import React from 'react';
import { CandidatePipelineTable } from './components';
import { Box } from '@mui/material';

function CandidatesSection(props) {
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Database',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Job Board',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'LinkedIn',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Job Posting',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '22px',
    },
  ];

  return (
    <div>
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>All Candidates</p>
      </Box>
      <CandidatePipelineTable
        buttons={buttons}
        onButtonClick={(value) => {}}
        showHiringPipeline={true}
        showButtons={true}
      />
    </div>
  );
}

export default CandidatesSection;
