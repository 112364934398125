import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React from 'react';

function IndividualClientReportDialog({ open, onClose, data , loading}) {
  const columns = [
    {
      accessorKey: 'client_number',
      header: 'Client No',
    },
    {
      accessorKey: 'title',
      header: 'Role Title',
      minWidth: 170,
    },
    {
      accessorKey: 'roleStatus',
      header: 'Status',
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor:
              cell.getValue() === 'issue'
                ? theme.palette.error.main
                : cell.getValue() === 'done'
                ? 'white'
                : cell.getValue() === 'completed'
                ? '#43EE3F'
                : cell.getValue() === 'pending'
                ? theme.palette.warning.main
                : cell.getValue() === 'inProgress'
                ? '#2196f3'
                : cell.getValue() === 're-working'
                ? '#D3C2CE'
                : cell.getValue() === 'final'
                ? theme.palette.success.dark
                : 'transparent',
            borderRadius: '0.25rem',
            p: '0.50rem',
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'roleDate',
      header: 'Date',
      Cell: ({ cell }) => {
        const dateString = cell?.row?.original?.roleDate;
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const humanReadableDate = date.toLocaleDateString('en-US', options);
        return humanReadableDate;
      },
    },
    {
      accessorKey: 'noOfCVsUploaded',
      header: 'CVs',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{!cell?.row?.original.noOfCVsUploaded ? 0 : cell?.row?.original.noOfCVsUploaded}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'noOfLiPrfilesAdded',
      header: 'LIS',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{cell?.row?.original.noOfLiPrfilesAdded ? cell?.row?.original.noOfLiPrfilesAdded : 0}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'location',
      header: 'Location',
    },
    {
      accessorKey: 'JobBoardUsed',
      header: 'Job boards',
      minWidth: 170,
      // getting job board used as :
      // "{\"reeds\":12,\"cvl\":23,\"totalJobs\":45,\"linkedIn\":45,\"zoomInfo\":67}"
      //show the job boards seperated by comma
      Cell: ({ cell }) => {
        const jobBoardInfo = cell?.row?.original?.JobBoardUsed;
        const jobBoards = JSON.parse(jobBoardInfo);
        if (jobBoards === null || jobBoards === undefined) {
          return 'N/A';
        } else {
          return (
            <Box component="span">
              <span>{jobBoards?.reeds ? 'Reeds' : ''}</span>
              <span>{jobBoards?.cvl ? ', CVL' : ''}</span>
              <span>{jobBoards?.linkedIn ? ', LinkedIn' : ''}</span>
              <span>{jobBoards?.zoomInfo ? ', Zoom Info' : ''}</span>
            </Box>
          );
        }
      },
    },
    {
      accessorKey: 'kuchbhi',
      header: 'Views',
      minWidth: 170,
      // getting job board used as :
      // "{\"reeds\":12,\"cvl\":23,\"totalJobs\":45,\"linkedIn\":45,\"zoomInfo\":67}"
      //show the job boards values seperated by comma
      Cell: ({ cell }) => {
        const jobBoardInfo = cell?.row?.original?.JobBoardUsed;
        const jobBoards = JSON.parse(jobBoardInfo);
        if (jobBoards === null || jobBoards === undefined) {
          return 'N/A';
        } else {
          return (
            <Box component="span">
              <span>{jobBoards?.reeds ? jobBoards.reeds : ''}</span>
              <span>{jobBoards?.cvl ? ', ' + jobBoards.cvl : ''}</span>
              <span>{jobBoards?.linkedIn ? ', ' + jobBoards.linkedIn : ''}</span>
              <span>{jobBoards?.zoomInfo ? ', ' + jobBoards.zoomInfo : ''}</span>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4"> </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MaterialReactTable
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center',
                },
                size: 50,
              },
            }}
            columns={columns}
            data={data}
            defaultColumn={{
              size: 100, //make columns wider by default
            }}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
            positionToolbarAlertBanner="bottom"
            manualFiltering
            state={{
              isLoading: loading,
            }}
            actionsColumnIndex={-1}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default IndividualClientReportDialog;
