import { Box, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { notify } from 'src/components/notify';
import {
  API_URLS,
} from 'src/constants/apiURLs';
import { Post } from 'src/actions/API/apiActions';
import { ArrowPipeline } from '../components';
import TeamSheetComponent from './TeamSheetComponent';
import RolesReportComponent from './RolesReportComponent';
import ClientRolesReportComponent from './ClientRolesReportComponent';
import IndividualReportDialog from './IndividualReportDialog';

function TeamSheetSection() {
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [loadingData, setLoadingData] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsToRender, setRowsToRender] = useState([]);
  const [totalStats, setTotalStats] = useState(0);
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [selectedResourcer, setSelectedResourcer] = useState(null);
  const [openIndividualReportDialog, setOpenIndividualReportDialog] = useState(false);
  const [statsType, setStatsType] = useState({ statsType: 'team' });
  const [roleStatus, setRoleStatus] = useState({ status: '' });
  const [roleStats, setRoleStats] = useState();
  const [showEmpty, setShowEmpty] = useState(false);
  const [clientRoleStats, setClientRoleStats] = useState();
  const [clientStatsType, setClientStatsType] = useState({ clientStatsType: '' });

  const getTeamSheetData = useCallback(() => {
    try {
      setLoadingData(true);
      Post(
        {
          fromDate: fromDate,
          toDate: toDate,
        },
        API_URLS.getResourcingTeamStats,
        (resp) => {
          console.log('dsdsadsa', resp);
          const data = resp.map((row) => {
            row.fullName = `${row?.resourcer?.first_name} ${row?.resourcer?.last_name}`;
            return row;
          });
          setRows(data);
          setTotalStats(data.length);
          //filter those who are present whose no of roles are greater than 0
          const present = data.filter((row) => row.roles > 0);
          setPresent(present.length);
          //filter those who are absent whose no of roles are 0
          const absent = data.filter((row) => row.roles === 0);
          setAbsent(absent.length);
          setLoadingData(false);
          // notify('Stats retrieved successfully', 'success', 1000);
        },
        (error) => {
          notify('Failed to retrieve stats', 'error', 1000);
          setLoadingData(false);
        }
      );
    } catch (error) {
      console.error('Error getting resourcing team report:', error);
      notify('Failed to retrieve stats', 'error', 1000);
      setLoadingData(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getTeamSheetData();
  }, [getTeamSheetData]);

  const getRoleStats = useCallback(() => {
    try {
      Post(
        { fromDate: fromDate, toDate: toDate },
        API_URLS.getRolesStats,
        (resp) => {
          setRoleStats(resp);
          // notify('Stats retrieved successfully', 'success', 1000);
        },
        (error) => {
          notify('Failed to retrieve stats', 'error', 1000);
        }
      );
    } catch (error) {
      console.error('Error getting roles report:', error);
      notify('Failed to retrieve stats', 'error', 1000);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getRoleStats();
  }, [getRoleStats]);

  const defaultRows = rowsToRender.length > 0 ? rowsToRender : rows;

  const getClientRolesStats = useCallback(() => {
    try {
      Post(
        { fromDate: fromDate, toDate: toDate },
        API_URLS.getClientRolesStats,
        (resp) => {
          setClientRoleStats(resp);
          // notify('Client Roles Stats fetched successfully', 'success', 1000);
        },
        (error) => {
          notify('Failed to fetch Client Roles Stats', 'error', 1000);
        }
      );
    } catch (error) {
      notify('Failed to fetch Client Roles Stats', 'error', 1000);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getClientRolesStats();
  }, [getClientRolesStats]);
  return (
    <div>
      <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
        Team Sheet
      </Typography>
      <Box
        width="calc(100% - 4rem)"
        height={90}
        bgcolor="white"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        sx={{ margin: '2rem' }}
        alignContent="center"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
          <ArrowPipeline
            title="Team"
            leftTooltip="Present"
            centerTooltip="Total"
            rightTooltip="Absent"
            color="#E496FF"
            center={totalStats}
            left={present}
            right={absent}
            onLeftClick={() => {
              if (present === 0) {
                setShowEmpty(true);
              } else {
                setShowEmpty(false);
                const temp = rows.filter((row) => row.roles > 0);
                setRowsToRender(temp);
              }
            }}
            onCenterClick={() => {
              getTeamSheetData();
              setShowEmpty(false);
              setRowsToRender([]);
            }}
            onRightClick={() => {
              if (absent === 0) {
                setShowEmpty(true);
              } else {
                setShowEmpty(false);
                const temp = rows.filter((row) => row.roles === 0);
                setRowsToRender(temp);
              }
            }}
            onClick={() => setStatsType((prevState) => ({ ...prevState, statsType: 'team' }))}
          />
          <ArrowPipeline
            title="Roles"
            leftTooltip="Done"
            centerTooltip="Total"
            rightTooltip="Pending"
            color="#8C85FB"
            onClick={() => setStatsType((prevState) => ({ ...prevState, statsType: 'roles' }))}
            left={roleStats?.done}
            center={roleStats?.total}
            right={roleStats?.pending}
            onLeftClick={() => setRoleStatus((prevState) => ({ ...prevState, status: 'done' }))}
            onCenterClick={() => setRoleStatus((prevState) => ({ ...prevState, status: '' }))}
            onRightClick={() => setRoleStatus((prevState) => ({ ...prevState, status: 'pending' }))}
          />
          <ArrowPipeline
            title="Clients"
            leftTooltip="Sent role"
            centerTooltip="Total"
            rightTooltip="Not sent role"
            color="#00C1FE"
            onClick={() => setStatsType((prevState) => ({ ...prevState, statsType: 'clients' }))}
            left={clientRoleStats?.clientsWithRoles}
            center={clientRoleStats?.totalClients}
            right={clientRoleStats?.clientsWithoutRoles}
            onLeftClick={() => setClientStatsType((prevState) => ({ ...prevState, clientStatsType: 'sent' }))}
            onCenterClick={() => setClientStatsType((prevState) => ({ ...prevState, clientStatsType: '' }))}
            onRightClick={() => setClientStatsType((prevState) => ({ ...prevState, clientStatsType: 'notSent' }))}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem' }}>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>
          From{' '}
        </Typography>
        <TextField
          id="fromDate"
          name="fromDate"
          type="date"
          value={fromDate}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            color: '#0ec4fe',
            // height: '56px',
            border: '3px solid #0ec4fe',
            marginLeft: '15px',
            '&:hover': {
              color: '#0ec4fe',
              // height: '54px !important',
              boxShadow: 'none', // Remove default box-shadow
              border: '3px solid #0ec4fe !important',
            },
          }}
          size="medium"
          onChange={(e) => {
            // from date should be less than to date
            if (new Date(e.target.value) > new Date(toDate)) {
              notify('From date should be less than To date', 'error', 1000);
              return;
            } else {
              setFromDate(new Date(e.target.value).toISOString().split('T')[0]);
            }
          }}
        />
        <Typography variant="h6" sx={{ marginTop: '10px', marginLeft: '15px' }}>
          To{' '}
        </Typography>
        <TextField
          id="toDate"
          name="toDate"
          type="date"
          value={toDate}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            color: '#0ec4fe',
            // height: '54px',
            border: '3px solid #0ec4fe',
            marginLeft: '15px',
            marginRight: '2rem',
            '&:hover': {
              color: '#0ec4fe',
              // height: '54px !important',
              boxShadow: 'none', // Remove default box-shadow
              backgroundColor: 'transparent',
              border: '3px solid #0ec4fe !important',
            },
          }}
          size="medium"
          onChange={(e) => {
            // to date must be greater than from date
            if (new Date(e.target.value) < new Date(fromDate)) {
              notify('To date should be greater than From date', 'error', 1000);
              return;
            } else {
              setToDate(new Date(e.target.value).toISOString().split('T')[0]);
            }
          }}
        />
      </Box>
      <br />
      {statsType?.statsType === 'team' && (
        <TeamSheetComponent
          data={showEmpty ? [] : defaultRows}
          loadingData={loadingData}
          resourcer={(resourcer) => {
            setSelectedResourcer(resourcer);
            setOpenIndividualReportDialog(true);
          }}
        />
      )}
      {statsType?.statsType === 'roles' && (
        <RolesReportComponent fromDate={fromDate} toDate={toDate} roleStatus={roleStatus?.status} />
      )}
      {statsType?.statsType === 'clients' && (
        <ClientRolesReportComponent
          fromDate={fromDate}
          toDate={toDate}
          clientStatsType={clientStatsType?.clientStatsType}
        />
      )}

      {openIndividualReportDialog && (
        <IndividualReportDialog
          open={openIndividualReportDialog}
          onClose={() => setOpenIndividualReportDialog(false)}
          resourcer={selectedResourcer}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    
    </div>
  );
}

export default TeamSheetSection;
