import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Tooltip,
  TablePagination,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { AssociateCandidate, Microscope } from './images';
import { useLocation, useNavigate } from 'react-router-dom';
import { AskSendEmailConfirmationDialog, JobDescriptionDialog } from '.';
import { styled } from '@mui/styles';
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#ccc', // default background for unchecked state
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText('#4CD4FF')
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText('#4CD4FF')
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#4CD4FF', // Enabled track color
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#36A3CC', // Darker shade of #4CD4FF for the enabled thumb
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    backgroundColor: '#36A3CC', // Darker thumb color when enabled
  },
}));

function RolePipelinesTable({ title, roles, onPageChange, onRowsPerPageChange, totalCount }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const location = useLocation();

  // const data = [
  //   {
  //     role: 'Designer',
  //     candidates: 9,
  //     stages: {
  //       allCandidates: 10,
  //       screening: 8,
  //       submissions: 8,
  //       rejected: 2,
  //       interview: 6,
  //       offered: 4,
  //       hired: 4,
  //     },
  //   },
  //   {
  //     role: 'Developer',
  //     candidates: 4,
  //     stages: {
  //       allCandidates: 3,
  //       screening: 3,
  //       submissions: 3,
  //       rejected: 3,
  //       interview: 3,
  //       offered: 3,
  //       hired: 3,
  //     },
  //   },
  //   {
  //     role: 'Writer',
  //     candidates: 4,
  //     stages: {
  //       allCandidates: 3,
  //       screening: 3,
  //       submissions: 3,
  //       rejected: 3,
  //       interview: 3,
  //       offered: 3,
  //       hired: 3,
  //     },
  //   },
  // ];

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const navigate = useNavigate();
  const [openJobDescriptionDialog, setOpenJobDescriptionDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [openSendEmailsDialog, setOpenSendEmailsDialog] = useState(false);
  // Track toggle state individually for each row
  const [emailToggleStates, setEmailToggleStates] = useState({});
  // Toggle the email dialog for specific row
  const handleToggle = (rowIndex) => {
    setEmailToggleStates((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };
  return (
    <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
      <Typography variant="subtitle2" gutterBottom sx={{ marginLeft: 2 }}>
        {title}
      </Typography>
      <hr style={{ width: '100%', margin: 'auto', color: '#E6EBF5' }} />
      {/* Colored bar directly above table headers */}
      <Box
        display="flex"
        sx={{
          height: '5px',
          width: '82%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginLeft: 'auto',
          marginRight: 3,
        }}
      >
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EB65E5', // Designer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#88BCF6', // Developer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#AFBAE0', // Writer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#FF9999', // Rejected
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EBB794', // Interview
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#67E4BB', // Offered
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#E0CC38', // Hired
            height: '100%',
          }}
        ></span>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Marketing</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Posting Title</TableCell>
              <TableCell style={{ textAlign: 'center' }}>All Candidates</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Screening</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Submissions</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Rejected</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Interview</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Offered</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Hired</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Paginate the rows */}
            {roles?.length > 0 ? (
              <>
                {roles?.map((row, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell sx={{ width: '6%' }}>
                          <Box display="flex" alignItems="center" justifyContent="space-around">
                            <Tooltip title="View JD" placement="top">
                              <img
                                src={Microscope}
                                alt="View JD"
                                width="18px"
                                height="14px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenJobDescriptionDialog(true);
                                  setSelectedJob(row);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Associate candidate" placement="top">
                              <img
                                src={AssociateCandidate}
                                alt="Associate Candidate"
                                width="12px"
                                height="12px"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/recruiter/jobApplicants/1`, {
                                    state: { previousPath: location.pathname }, // Pass the current path as state
                                  })
                                }
                              />
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <FormControlLabel
                            control={<Android12Switch />}
                            label=""
                            checked={!!emailToggleStates[index]}
                            onChange={() => handleToggle(index)}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 'auto',
                            }}
                          >
                            {' '}
                            <span
                              style={{ color: '#4CD4FF', cursor: 'pointer' }}
                              onClick={() => navigate('/recruiter/jobDetails/1')}
                            >
                              {row.title}
                            </span>{' '}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/recruiter/jobApplicants/${row?.id}`)}
                            >
                              ({row?.cv_ids?.length})
                            </span>
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%', textAlign: 'center' }}>
                          <p
                            style={{
                              backgroundColor: '#FFE2FE',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #EB65E5',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/allCandidates/1')}
                          >
                            {row?.cv_ids?.length}
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#DDECFD',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #88BCF6',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/screeningCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#DCE1F3',
                              padding: '10px',
                              width: '55%',
                              margin: 'auto',
                              textAlign: 'center',
                              borderRight: '5px solid #AFBAE0',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/submittedCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#F6D3D3',
                              padding: '10px',
                              width: '55%',
                              margin: 'auto',
                              textAlign: 'center',
                              borderRight: '5px solid #FF9999',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/rejectedCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#FFE6D6',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #EBB794',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/interviewCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#C7FFED',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #67E4BB',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/offeredCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '12%' }}>
                          <p
                            style={{
                              backgroundColor: '#FFF5AE',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #E0CC38',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/hiredCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                    No records found
                  </TableCell>
                </TableRow>
              </>
            )}

            {/* {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: '6%' }}>
                  <Box display="flex" alignItems="center" justifyContent="space-around">
                    <Tooltip title="View JD" placement="top">
                      <img
                        src={Microscope}
                        alt="View JD"
                        width="18px"
                        height="14px"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpenJobDescriptionDialog(true);
                          setSelectedJob(row);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Associate candidate" placement="top">
                      <img
                        src={AssociateCandidate}
                        alt="Associate Candidate"
                        width="12px"
                        height="12px"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigate(`/recruiter/jobApplicants/1`, {
                            state: { previousPath: location.pathname }, // Pass the current path as state
                          })
                        }
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={<Android12Switch />}
                    label=""
                    checked={!!emailToggleStates[index]}
                    onChange={() => handleToggle(index)}
                  />
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <p
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                    }}
                  >
                    {' '}
                    <span
                      style={{ color: '#4CD4FF', cursor: 'pointer' }}
                      onClick={() => navigate('/recruiter/jobDetails/1')}
                    >
                      {row.role}
                    </span>{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`/recruiter/jobApplicants/1`, {
                          state: { previousPath: location.pathname }, // Pass the current path as state
                        })
                      }
                    >
                      ({row.candidates})
                    </span>
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%', textAlign: 'center' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE2FE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EB65E5',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/allCandidates/1')}
                  >
                    {row.stages.allCandidates}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DDECFD',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #88BCF6',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/screeningCandidates/1')}
                  >
                    {row.stages.screening}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DCE1F3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #AFBAE0',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/submittedCandidates/1')}
                  >
                    {row.stages.submissions}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#F6D3D3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #FF9999',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/rejectedCandidates/1')}
                  >
                    {row.stages.rejected}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE6D6',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EBB794',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/interviewCandidates/1')}
                  >
                    {row.stages.interview}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#C7FFED',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #67E4BB',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/offeredCandidates/1')}
                  >
                    {row.stages.offered}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFF5AE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #E0CC38',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/hiredCandidates/1')}
                  >
                    {row.stages.hired}
                  </p>
                </TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination component */}
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 15, 25]} // Optional: Different options for rows per page
      />

      {openJobDescriptionDialog && (
        <JobDescriptionDialog
          open={openJobDescriptionDialog}
          onClose={() => setOpenJobDescriptionDialog(false)}
          jobDetails={selectedJob}
        />
      )}
      {Object?.values(emailToggleStates)?.some((value) => value) && (
        <AskSendEmailConfirmationDialog
          open={Object.values(emailToggleStates).some((value) => value)}
          onClose={() => setEmailToggleStates({})}
        />
      )}
    </Box>
  );
}

export default RolePipelinesTable;
