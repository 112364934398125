import React, { useCallback, useEffect, useState } from 'react';
import { RolePipelinesTable } from './components';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

function RecruiterDashboard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [preRoles, setPreRoles] = useState([]);
  const [prePage, setPrePage] = useState(0);
  const [preLimit, setPreLimit] = useState(10);
  const [preCount, setPreCount] = useState(0);
  const getPrequalificationRoles = useCallback(() => {
    try {
      Get(
        {
          page: prePage,
          limit: preLimit,
        },
        API_URLS.getPreQualificationRoles,
        (resp) => {
          setPreRoles(resp?.data?.data);
          setPreCount(resp?.data?.pagination?.totalRoles);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [prePage, preLimit]);

  useEffect(() => {
    getPrequalificationRoles();
  }, [getPrequalificationRoles]);
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <p style={{ fontSize: '18px', color: '#A0A9B4' }}>Welcome back, John!</p>
      <p style={{ fontSize: '36px', fontWeight: 'bold', marginTop: '10px' }}>Dashboard</p>
      <br />
      <RolePipelinesTable
        title="Pre-Qualification"
        roles={preRoles}
        onPageChange={(page) => {
          setPrePage(page);
        }}
        onRowsPerPageChange={(limit) => {
          setPreLimit(limit);
        }}
        totalCount={preCount}
      />
      <br />
      <RolePipelinesTable title="360 " />
    </div>
  );
}

export default RecruiterDashboard;
