import { Icon } from '@iconify/react';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import day.js
import dayjs from 'dayjs';
import ReactQuillEditor from './ReactQuillEditor';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; // Import these from react-beautiful-dnd
function RoleSequence(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null); // For managing popover state
  const [addIndex, setAddIndex] = useState(null);
  const [regards, setRegards] = useState('');
  const [message, setMessage] = useState();
  const [emailTemlpate, setEmailTemlpate] = useState();
  const [emailSubject, setEmailSubject] = useState();
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  // State to track if the title is hovered, making it clickable
  const [isTitleHovered, setIsTitleHovered] = useState(null);
  // Define the initial steps with instances and counts

  const currentTime = dayjs().format('hh:mm A');
  const [time, setTime] = useState(currentTime);
  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget); // Set the popover anchor element
    setAddIndex(index); // Store the index of the button that was clicked
  };

  // Handle popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Boolean to check if popover is open
  const open = Boolean(anchorEl);
  const [communicationData, setCommunicationData] = useState([
    {
      title: 'Outreach Email',
      icon: 'ic:baseline-email',
      color: '#00C1FE',
      details: [
        { label: 'Subject line', value: 'Template for S&R' },
        { label: 'Content', value: 'Hi, I hope you are well.' },
      ],
      stats: [
        { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        { label: 'Auto reply', icon: 'mdi:auto-mode', color: '#3F8CFF', count: 20 },
        { label: 'Potential reply', icon: 'tabler:message-up', color: '#43EE3F', count: 10 },
      ],
    },
    {
      title: 'Follow Up Email 1',
      icon: 'ic:baseline-email',
      color: '#00C1FE',
      details: [
        { label: 'Subject line', value: 'Template for S&R' },
        { label: 'Content', value: 'Hi, I hope you are well.' },
      ],
      stats: [
        { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        { label: 'Auto reply', icon: 'mdi:auto-mode', color: '#3F8CFF', count: 20 },
        { label: 'Potential reply', icon: 'tabler:message-up', color: '#43EE3F', count: 10 },
      ],
    },
    {
      title: 'WhatsApp Message',
      icon: 'logos:whatsapp-icon',
      color: '#25D366',
      details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 60 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
      ],
    },
    {
      title: 'SMS',
      icon: 'material-symbols:sms',
      color: '#009688',
      details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 60 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
      ],
    },
    {
      title: 'LI Connection',
      icon: 'ri:linkedin-fill',
      color: '#0076B2',
      details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'Replied', icon: 'codicon:verified-filled', color: '#E5B137', count: 60 },
        { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
      ],
    },
    {
      title: 'Call',
      icon: 'material-symbols:call',
      color: '#60D669',
      details: [{ label: 'Message', value: 'Any' }],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'Responded', icon: 'solar:outgoing-call-bold', color: '#F7CB45', count: 60 },
        { label: 'No response', icon: 'subway:call-3', color: 'red', count: 60 },
      ],
    },
    {
      title: 'InMail',
      icon: 'mdi:linkedin',
      color: '#C8773F',
      details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
        { label: 'Replied', icon: 'tabler:message-up', color: '#43EE3F', count: 60 },
        { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
      ],
    },
  ]);

  useEffect(() => {
    setRegards(`<body> 
            Regards
            <div>Amna Yaseen</div>
            <div>Senior Business Development Executive</div>
            <div>Email: <span style="margin-left: 2.5rem;"> amna@ultimateoutsourcing.co.uk</span></div>
            <div><b>Tel: <span style="margin-left: 4rem;"> 03065495216 </span></b></div>
            <div><b>Tel US: <span style="margin-left: 1.9rem;">+1 347 480 4177</span></b></div>
            <div>Website: <a href="https://ultimateoutsourcing.co.uk/" target="_blank"
                style="margin-left: 2rem;">www.ultimateoutsourcing.co.uk</a> </div>
          </b> <img src="https://ultimateoutsourcing.co.uk/wp-content/uploads/2021/03/300.png" alt="Ultimate outsourcing LTD"
          style="padding-top: 2rem; padding-bottom: 2rem;"<br>
          <p><p><i><strong>Ultimate outsourcing – Home to the UK’s, Europe, UAE, US's &amp; Canada largest Candidate Sourcing Services.</strong></i></p><p>Ultimate Outsourcing is a limited liability company registered in Wyoming USA Under Filling ID: 2023-001264578. Ultimate Outsourcing is a limited company registered in England &amp; Wales with Registered Number 12980312.</p><p>Disclaimer:&nbsp;<br>This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error please contact the sender and delete the message from your computer. Ultimate Outsourcing LTD &amp; Ultimate Outsourcing LLC has taken every reasonable step to minimize the risk of viruses, it cannot accept responsibility for any damage which you may sustain as a result of a virus attached to this e-mail. Click <a href="https://ultimateoutsourcing.co.uk/unsubscribe/">Unsubscribe</a> to unsubscribe our services.</p></p>
          </body>`);
  }, []);

  useEffect(() => {
    setEmailTemlpate(
      `<body>
      <div>Hi,</div>
      <br/>
      <div>Coming across your profile , I thought you are a great match for may client's Software Engineer Role in ST Helens. I've attached the JD for this role.</div>
      Salary range: £30,000 - £40,000</div>
      <div>Please let me know if you are interested and want to know more about this position.</div>
      <br/>
      </body>`
    );
  }, []);

  useEffect(() => {
    setEmailSubject('Software Engineer Role in ST Helens');
  }, []);
  const handleAddStep = (type) => {
    let newStep;
    const emailFollowUps = communicationData.filter((step) => step?.title?.includes('Follow Up Email'));
    const whatsappFollowUps = communicationData.filter((step) => step?.title?.includes('WhatsApp Message'));
    const callFollowUps = communicationData.filter((step) => step?.title?.includes('Call'));
    const liFollowUps = communicationData.filter((step) => step?.title?.includes('LI Connection'));
    const smsFollowUps = communicationData.filter((step) => step?.title?.includes('SMS'));
    const inMailFollowUps = communicationData.filter((step) => step?.title?.includes('InMail'));
    if (type === 'Email') {
      const nextEmailIndex = emailFollowUps.length + 1;
      newStep = {
        title: `Follow Up Email ${nextEmailIndex}`,
        icon: 'ic:baseline-email',
        color: '#00C1FE',
        details: [
          { label: 'Subject line', value: emailSubject },
          { label: 'Content', value: 'Hi, I hope you are well.' },
        ],
        stats: [
          { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        ],
        instance: nextEmailIndex,
      };
    } else if (type === 'WhatsApp') {
      newStep = {
        title: `WhatsApp Message ${whatsappFollowUps.length + 1}`,
        icon: 'logos:whatsapp-icon',
        color: '#25D366',
        details: [{ label: 'Message', value: message }],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        ],
        instance: whatsappFollowUps.length + 1,
      };
    } else if (type === 'Connection Request') {
      newStep = {
        title: `LI Connection ${liFollowUps.length + 1}`,
        icon: 'ri:linkedin-fill',
        color: '#0076B2',
        details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'codicon:verified-filled', color: '#E5B137', count: 60 },
          { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
        ],
        instance: liFollowUps.length + 1,
      };
    } else if (type === 'SMS') {
      newStep = {
        title: `SMS ${smsFollowUps.length + 1}`,
        icon: 'material-symbols:sms',
        color: '#009688',
        details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 60 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        ],
        instance: smsFollowUps.length + 1,
      };
    } else if (type === 'Call') {
      newStep = {
        title: `Call ${callFollowUps.length + 1}`,
        icon: 'material-symbols:call',
        color: '#60D669',
        details: [{ label: 'Message', value: 'Any' }],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Responded', icon: 'solar:outgoing-call-bold', color: '#F7CB45', count: 60 },
          { label: 'No response', icon: 'subway:call-3', color: 'red', count: 60 },
        ],
        instance: callFollowUps.length + 1,
      };
    } else if (type === 'InMail') {
      newStep = {
        title: `InMail ${inMailFollowUps.length + 1}`,
        icon: 'mdi:linkedin',
        color: '#C8773F',
        details: [{ label: 'Message', value: 'Hi, I hope you are well.' }],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'tabler:message-up', color: '#43EE3F', count: 60 },
          { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
        ],
        instance: inMailFollowUps.length + 1,
      };
    }
    // const addIndex = communicationData.findIndex((step) => step.title.includes(type));

    const updatedData = [
      ...communicationData.slice(0, addIndex + 1),
      newStep,
      ...communicationData.slice(addIndex + 1),
    ];

    setCommunicationData(updatedData);
    handlePopoverClose();
  };

  // Remove a communication step
  const handleRemoveStep = (index) => {
    const updatedData = communicationData.filter((_, i) => i !== index);
    // Reassign instances for follow-up emails
    const reindexedData = updatedData.map((item, i) => {
      if (item.title.includes('Follow Up Email')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      }
      return item;
    });

    setCommunicationData(reindexedData);
  };

  const handleCardClick = (index) => {
    // Store the currently clicked step's index
    setCurrentStepIndex(index);
  };

  const handleContentChange = (index, content) => {
    const updatedSteps = [...communicationData];
    updatedSteps[index] = { ...updatedSteps[index], content: content };
    setCommunicationData(updatedSteps);
  };

  const handleSubjectChange = (index, subject) => {
    const updatedSteps = [...communicationData];
    updatedSteps[index] = { ...updatedSteps[index], subject: subject };
    setCommunicationData(updatedSteps);
  };
  const handleSave = () => {
    const stepsToSave = communicationData.map((step, index) => ({
      id: index + 1,
      step_type: step.title,
      subject: step.subject || '',
      content: step.content || '',
      order: index + 1,
      scheduled_time: step.scheduledTime || null,
    }));
    console.log('stepsToSave', stepsToSave);
  };
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination || destination.index === 0) return; // Prevent drop outside or at index 0

              // Reorder the communicationData array
              const reorderedData = Array.from(communicationData);
              const [movedItem] = reorderedData.splice(source.index, 1);
              reorderedData.splice(destination.index, 0, movedItem);

              // Update the state
              setCommunicationData(reorderedData);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {communicationData?.map((data, index) =>
                    index === 0 ? (
                      // Render non-draggable CommunicationCard for index 0
                      <div key={index}>
                        <CommunicationCard
                          key={index}
                          step={data}
                          onClick={() => handleCardClick(index)}
                          onRemove={() => handleRemoveStep(index)}
                        />
                        <Box display="flex" justifyContent="center" alignItems="center" margin="1.5rem">
                          <Button
                            sx={{
                              backgroundColor: '#DADAF9',
                              width: '5%',
                              maxWidth: '5%',
                              '&:hover': { backgroundColor: '#DADAF9 !important' },
                            }}
                            disabled
                          >
                            <Icon icon="formkit:arrowdown" style={{ fontSize: '25px' }} />
                          </Button>
                        </Box>
                      </div>
                    ) : (
                      // Draggable items for other indices
                      <Draggable key={index} draggableId={String(index)} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <div
                              {...provided.dragHandleProps}
                              onMouseEnter={() => setIsTitleHovered(index)}
                              onMouseLeave={() => setIsTitleHovered(null)}
                              onClick={() => {
                                if (isTitleHovered === index) {
                                  handleCardClick(index); // Call the click handler only if the title is hovered
                                }
                              }}
                            >
                              <CommunicationCard
                                key={index}
                                step={data}
                                titleProps={{
                                  onMouseEnter: () => setIsTitleHovered(index),
                                  onMouseLeave: () => setIsTitleHovered(null),
                                }}
                                onRemove={() => handleRemoveStep(index)}
                              />
                            </div>
                            <Box display="flex" justifyContent="center" alignItems="center" margin="1.5rem">
                              <Button
                                sx={{
                                  backgroundColor: '#DADAF9',
                                  width: '5%',
                                  maxWidth: '5%',
                                  '&:hover': { backgroundColor: '#DADAF9 !important' },
                                }}
                                onClick={(event) => handlePopoverOpen(event, index)}
                              >
                                <Icon icon="pajamas:plus" style={{ fontSize: '25px' }} />
                              </Button>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <List>
            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Email')}>
              {/* Icon and text for "Sourced" */}
              <ListItemIcon>
                <Icon icon="ic:baseline-email" style={{ fontSize: '20px', color: '#00C1FE' }} />
              </ListItemIcon>
              <ListItemText primary="Email" sx={{ fontSize: '8px', color: '#00C1FE' }} />
            </ListItem>

            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('WhatsApp')}>
              {/* Icon and text for "Messaged" */}
              <ListItemIcon>
                <Icon icon="logos:whatsapp-icon" style={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="WhatsApp" sx={{ fontSize: '12px', color: '#60D669' }} />
            </ListItem>

            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('SMS')}>
              {/* Icon and text for "Opened" */}
              <ListItemIcon>
                <Icon icon="material-symbols:sms" style={{ fontSize: '20px', color: '#009688' }} />
              </ListItemIcon>
              <ListItemText primary="SMS" sx={{ fontSize: '12px', color: '#009688' }} />
            </ListItem>

            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Connection Request')}>
              {/* Icon and text for "Clicked" */}
              <ListItemIcon>
                <Icon icon="flowbite:linkedin-solid" style={{ fontSize: '20px', color: '#007' }} />
              </ListItemIcon>
              <ListItemText primary="Connection Request" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Call')}>
              {/* Icon and text for "Replied" */}
              <ListItemIcon>
                <Icon icon="material-symbols:call" style={{ fontSize: '20px', color: '#D1D42B' }} />
              </ListItemIcon>
              <ListItemText primary="Call" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('InMail')}>
              {/* Icon and text for "Interested" */}
              <ListItemIcon>
                <Icon icon="mdi:linkedin" style={{ fontSize: '20px', color: '#C8773F' }} />
              </ListItemIcon>
              <ListItemText primary="InMail" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>
          </List>
        </Popover>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 3,
              height: 'auto',
              width: '35%',
              padding: '15px',
            }}
            display="flex"
            alignItems="center"
          >
            <Icon icon="ic:baseline-email" style={{ fontSize: '30px', color: '#00C1FE' }} />
            <Typography variant="h6" sx={{ ml: 2, fontWeight: 'normal' }}>
              Email
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={2}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: 3,
                height: 'auto',
                marginRight: '10px',
                width: '25%',
                padding: '5px',
              }}
              display="flex"
              alignItems="center"
            >
              <Typography variant="subtitle1" sx={{ ml: 2, fontWeight: 'normal' }}>
                {time}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  onChange={(newValue) => {
                    setTime(dayjs(newValue).format('hh:mm A'));
                  }}
                  sx={{
                    width: '10%',
                    cursor: 'pointer',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none', // Removes the border of the input
                      },
                    },
                    '& .MuiInputBase-input': {
                      outline: 'none', // Removes the focus outline
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <FormControlLabel control={<Switch defaultChecked />} label="Instant" />
          </Box>
          {currentStepIndex !== null && (
            <Grid>
              {communicationData[currentStepIndex].title.includes('Email') ? (
                <>
                  <Box marginTop={2}>
                    <TextField
                      id="subject"
                      name="subject"
                      type="text"
                      placeholder="Subject line"
                      size="large"
                      value={communicationData[currentStepIndex]?.subject || emailSubject}
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        const newSubject = e.target.value;
                        handleSubjectChange(currentStepIndex, newSubject);
                      }}
                      sx={{
                        backgroundColor: 'white',
                        outline: 'none',
                        border: '1px solid #D9D9D9',
                        marginBottom: '3px',
                        borderRadius: '15px',
                        marginLeft: '5px',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: 3,
                      height: 'auto',
                      marginRight: '10px',
                      padding: '10px',
                    }}
                    display="flex"
                    flexDirection="column"
                    marginTop={2}
                  >
                    <ReactQuillEditor
                      showTags={false}
                      editorLabel=""
                      htmlText={communicationData[currentStepIndex].title.includes('Email') ? emailTemlpate : message}
                      onEditorStateChange={(html) => {
                        handleContentChange(currentStepIndex, html?.data);
                      }}
                      maxCharacters={
                        communicationData[currentStepIndex].title.includes('WhatsApp')
                          ? 300
                          : communicationData[currentStepIndex].title.includes('SMS')
                          ? 200
                          : 2000
                      }
                    />
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        padding: '5px',
                        paddingLeft: '20px',
                        marginBottom: '5px',
                        marginLeft: '10px',
                        height: '600px',
                        overflowX: 'auto', // Add horizontal scrollbar if necessary
                        overflowY: 'auto', // Add vertical scrollbar if necessary
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: regards,
                        }}
                      />
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: 3,
                      height: 'auto',
                      marginRight: '10px',
                      padding: '10px',
                    }}
                    display="flex"
                    flexDirection="column"
                    marginTop={2}
                  >
                    <ReactQuillEditor
                      showTags={false}
                      editorLabel=""
                      htmlText={communicationData[currentStepIndex]?.title?.includes('Email') ? emailTemlpate : message}
                      onEditorStateChange={(html) => {
                        handleContentChange(currentStepIndex, html?.data);
                      }}
                      maxCharacters={
                        communicationData[currentStepIndex]?.title?.includes('WhatsApp')
                          ? 300
                          : communicationData[currentStepIndex]?.title?.includes('SMS')
                          ? 200
                          : 2000
                      }
                    />
                  </Box>
                </>
              )}
            </Grid>
          )}

          <Box display="flex" justifyContent="flex-end" alignItems="flex-end" marginTop="1rem" marginRight="1rem">
            <Button
              size="large"
              sx={{
                backgroundColor: 'white',
                color: '#0ec4fe',
                borderRadius: 0,
                border: '2px solid #0ec4fe',
                padding: '10px 30px',
                '&:hover': {
                  color: '#0ec4fe !important',
                  backgroundColor: 'white !important',
                },
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              sx={{
                backgroundColor: '#0ec4fe',
                color: 'white',
                borderRadius: 0,
                padding: '10px 30px',
                marginLeft: '10px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default RoleSequence;

function CommunicationCard({ step, onRemove, onClick }) {
  const { title, icon, color, details, stats, content, subject } = step;
  return (
    <Box onClick={onClick} sx={{ backgroundColor: 'white', borderRadius: 1, border: `1px solid ${color}`, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        {' '}
        <Box display="flex">
          {' '}
          <Icon icon={icon} style={{ fontSize: '30px', color }} />{' '}
          <Typography variant="h6" sx={{ ml: 2, fontWeight: 'normal', cursor: 'pointer' }}>
            {' '}
            {title}{' '}
          </Typography>{' '}
        </Box>
        <Tooltip title="Remove step" placement="top">
          {' '}
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{
              fontSize: '25px',
              cursor: onRemove ? 'pointer' : 'default',
              color: onRemove ? 'inherit' : '#CAC5C5',
            }}
            onClick={onRemove}
          />{' '}
        </Tooltip>
      </Box>
      <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '5px' }} />
      {details?.map((detail, index) => (
        <Box display="flex" sx={{ paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px' }} key={index}>
          {' '}
          <p style={{ fontWeight: 'bold' }}>
            {' '}
            {detail.label}: <span style={{ fontWeight: 'normal' }}>{detail.value}</span>{' '}
          </p>{' '}
        </Box>
      ))}
      <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
      <Box display="flex" sx={{ padding: '20px' }} justifyContent="space-between" alignItems="center">
        {' '}
        {stats.map((stat, index) => (
          <Tooltip key={index} title={stat.label} placement="bottom">
            {' '}
            <p style={{ display: 'flex', alignItems: 'center' }}>
              {' '}
              <Icon icon={stat.icon} style={{ fontSize: '25px', color: stat.color, marginRight: '5px' }} /> {stat.count}{' '}
            </p>{' '}
          </Tooltip>
        ))}{' '}
      </Box>
    </Box>
  );
}
