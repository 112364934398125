import React, { useState, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { renderAsync } from 'docx-preview';
import './style.css';

registerPlugin(FilePondPluginFileValidateType);

const WordEditor = ({ onContentChange }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (content !== null) {
      onContentChange(content);
    }
  }, [content]);

  const handleFileChange = (fileItems) => {
    if (fileItems.length > 0) {
      const file = fileItems[0].file;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;

        const container = document.createElement('div');
        try {
          await renderAsync(arrayBuffer, container);
          setContent(container.innerHTML); // Update state only
        } catch (error) {
          console.error('Error rendering .docx file:', error);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <FilePond
        label="Upload a Word Document"
        onupdatefiles={handleFileChange}
        allowMultiple={false}
        acceptedFileTypes={[
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
      />

      <div className="docx-preview" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default WordEditor;




// import React, { useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { FilePond, registerPlugin } from 'react-filepond';
// import 'filepond/dist/filepond.min.css';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import './style.css'
// registerPlugin(FilePondPluginFileValidateType);

// const WordEditor = () => {
//   const [content, setContent] = useState('');

//   const options = {
//     styleMap: [
//       "table => table",
//       "tr => tr",
//       "td => td",
//       "th => th",
//       "p[style*='margin'] => p"
//     ]
//   };

//   const handleFileChange = async (fileItems) => {
//     if (fileItems.length > 0) {
//       const file = fileItems[0].file;

//       // Read the file and convert it to HTML using Mammoth
//       const reader = new FileReader();
//       reader.onload = async (e) => {
//         const arrayBuffer = e.target.result;

//         // Use Mammoth to convert the .docx ArrayBuffer to HTML
//         const mammoth = window.mammoth;
//         mammoth
//           .convertToHtml({ arrayBuffer }, options)
//           .then((result) => {
//             const processedContent = postProcessHTML(result.value); // Process HTML
//             setContent(processedContent); // Set the processed HTML content
//           })
//           .catch((error) => {
//             console.error('Error rendering .docx file:', error);
//           });
//       };
//       reader.readAsArrayBuffer(file);
//     }
//   };

//   const postProcessHTML = (html) => {
//     // Example: Ensure proper table formatting (add classes for styling)
//     let processedHtml = html;

//     // Add custom classes for table, th, and td to preserve layout
//     processedHtml = processedHtml.replace(/<table>/g, '<table class="custom-table">');
//     processedHtml = processedHtml.replace(/<th>/g, '<th class="custom-th">');
//     processedHtml = processedHtml.replace(/<td>/g, '<td class="custom-td">');

//     return processedHtml;
//   };

//   const handleContentChange = (value) => {
//     setContent(value);
//   };

//   return (
//     <div>
//       <FilePond
//         label="Upload a Word Document"
//         onupdatefiles={handleFileChange}
//         allowMultiple={false}
//         acceptedFileTypes={['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
//       />
//       <ReactQuill
//         theme="snow"
//         value={content}
//         onChange={handleContentChange}
//       />
//     </div>
//   );
// };

// export default WordEditor;




// import React, { useState, useRef } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { FilePond, registerPlugin } from 'react-filepond';
// import 'filepond/dist/filepond.min.css';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import { renderAsync } from 'docx-preview';

// // Register the file validation plugin for FilePond
// registerPlugin(FilePondPluginFileValidateType);

// const WordEditor = () => {
//   const [file, setFile] = useState(null);
//   const [content, setContent] = useState('');
//   const quillRef = useRef(null);

//   const handleFileChange = async (fileItems) => {
//     if (fileItems.length > 0) {
//       const selectedFile = fileItems[0].file;
//       setFile(selectedFile);

//       const arrayBuffer = await selectedFile.arrayBuffer();

//       // Create a temporary container element for rendering
//       const tempContainer = document.createElement('div');
      
//       try {
//         // Render the .docx content into the temporary container
//         await renderAsync(arrayBuffer, tempContainer);
//         // Extract the HTML content from the container
//         setContent(tempContainer.innerHTML);
//       } catch (error) {
//         console.error("Error rendering .docx file:", error);
//       }
//     }
//   };

//   const handleContentChange = (value) => {
//     setContent(value);
//   };

//   const handleDownload = () => {
//     const blob = new Blob([content], { type: 'application/msword' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = 'edited_document.docx';
//     link.click();
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <div>
//       <FilePond
//         label="Upload a Word Document"
//         onupdatefiles={handleFileChange}
//         allowMultiple={false}
//         acceptedFileTypes={['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
//       />
//       <ReactQuill
//         theme="snow"
//         value={content}
//         onChange={handleContentChange}
//         ref={quillRef}
//       />
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default WordEditor;



// import React, { useState, useRef } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { FilePond, registerPlugin } from 'react-filepond';
// import 'filepond/dist/filepond.min.css';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// // Register the file validation plugin for FilePond
// registerPlugin(FilePondPluginFileValidateType);


// const WordEditor = () => {
//   const [file, setFile] = useState(null);
//   const [content, setContent] = useState('');
//   const quillRef = useRef(null);

//   const handleFileChange = (fileItems) => {
//     if (fileItems.length > 0) {
//       const selectedFile = fileItems[0].file;
//       setFile(selectedFile);

//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setContent(e.target.result);
//       };
//       reader.readAsText(selectedFile);
//     }
//   };

//   const handleContentChange = (value) => {
//     setContent(value);
//   };

//   const handleDownload = () => {
//     const blob = new Blob([content], { type: 'application/msword' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = 'edited_document.docx';
//     link.click();
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <div>
//       <FilePond
//         label="Upload a Word Document"
//         onupdatefiles={handleFileChange}
//         allowMultiple={false}
//         acceptedFileTypes={['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
//       />
//       <ReactQuill
//         theme="snow"
//         value={content}
//         onChange={handleContentChange}
//         ref={quillRef}
//       />
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default WordEditor;



// // import React from 'react';
// // import DocViewer, { DocViewerRenderers, PDFRenderer } from 'react-doc-viewer';

// // const ReactDocViewer = ({ file }) => {
// //   const docs = [{ uri: URL.createObjectURL(file) }];

// //   return (
// //     <div>
// //       <DocViewer
// //         documents={docs}
// //         pluginRenderers={[PDFRenderer, DocViewerRenderers]}
// //         style={{ height: '600px', width: '100%' }}
// //       />
// //     </div>
// //   );
// // };

// // export default ReactDocViewer;

// // import React, { useState } from 'react';
// // import FileViewer from 'react-file-viewer';

// // const ReactDocViewer = () => {
// //   const [selectedFile, setSelectedFile] = useState(null);

// //   const handleFileChange = (event) => {
// //     const file = event.target.files[0];
// //     if (file) {
// //       setSelectedFile(file);
// //     }
// //   };

// //   return (
// //     <div>
// //       {/* File input to allow user to choose file */}
// //       <input type="file" onChange={handleFileChange} />

// //       {/* Display the file viewer if a file is selected */}
// //       {selectedFile && (
// //         <div style={{ width: '100%', height: '600px', marginTop: '20px' }}>
// //           <FileViewer
// //             fileType={selectedFile.name.split('.').pop()}  // Get file extension
// //             filePath={URL.createObjectURL(selectedFile)}    // Create a URL for the file
// //             onError={(error) => console.error('Error rendering file:', error)}
// //           />
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ReactDocViewer;

// // import React, { useState, useRef } from 'react';
// // import { renderAsync } from 'docx-preview';

// // const ReactDocViewer = () => {
// //   const [fileName, setFileName] = useState('');
// //   const previewContainerRef = useRef(null);

// //   const handleFileChange = async (event) => {
// //     const file = event.target.files[0];
// //     if (file && file.name.endsWith('.docx')) {
// //       setFileName(file.name);

// //       try {
// //         // Clear the previous preview
// //         previewContainerRef.current.innerHTML = '';

// //         // Read the DOCX file as an ArrayBuffer
// //         const arrayBuffer = await file.arrayBuffer();

// //         // Render the DOCX content into the preview container
// //         await renderAsync(arrayBuffer, previewContainerRef.current, null);
// //       } catch (error) {
// //         console.error('Error previewing DOCX file:', error);
// //         previewContainerRef.current.innerHTML = '<p>Could not preview the document.</p>';
// //       }
// //     } else {
// //       alert('Please select a .docx file.');
// //     }
// //   };

// //   return (
// //     <div>
// //       <h2>DOCX File Previewer</h2>
// //       <input type="file" accept=".docx" onChange={handleFileChange} />
// //       {fileName && (
// //         <div>
// //           <h3>Preview of: {fileName}</h3>
// //           <div
// //             ref={previewContainerRef}
// //             style={{ border: '1px solid #ddd', padding: '10px', marginTop: '10px', maxHeight: '400px', overflowY: 'auto' }}
// //           />
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ReactDocViewer;