import React from 'react';

function ResourcerDashboardPage(props) {
  return (
    <div>
      <h1 style={{ marginLeft: '20px' }}>Hey ! Welcome Back ! </h1>
    </div>
  );
}

export default ResourcerDashboardPage;
