import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function StartRoleWorkingDialog({ open, onClose }) {
  const roleOptions = [
    { id: 1, label: 'Searching' },
    { id: 2, label: 'Connectivity' },
    { id: 3, label: 'Calling' },
  ];
  const searchingOptions = ['Job Boards', 'LI Searching'];
  const connectivityOptions = ['Sending WhatsApp Messages', 'Sending SMS', 'Sending LI Connections', 'Sending InMails'];
  const [selectedTask, setSelectedTask] = useState();
  const [selectedSubTask, setSelectedSubTask] = useState();
  const navigate = useNavigate();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '600px',
            height: selectedSubTask ? '450px' : 'auto',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Start working on this role
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
            <FormControl>
              <label
                htmlFor="select-single-native"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Choose a Primary Task
              </label>
              <Autocomplete
                options={roleOptions} // Array of options
                getOptionLabel={(option) => option?.label || ''} // Specifies which field to display
                value={selectedTask ? roleOptions.find((option) => option.label === selectedTask) : null}
                onChange={(event, newValue) => setSelectedTask(newValue ? newValue.label : '')}
                isOptionEqualToValue={(option, value) => option.label === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select an option" // Optional placeholder
                    sx={{
                      width: '500px',
                      '.MuiOutlinedInput-root': {
                        color: '#000000', // Text color
                        padding: '8px 10px',
                        height: '50px',
                        backgroundColor: '#FFFFFF', // White background
                        border: '2px solid #D9D9D9', // Grey border
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Grey border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Hover border color
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Focus border color
                      },
                      '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                        color: '#000000', // Icon color
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>

          {selectedTask === 'Searching' && selectedTask !== 'Connectivity' && (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
                <FormControl>
                  <label
                    htmlFor="select-single-native"
                    style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                  >
                    Select a Sub-Task for Searching:
                  </label>
                  <Autocomplete
                    options={searchingOptions} // Array of options
                    getOptionLabel={(option) => option} // Specifies which field to display
                    value={selectedSubTask ? searchingOptions.find((option) => option === selectedSubTask) : null}
                    onChange={(event, newValue) => setSelectedSubTask(newValue)}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select an option" // Optional placeholder
                        sx={{
                          width: '500px',
                          '.MuiOutlinedInput-root': {
                            color: '#000000', // Text color
                            padding: '8px 10px',
                            height: '50px',
                            backgroundColor: '#FFFFFF', // White background
                            border: '2px solid #D9D9D9', // Grey border
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Grey border color
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Hover border color
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Focus border color
                          },
                          '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                            color: '#000000', // Icon color
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </>
          )}
          {selectedTask === 'Connectivity' && selectedTask !== 'Searching' && (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
                <FormControl>
                  <label
                    htmlFor="select-single-native"
                    style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                  >
                    Select a Sub-Task for Connectivity:
                  </label>
                  <Autocomplete
                    options={connectivityOptions} // Array of options
                    getOptionLabel={(option) => option} // Specifies which field to display
                    value={selectedSubTask ? connectivityOptions.find((option) => option === selectedSubTask) : null}
                    onChange={(event, newValue) => setSelectedSubTask(newValue)}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select an option" // Optional placeholder
                        sx={{
                          width: '500px',
                          '.MuiOutlinedInput-root': {
                            color: '#000000', // Text color
                            padding: '8px 10px',
                            height: '50px',
                            backgroundColor: '#FFFFFF', // White background
                            border: '2px solid #D9D9D9', // Grey border
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Grey border color
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Hover border color
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D9D9D9', // Focus border color
                          },
                          '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                            color: '#000000', // Icon color
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </>
          )}
          <br />

          <Box display="flex" justifyContent="center">
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              onClick={() => {
                navigate(`/recruiter/jobApplicants/candidateWorking/1`);
              }}
            >
              Start Role
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StartRoleWorkingDialog;
