import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CandidatePipelineTable } from './components';
import { StartRoleWorkingDialog } from './candidates-working';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { FadeLoader } from 'react-spinners';

function RoleApplications(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openStartRoleWorkingDialog, setOpenStartRoleWorkingDialog] = useState(false);
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'New',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'In Review',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Available',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Engaged',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Hired',
      value: 25,
      borderTop: '#67E4BB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Offered',
      value: 25,
      borderTop: '#E0CC38',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Rejected',
      value: 25,
      borderTop: '#B0CDCF',
      buttonFontSize: '20px',
    },
  ];

  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getRoleCandidatesById,
        (resp) => {
          setRoleCandidates(resp?.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId, page, limit]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  // implement the fade loader from react-spinners until the data is fetched

  return (
    <div style={{ width: '97%', margin: 'auto', height: '90vh' }}>
      {/* {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          {' '}
          <FadeLoader color={'#36D7B7'} loading={loading} />{' '}
        </div>
      ) : ( */}
      <>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <ArrowBack
            fontSize="small"
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              color: '#00C1FE',
              cursor: 'pointer',
            }}
          />
          &nbsp;&nbsp;{' '}
          <Typography
            variant="subtitle2"
            sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {loading ? (
            <Skeleton variant="text" width="40%" />
          ) : (
            <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>
              Candidates associated to {roleCandidates?.roleTitle}
            </p>
          )}
          <Box display="flex" alignItems="center" marginRight="1rem">
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              onClick={() => {
                navigate(`/recruiter/jobApplicants/roleSequence/${roleId}`);
              }}
            >
              Sequences
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                marginLeft: '10px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              onClick={() => {
                setOpenStartRoleWorkingDialog(true);
              }}
            >
              Start Role
            </Button>
          </Box>
        </Box>
        {/* <br /> */}
        <CandidatePipelineTable
          buttons={buttons}
          onButtonClick={(value) => {}}
          showHiringPipeline={true}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(limit) => setLimit(limit)}
          candidates={roleCandidates}
          totalCandidates={roleCandidates?.pagination?.totalCandidates}
          showButtons={false}
          loading={loading}
        />
      </>
      {/* )} */}

      {openStartRoleWorkingDialog && (
        <StartRoleWorkingDialog
          open={openStartRoleWorkingDialog}
          onClose={() => {
            setOpenStartRoleWorkingDialog(false);
          }}
        />
      )}
    </div>
  );
}

export default RoleApplications;
