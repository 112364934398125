import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { CVFormat } from '.';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URLS } from 'src/constants/apiURLs';
import { notify } from 'src/components/notify';
import { Download } from '@mui/icons-material';
import { Post } from 'src/actions/API/apiActions';

function CVsTable({ roleCVs, onPageChange, onRowsPerPageChange, totalRows }) {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [SelectedCV, setSelectedCV] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };
  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const [openCVDialog, setOpenCVDialog] = useState(false);

  // const handleCVDownload = (candidateId, action) => {
  //   try {
  //     fetch(API_URLS.downloadCV, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ id: candidateId }),
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error('Error downloading file');
  //         }
  //         const contentDisposition = response.headers.get('Content-Disposition');
  //         const filename = contentDisposition
  //           ? contentDisposition.split('filename=')[1].replace(/"/g, '') // Get the filename
  //           : candidateId; // Fallback to candidateId if header is missing

  //         return response.blob().then((blob) => ({ blob, filename }));
  //       })
  //       .then(({ blob, filename }) => {
  //         if (action === 'view') {
  //           const url = window.URL.createObjectURL(blob);
  //           setSelectedCV(url);
  //           setOpenCVDialog(true);
  //           return;
  //         }else{
  //           const url = window.URL.createObjectURL(blob);
  //           const a = document.createElement('a');
  //           a.href = url;
  //           a.download = filename; // Use the extracted filename
  //           document.body.appendChild(a);
  //           a.click();
  //           a.remove();
  //           window.URL.revokeObjectURL(url);
  //         }
  //         notify('File downloaded successfully', 'success', 1000);
  //       })
  //       .catch((error) => {
  //         notify('Error downloading file', 'error', 1000);
  //       });
  //   } catch (error) {
  //     notify('Error downloading file', 'error', 1000);
  //   }
  // };

  const handleCVDownload = (candidateId, action) => {
    try {
      fetch(API_URLS.downloadCV, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: candidateId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error downloading file');
          }
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/"/g, '') // Extract filename
            : candidateId; // Fallback to candidateId if header is missing

          return response.blob().then((blob) => ({ blob, filename }));
        })
        .then(({ blob, filename }) => {
          if (action === 'view') {
            setOpenCVDialog(true);
            // Convert blob to File and set it in selected state
            const file = new File([blob], filename, { type: blob.type });
            if (blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              try {
                const formData = new FormData();
                formData.append('file', file);
                Post(
                  formData,
                  API_URLS.extractText,
                  (resp) => {
                    // setSelectedCV({
                    //   docx: resp,
                    // });
                    setSelectedFile({
                      file: file,
                      docx: resp,
                    }); // Set the file in the desired state
                  },
                  (error) => {
                    console.log('error', error);
                  }
                );
              } catch (error) {
                console.log('error', error);
              }
            } else {
              setSelectedFile({
                file: file,
                docx: null,
              }); // Set the file in the desired state
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          }
          notify('File downloaded successfully', 'success', 1000);
        })
        .catch((error) => {
          notify('Error downloading file', 'error', 1000);
        });
    } catch (error) {
      notify('Error downloading file', 'error', 1000);
    }
  };

  console.log('jsdfsdafsdafsda', selectedFile);

  return (
    <div>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC' }}>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  CVs
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {' '}
                  Status
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  Actions
                </TableCell>
                <TableCell>
                  <Button
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      width: '110px',
                      height: '40px',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: '#00C1FE',
                        color: 'white',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                    }}
                    onClick={() => {
                      navigate(`/resourcing/uploadCV/${roleId}`);
                    }}
                  >
                    Upload CV
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleCVs?.length > 0 ? (
                <>
                  {roleCVs?.map((cv) => (
                    <TableRow>
                      <TableCell>{cv?.role_cvs_candidate?.full_name}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            backgroundColor: '#FFEFCA',
                            color: '#FDC748',
                            padding: '5px',
                            width: '105px',
                            margin: '5px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'flex', // Flexbox layout
                            alignItems: 'center', // Align items horizontally in the center
                            justifyContent: 'center', // Center content horizontally
                          }}
                        >
                          <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                          {cv?.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-around" alignItems="center">
                          {/* <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Reject LI" arrow placement="top">
                          <Icon
                            icon="bitcoin-icons:cross-filled"
                            style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                          />
                        </Tooltip>
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Accept LI" arrow placement="top">
                          <Icon icon="mdi:tick" style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }} />
                        </Tooltip>
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#E1F7FC',
                          height: '34px',
                          //   width: '15px',
                          '&:hover': {
                            backgroundColor: '#E1F7FC',
                          },
                          borderRadius: '5px',
                        }}
                      >
                        {' '}
                        <Tooltip title="Edit LI" arrow placement="top">
                          <Icon
                            icon="material-symbols-light:edit-outline"
                            style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                          />
                        </Tooltip>
                      </Button> */}
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '100px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => {
                              console.log('hjsdfsdfsdf', cv?.role_cvs_candidate?.id);
                              // setOpenCVDialog(true);
                              // setSelectedCV(cv);
                              handleCVDownload(cv?.role_cvs_candidate?.id, 'view');
                            }}
                          >
                            View CV
                          </Button>
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '100px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => {
                              console.log('hjsdfsdfsdf', cv?.role_cvs_candidate?.id);
                              // setOpenCVDialog(true);
                              // setSelectedCV(cv);
                              handleCVDownload(cv?.role_cvs_candidate?.id, 'download');
                            }}
                          >
                            <Download />
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No CVs found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={totalRows} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {openCVDialog && (
        <Dialog
          open={openCVDialog}
          onClose={() => setOpenCVDialog(false)}
          PaperProps={{
            sx: {
              width: '80vw', // Fixed width
              maxWidth: '900px', // Optional max-width for larger screens
              height: '90vh', // Fixed height
              maxHeight: '90vh', // Ensures it fits within viewport
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // Background color adjustment
              overflow: 'hidden', // Ensures the Box handles scrolling
            },
          }}
        >
          {selectedFile ? (
            <Box
              sx={{
                height: '100%',
                overflowY: 'auto',
                padding: '20px',
              }}
            >
              {selectedFile?.file?.type === 'application/pdf' ? (
                <>
                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                    Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                  </Typography>
                  <iframe
                    title={selectedFile?.file?.name}
                    src={URL.createObjectURL(selectedFile?.file)}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                  ></iframe>
                </>
              ) : selectedFile?.file?.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                <>
                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                    Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                      height: '100vh',
                      overflowY: 'auto',
                      padding: '20px',
                      border: '1px solid #00C1FE',
                      borderRadius: '10px',
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedFile?.docx?.htmlContent }}
                  ></div>
                </>
              ) : (
                <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                  No Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="subtitle1" sx={{ margin: '20px' }}>
              No CV selected for preview
            </Typography>
          )}
        </Dialog>
      )}
    </div>
  );
}

export default CVsTable;
