import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { active_within, databaseOptions, jobTypes, languages, qualificationsss, sectors, signedInOptions } from './constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AdvanceSearchComponent(props) {
  const [minimumSalary, setMinimumSalary] = useState();
  const [maximumSalary, setMaximumSalary] = useState();
  const [salaryType, setSalaryType] = useState('per-annum');
  const [sector, setSector] = useState('');
  const [open, setOpen] = useState(true); // To control if Select is expanded
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [signedIn, setSignedIn] = useState('');
  const [activeWithin, setActiveWithin] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [language, setLanguage] = useState([]);
  const [languageProficiency, setLanguageProficiency] = useState('inter');
  const [selectedOption, setSelectedOption] = useState('');
  const [searchIn, setSearchIn] = useState('');
  const handleChange = (event) => {
    const selectedValue = event.target.value;

        if (selectedValue === sector) {
            setSector('');
        } else {
            setSector(selectedValue);
        }
  };

  const handleOpen = () => {
    setOpen(true); // Set to true to show expanded
  };

  const handleClose = () => {
    setOpen(false); // Set to false to close when needed
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedJobTypes([...selectedJobTypes, value]);
    } else {
      setSelectedJobTypes(selectedJobTypes.filter((item) => item !== value));
    }
  };

  // Set the first option when signedInOptions or signedIn changes
  useEffect(() => {
    if (signedInOptions?.length > 0 && !signedIn) {
      setSignedIn(signedInOptions[0]);
    }
  }, [signedInOptions, signedIn]);

// Set the first option when active_within or activeWithin changes
useEffect(() => {
    if (active_within?.length > 0 && !activeWithin) {
      setActiveWithin(active_within[0]);
    }
  }, [active_within, activeWithin]);
  
  // Set the first option when qualificationsss or qualifications changes
  useEffect(() => {
    if (qualificationsss?.length > 0 && !qualifications) {
      setQualifications(qualificationsss[0]);
    }
  }, [qualificationsss, qualifications]);
  
  const handleChangeLanguage = (event) => {
    const {
      target: { value },
    } = event;
    
    // Allowing selection of up to 5 items
    if (value.length <= 5) {
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const handleCheckboxChangeOption = (event) => {
    // Set the selected option to the value of the clicked checkbox
    // This allows only one checkbox to be selected at a time
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (databaseOptions?.length > 0 && !searchIn) {
      setSearchIn(databaseOptions[0]);
    }
  }, [databaseOptions, searchIn]);
  return (
    <div style={{ backgroundColor: '#F1FCFE', marginTop: '2rem', padding: '20px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left' }}>Salary</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              id="job_title"
              name="job_title"
              type="text"
              placeholder="$"
              size="large"
              value={minimumSalary}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMinimumSalary(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
            <Icon icon="hugeicons:minus-sign" style={{ fontSize: '26px', marginLeft: '10px', marginRight: '10px' }} />
            <TextField
              id="job_title"
              name="job_title"
              type="text"
              placeholder="$"
              size="large"
              value={maximumSalary}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMaximumSalary(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <ToggleButtonGroup
            value={salaryType}
            size="large"
            exclusive
            style={{
              height: 45,
              border: '1px solid #ECECEC',
              borderRadius: '5px',
              boxSizing: 'border-box',
              width: '75%',
            }}
            onChange={(event, value) => {
              setSalaryType(value);
            }}
          >
            <ToggleButton
              value="per-annum"
              style={{
                borderRight: '1px solid #ccc',
                borderTopLeftRadius: '5px',
                width: '100%',
                borderBottomLeftRadius: '5px',
                background: salaryType === 'per-annum' ? '#EBEBEB' : 'white',
                fontWeight: 'normal',
              }}
            >
              Per annum
            </ToggleButton>
            <ToggleButton
              value="per-hour"
              fullWidth
              style={{
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                background: salaryType === 'per-hour' ? '#EBEBEB' : 'white',
                fontWeight: 'normal',
              }}
            >
              Per hour
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <hr
        style={{
          width: '98%',
          backgroundColor: '#000000',
          height: '1px',
          border: 'none',
          marginTop: '15px',
        }}
      />
      <Box>
        <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '10px' }}>Sectors</p>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" flexDirection="column">
          <FormControl>
            <label
              htmlFor="select-single-native"
              style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
            >
              Select Sector
            </label>
            <Select
              native
              value={sector}
              onChange={handleChange}
              open={open} // Control the open state
              onClose={handleClose}
              onOpen={handleOpen}
              inputProps={{
                id: 'select-single-native',
              }}
              // Style for height of the dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set specific height for the dropdown
                  },
                },
              }}
              sx={{
                width: '500px',
                '.MuiSelect-select': {
                  color: '#000000', // Text color
                  padding: '8px 10px',
                  height: '20px',

                  borderRadius: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Grey border color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Hover border color
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Focus border color
                },
                '.MuiSelect-select.MuiSelect-outlined': {
                  backgroundColor: '#FFFFFF', // White background
                  border: '2px solid #D9D9D9', // Grey border
                },
                '.MuiSelect-icon': {
                  color: '#000000', // Icon color
                },
              }}
            >
              {sectors?.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <hr style={{ width: '98%', backgroundColor: '#000000', height: '1px', border: 'none', marginTop: '15px' }} />
      <Box>
        <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '10px' }}>
          Candidate looking for
        </p>
        <FormGroup>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              {/* First column with 3 checkboxes, aligned */}
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {jobTypes?.slice(0, 3).map((jobType) => (
                  <FormControlLabel
                    key={jobType}
                    control={
                      <Checkbox
                        value={jobType}
                        checked={selectedJobTypes.includes(jobType)}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: '#D9D9D9',
                          '&.Mui-checked': {
                            color: '#00C1FE', // Set the color to blue when the checkbox is checked
                          },
                        }}
                      />
                    }
                    label={jobType}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {/* Second column with 2 checkboxes */}
                {jobTypes?.slice(3).map((jobType) => (
                  <FormControlLabel
                    key={jobType}
                    control={
                      <Checkbox
                        value={jobType}
                        checked={selectedJobTypes.includes(jobType)}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: '#D9D9D9',
                          '&.Mui-checked': {
                            color: '#00C1FE', // Set the color to blue when the checkbox is checked
                          },
                        }}
                      />
                    }
                    label={jobType}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
      <hr style={{ width: '98%', backgroundColor: '#000000', height: '1px', border: 'none', marginTop: '15px' }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '20px' }}>Candidate who</p>
          <Box marginTop="20px">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Select
                  value={signedIn || ''}
                  onChange={(event) => setSignedIn(event.target.value)}
                  sx={{
                    width: '200px',
                    '.MuiSelect-select': {
                      color: '#000000', // Text color
                      padding: '8px 10px',
                      height: '10px',
                      borderRadius: 0,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Grey border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Hover border color
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Focus border color
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                      backgroundColor: '#FFFFFF', // White background
                      border: '2px solid #D9D9D9', // Grey border
                    },
                    '.MuiSelect-icon': {
                      color: '#000000', // Icon color
                    },
                  }}
                >
                  {signedInOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <p style={{ fontSize: '18px', textAlign: 'left', color: '#858D99' }}>Within the last</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Select
                  value={activeWithin || ''}
                  onChange={(event) => setActiveWithin(event.target.value)}
                  sx={{
                    width: '200px',
                    '.MuiSelect-select': {
                      color: '#000000', // Text color
                      padding: '8px 10px',
                      height: '10px',
                      borderRadius: 0,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Grey border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Hover border color
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Focus border color
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                      backgroundColor: '#FFFFFF', // White background
                      border: '2px solid #D9D9D9', // Grey border
                    },
                    '.MuiSelect-icon': {
                      color: '#000000', // Icon color
                    },
                  }}
                >
                  {active_within?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '20px' }}>Qualifications</p>
          <Box marginTop="20px">
            <Select
              value={qualifications || ''}
              onChange={(event) => setQualifications(event.target.value)}
              sx={{
                width: '500px',
                '.MuiSelect-select': {
                  color: '#000000', // Text color
                  padding: '8px 10px',
                  height: '10px',

                  borderRadius: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Grey border color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Hover border color
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D9D9D9', // Focus border color
                },
                '.MuiSelect-select.MuiSelect-outlined': {
                  backgroundColor: '#FFFFFF', // White background
                  border: '2px solid #D9D9D9', // Grey border
                },
                '.MuiSelect-icon': {
                  color: '#000000', // Icon color
                },
              }}
            >
              {qualificationsss?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
      <hr style={{ width: '98%', backgroundColor: '#000000', height: '1px', border: 'none', marginTop: '20px' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '15px' }}>Languages</p>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <FormControl sx={{ m: 1, width: 300, marginTop: '15px' }}>
                  <label
                    htmlFor="demo-multiple-checkbox-label"
                    style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                  >
                    Enter upto 5
                  </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={language}
                    onChange={handleChangeLanguage}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{
                      width: '300px',
                      '.MuiSelect-select': {
                        color: '#000000', // Text color
                        padding: '8px 10px',
                        height: '10px',

                        borderRadius: 0,
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Grey border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Hover border color
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Focus border color
                      },
                      '.MuiSelect-select.MuiSelect-outlined': {
                        backgroundColor: '#FFFFFF', // White background
                        border: '2px solid #D9D9D9', // Grey border
                      },
                      '.MuiSelect-icon': {
                        color: '#000000', // Icon color
                      },
                    }}
                  >
                    {languages?.map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        <Checkbox
                          checked={language.includes(lang)}
                          sx={{
                            color: '#D9D9D9',
                            '&.Mui-checked': {
                              color: '#00C1FE', // Set the color to blue when the checkbox is checked
                            },
                          }}
                        />
                        <ListItemText primary={lang} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box marginTop="25px">
                <ToggleButtonGroup
                  value={languageProficiency}
                  size="large"
                  exclusive
                  style={{
                    height: 45,
                    border: '1px solid #ECECEC',
                    borderRadius: '5px',
                    boxSizing: 'border-box',
                    width: '75%',
                  }}
                  onChange={(event, value) => {
                    setLanguageProficiency(value);
                  }}
                >
                  <ToggleButton
                    value="inter"
                    style={{
                      borderRight: '1px solid #ccc',
                      borderTopLeftRadius: '5px',
                      width: '100%',
                      borderBottomLeftRadius: '5px',
                      background: languageProficiency === 'inter' ? '#EBEBEB' : 'white',
                      fontWeight: 'normal',
                    }}
                  >
                    Intermediate
                  </ToggleButton>
                  <ToggleButton
                    value="fluent"
                    fullWidth
                    style={{
                      width: '100%',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                      background: languageProficiency === 'fluent' ? '#EBEBEB' : 'white',
                      fontWeight: 'normal',
                    }}
                  >
                    Fluent
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box marginTop="25px" display={'flex'} flexDirection="column">
            <FormGroup>
              {/* First Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Change color when checked
                      },
                      '&.MuiCheckbox-root': {
                        width: '32px', // Increase width
                        height: '32px', // Increase height
                      },
                    }}
                    checked={selectedOption === 'option1'}
                    value="option1"
                    onChange={handleCheckboxChangeOption}
                  />
                }
                label="Include candidates ineligible to work in the UK"
              />

              {/* Second Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Change color when checked
                      },
                      '&.MuiCheckbox-root': {
                        width: '32px', // Increase width
                        height: '32px', // Increase height
                      },
                    }}
                    checked={selectedOption === 'option2'}
                    value="option2"
                    onChange={handleCheckboxChangeOption}
                  />
                }
                label="Exclude candidates without a driving license"
              />

              {/* Third Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Change color when checked
                      },
                      '&.MuiCheckbox-root': {
                        width: '32px', // Increase width
                        height: '32px', // Increase height
                      },
                    }}
                    checked={selectedOption === 'option3'}
                    value="option3"
                    onChange={handleCheckboxChangeOption} // Use the same handler for all
                  />
                }
                label="Exclude candidates without a car"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>

      <hr style={{ width: '98%', backgroundColor: '#000000', height: '1px', border: 'none', marginTop: '20px' }} />
      <Box display="flex" flexDirection="column">
        <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'left', marginTop: '15px', marginBottom: '10px' }}>
          Search In
        </p>
        <Select
          value={searchIn || ''}
          onChange={(event) => setSearchIn(event.target.value)}
          sx={{
            width: '300px',
            '.MuiSelect-select': {
              color: '#000000', // Text color
              padding: '8px 10px',
              height: '10px',
              borderRadius: 0,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#D9D9D9', // Grey border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#D9D9D9', // Hover border color
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#D9D9D9', // Focus border color
            },
            '.MuiSelect-select.MuiSelect-outlined': {
              backgroundColor: '#FFFFFF', // White background
              border: '2px solid #D9D9D9', // Grey border
            },
            '.MuiSelect-icon': {
              color: '#000000', // Icon color
            },
          }}
        >
          {databaseOptions?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <hr style={{ width: '98%', backgroundColor: '#000000', height: '1px', border: 'none', marginTop: '20px' }} />
      <Box marginTop="20px" display="flex" marginLeft="4rem" alignItems="center">
        <Button
          size="large"
          sx={{
            backgroundColor: '#0ec4fe',
            color: 'white',
            width: '10%',
            marginLeft: '5px',
            '&:hover': {
              color: 'white !important',
              backgroundColor: '#0ec4fe !important',
            },
          }}
        >
          <Icon icon="teenyicons:search-outline" style={{ fontSize: '20px' }} /> &nbsp; Search
        </Button>
        <Button
          size="large"
          sx={{
            backgroundColor: '#F1FCFE',
            color: '#979797',
            width: '10%',
            marginLeft: '5px',
            '&:hover': {
              color: '#979797 !important',
              backgroundColor: '#F1FCFE !important',
            },
          }}
        >
          Cancel
        </Button>
      </Box>
      <br />
      <br />
    </div>
  );
}

export default AdvanceSearchComponent;
