import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import ViewRolesIssues from './ViewRolesIssues';
import ViewResolvedIssueRoles from './ViewResolvedIssueRoles';
// import { Post_GetResourcerRoles_URL } from 'src/constants/apiURLs';
// import { ViewResolvedIssueRoles, ViewRolesIssues } from '.';

function IndividualReportDialog({ open, onClose, resourcer }) {
  console.log('resourcer', resourcer);
  const [loading, setLoading] = useState(false);
  const [roleDate, setRoleDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(true);
  const [previousMonth, setPreviousMonth] = useState(false);
  const [roles, setRoles] = useState([]);
  const [resourcerRoleStats, setResourcerRoleStats] = useState({});
  const [openViewIssuesDialog, setOpenViewIssuesDialog] = useState(false);
  const [issueRoles, setIssueRoles] = useState([]);
  const [resolvedRoles, setResolvedRoles] = useState([]);
  const [openViewResolvedIssuesDialog, setOpenViewResolvedIssuesDialog] = useState(false);
  const columns = [
    {
      accessorKey: 'client_number',
      header: 'Client Number',
    },
    {
      accessorKey: 'title',
      header: 'Role Title',
      minWidth: 170,
    },
    {
      accessorKey: 'roleStatus',
      header: 'Status',
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor:
              cell.getValue() === 'issue'
                ? theme.palette.error.main
                : cell.getValue() === 'done'
                ? 'white'
                : cell.getValue() === 'completed'
                ? '#43EE3F'
                : cell.getValue() === 'pending'
                ? theme.palette.warning.main
                : cell.getValue() === 'inProgress'
                ? '#2196f3'
                : cell.getValue() === 're-working'
                ? '#D3C2CE'
                : cell.getValue() === 'final'
                ? theme.palette.success.dark
                : cell.getValue() === 'resolved'
                ? '#FF9900'
                : 'transparent',
            borderRadius: '0.25rem',
            p: '0.50rem',
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'roleDate',
      header: 'Date',
      Cell: ({ cell }) => {
        const dateString = cell?.row?.original?.roleDate;
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const humanReadableDate = date.toLocaleDateString('en-US', options);
        return humanReadableDate;
      },
    },
    {
      accessorKey: 'noOfCVsUploaded',
      header: 'CVs',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{!cell?.row?.original.noOfCVsUploaded ? 0 : cell?.row?.original.noOfCVsUploaded}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'noOfLiPrfilesAdded',
      header: 'LIS',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{cell?.row?.original.noOfLiPrfilesAdded ? cell?.row?.original.noOfLiPrfilesAdded : 0}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'kuch bhi',
      header: 'Accepted CVs',
      // get the cvs where cvstatus is true from role cvs
      Cell: ({ cell }) => {
        const acceptedCVs = cell?.row?.original?.roleCVs.filter((cv) => cv.CVStatus === true);
        return acceptedCVs.length;
      },
    },
    {
      accessorKey: 'kuch bhi',
      header: 'Rejected CVs',
      // get the cvs where cvstatus is false from role cvs
      Cell: ({ cell }) => {
        const rejectedCVs = cell?.row?.original?.roleCVs.filter((cv) => cv.CVStatus === false);
        return rejectedCVs.length;
      },
    },
    {
      accessorKey: 'kuch bhi',
      header: 'Accepted LIS',
      // get the lis where liStatus is true from role lis
      Cell: ({ cell }) => {
        const acceptedLIS = cell?.row?.original?.RoleLIs.filter((li) => li.LIStatus === true);
        return acceptedLIS.length;
      },
    },
    {
      accessorKey: 'kuch bhi',
      header: 'Rejected LIS',
      // get the lis where liStatus is true from role lis
      Cell: ({ cell }) => {
        const acceptedLIS = cell?.row?.original?.RoleLIs.filter((li) => li.LIStatus === false);
        return acceptedLIS.length;
      },
    },
    {
      accessorKey: 'location',
      header: 'Location',
    },
    {
      accessorKey: 'JobBoardUsed',
      header: 'Job boards',
      minWidth: 170,
      // getting job board used as :
      // "{\"reeds\":12,\"cvl\":23,\"totalJobs\":45,\"linkedIn\":45,\"zoomInfo\":67}"
      //show the job boards seperated by comma
      Cell: ({ cell }) => {
        const jobBoardInfo = cell?.row?.original?.JobBoardUsed;
        const jobBoards = JSON.parse(jobBoardInfo);
        if (jobBoards === null || jobBoards === undefined) {
          return 'N/A';
        } else {
          return (
            <Box component="span">
              <span>{jobBoards?.reeds ? 'Reeds' : ''}</span>
              <span>{jobBoards?.cvl ? ', CVL' : ''}</span>
              <span>{jobBoards?.linkedIn ? ', LinkedIn' : ''}</span>
              <span>{jobBoards?.zoomInfo ? ', Zoom Info' : ''}</span>
            </Box>
          );
        }
      },
    },
    {
      accessorKey: 'kuchbhi',
      header: 'Views',
      minWidth: 170,
      // getting job board used as :
      // "{\"reeds\":12,\"cvl\":23,\"totalJobs\":45,\"linkedIn\":45,\"zoomInfo\":67}"
      //show the job boards values seperated by comma
      Cell: ({ cell }) => {
        const jobBoardInfo = cell?.row?.original?.JobBoardUsed;
        const jobBoards = JSON.parse(jobBoardInfo);
        if (jobBoards === null || jobBoards === undefined) {
          return 'N/A';
        } else {
          return (
            <Box component="span">
              <span>{jobBoards?.reeds ? jobBoards.reeds : ''}</span>
              <span>{jobBoards?.cvl ? ', ' + jobBoards.cvl : ''}</span>
              <span>{jobBoards?.linkedIn ? ', ' + jobBoards.linkedIn : ''}</span>
              <span>{jobBoards?.zoomInfo ? ', ' + jobBoards.zoomInfo : ''}</span>
            </Box>
          );
        }
      },
    },
  ];

  const getResourcerRoles = useCallback(() => {
    try {
      setLoading(true);
      Post(
        {
          id: resourcer?.id,
          roleDate: roleDate ? roleDate : null,
          currentMonth: currentMonth,
          previousMonth: previousMonth,
        },
        null, // Post_GetResourcerRoles_URL,
        (resp) => {
          setRoles(resp?.roles);
          setLoading(false);
          setResourcerRoleStats(resp);
        },
        (error) => {
          notify('Failed to retrieve roles', 'error', 1000);
          setLoading(false);
        }
      );
    } catch (error) {
      notify('Failed to retrieve roles', 'error', 1000);
      setLoading(false);
    }
  }, [roleDate, currentMonth, previousMonth]);

  useEffect(() => {
    getResourcerRoles();
  }, [getResourcerRoles]);

  function getMonthName(dateString) {
    const dateArray = dateString.split('-');
    const month = parseInt(dateArray[1]) - 1;
    const dateObj = new Date(dateArray[0], month);
    return dateObj.toLocaleString('default', { month: 'long' });
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box
            display="flex"
            sx={{
              width: 'calc(100%)',
              backgroundColor: '#D9EEFF',
            }}
          >
            <IconButton onClick={onClose}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h4"
              sx={{
                padding: '15px',
                marginLeft: '5px',
              }}
            >
              {resourcer?.firstname + ' ' + resourcer?.lastname + ' Work Details'}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex">
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#57CC99',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#57CC99 !important',
                      backgroundColor: '#57CC99 !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setCurrentMonth(true);
                    setPreviousMonth(false);
                    setRoleDate(null);
                  }}
                >
                  Current Month
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#0ec4fe',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#0ec4fe !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setPreviousMonth(true);
                    setCurrentMonth(false);
                    setRoleDate(null);
                  }}
                >
                  Previous Months
                </Button>
                <TextField
                  type="date"
                  value={roleDate}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      border: '2px solid #0EC4FE',
                      width: '16ch',
                      marginLeft: '10px',
                      height: '50px',
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  size="small"
                  onChange={(e) => {
                    setRoleDate(new Date(e.target.value).toISOString().split('T')[0]);
                    setCurrentMonth(false);
                    setPreviousMonth(false);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#E95A5A',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#E95A5A !important',
                      backgroundColor: '#E95A5A !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setOpenViewIssuesDialog(true);
                    //filter the roles where status is issue
                    const issueRoles = roles.filter((role) => role.roleStatus === 'issue');
                    setIssueRoles(issueRoles);
                  }}
                >
                  Total Issues : {resourcerRoleStats?.totalIssues}
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '16ch',
                    backgroundColor: '#57CC99',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#57CC99 !important',
                      backgroundColor: '#57CC99 !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setOpenViewResolvedIssuesDialog(true);
                    const resolvedRoles = roles.filter((role) => role.roleStatus === 'resolved');
                    setResolvedRoles(resolvedRoles);
                  }}
                >
                  Resolved Issues : {resourcerRoleStats?.resolved}
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '18ch',
                    backgroundColor: '#0ec4fe',
                    marginLeft: '10px',
                    marginRight: '30px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#0ec4fe !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  size="small"
                  onClick={() => setOpenViewIssuesDialog(true)}
                >
                  Remaining Issues : {resourcerRoleStats?.remaining}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          {previousMonth === false && (
            <MaterialReactTable
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 50,
                },
              }}
              columns={columns}
              data={roles}
              defaultColumn={{
                size: 100, // make columns wider by default
              }}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableSorting={false}
              enableTopToolbar={false}
              positionToolbarAlertBanner="bottom"
              manualFiltering
              state={{
                isLoading: loading,
              }}
              actionsColumnIndex={-1}
              getRowProps={(row) => {
                return {
                  style: {
                    backgroundColor: row.original.roleStatus === 'issue' ? '#f44336' : 'inherit',
                  },
                };
              }}
            />
          )}

          <br />
          {previousMonth === true && (
            <>
              {resourcerRoleStats?.monthData &&
                Object.keys(resourcerRoleStats?.monthData)?.map((month) => (
                  <div key={month}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      <Box>
                        <h2
                          style={{
                            fontSize: '16px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            height: '45px',
                            color: 'white',
                            width: '11ch',
                            backgroundColor: '#0ec4fe',
                            marginLeft: '10px',
                            marginRight: '30px',
                            borderColor: '#0ec4fe',
                            padding: '5px',
                            borderStyle: 'solid', // You need
                            borderStyle: 'solid', // You need to specify the border style to see the border
                          }}
                        >
                          {getMonthName(month)} 2024
                        </h2>
                      </Box>

                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #E95A5A',
                            height: '45px',
                            color: '#E95A5A',
                            width: '15ch',
                            '&:hover': {
                              borderColor: '#E95A5A !important',
                              color: '#E95A5A !important',
                            },
                          }}
                          size="small"
                          color="error"
                          onClick={() => {
                            setOpenViewIssuesDialog(true);
                            const issueRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'issue'
                            );
                            setIssueRoles(issueRoles);
                          }}
                        >
                          Total Issues : {resourcerRoleStats?.monthData[month]?.totalIssues}
                        </Button>
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #57CC99',
                            height: '45px',
                            color: '#57CC99',
                            marginLeft: '10px',
                            width: '18ch',
                            '&:hover': {
                              borderColor: '#57CC99 !important',
                              color: '#57CC99 !important',
                            },
                          }}
                          size="small"
                          color="success"
                          onClick={() => {
                            setOpenViewResolvedIssuesDialog(true);
                            const resolvedRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'resolved'
                            );
                            setResolvedRoles(resolvedRoles);
                          }}
                        >
                          Resolved Issues : {resourcerRoleStats?.monthData[month]?.resolved}
                        </Button>
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #0ec4fe',
                            height: '45px',
                            color: '#0ec4fe',
                            marginLeft: '10px',
                            width: '18ch',
                            '&:hover': {
                              borderColor: '#0ec4fe !important',
                              color: '#0ec4fe !important',
                            },
                          }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            setOpenViewIssuesDialog(true);
                            const issueRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'issue'
                            );
                            setIssueRoles(issueRoles);
                          }}
                        >
                          Remaining Issues : {resourcerRoleStats?.monthData[month]?.remaining}
                        </Button>
                      </Box>
                    </div>

                    <MaterialReactTable
                      displayColumnDefOptions={{
                        'mrt-row-actions': {
                          muiTableHeadCellProps: {
                            align: 'center',
                          },
                          size: 50,
                        },
                      }}
                      columns={columns}
                      data={resourcerRoleStats?.monthData[month]?.roles || []}
                      defaultColumn={{
                        size: 100, // make columns wider by default
                      }}
                      enableColumnActions={false}
                      enableColumnFilters={false}
                      enableSorting={false}
                      enableTopToolbar={false}
                      positionToolbarAlertBanner="bottom"
                      manualFiltering
                      state={{
                        isLoading: loading,
                      }}
                      actionsColumnIndex={-1}
                      getRowProps={(row) => {
                        return {
                          style: {
                            backgroundColor: row.original.roleStatus === 'issue' ? '#f44336' : 'inherit',
                          },
                        };
                      }}
                    />
                  </div>
                ))}
            </>
          )}
        </DialogContent>
      </Dialog>

      <ViewRolesIssues open={openViewIssuesDialog} onClose={() => setOpenViewIssuesDialog(false)} roles={issueRoles} />
      <ViewResolvedIssueRoles
        open={openViewResolvedIssuesDialog}
        onClose={() => setOpenViewResolvedIssuesDialog(false)}
        roles={resolvedRoles}
      />
    </div>
  );
}

export default IndividualReportDialog;
