import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import QuillEditor from './QuilEditor';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { Get, Post } from 'src/actions/API/apiActions';
import debounce from 'lodash/debounce';
import WordEditor from 'src/components/react-doc-viewer/ReactDocViewer';
import { HTMLEditor } from 'src/sections/admin/templates/components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function AddNewRole({ open, onClose , onRoleAdded }) {
  const userId = 1;
  const [selectedClient, setSelectedClient] = useState(null);
  const [docFileTemplate, setDocFileTemplate] = useState(null);
  const initialValues = {
    title: '',
    location: '',
    leadId: null,
    role_date: new Date().toISOString().split('T')[0],
    end_date: null,
    role_type: '',
    client_number: null,
    clientId: null,
    service_id: null,
    acm_name: '',
    user_id: userId,
    role_number: null,
    no_credit_charge_reason: null,
    chargeCredit: '',
  };

  const cellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
  };
  const roleCategory = ['Fix', 'Regular', 'Trial'];
  const chargeCreditOptions = ['Yes', 'No'];
  const acmOptions = ['Areeba', 'Shaila', 'Zainab', 'Caan'];

  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [clientRoles, setClientRoles] = useState([]);
  const [maxRoleNumber, setMaxRoleNumber] = useState(1);
  const getAllServices = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.getAllServices,
        (resp) => {
          let tempServices = [];
          resp?.data?.forEach((service) => {
            if (
              service?.service_name === 'CV-Sourcing' ||
              service?.service_name === 'Pre-Qualification' ||
              service?.service_name === '360/Direct'
            ) {
              tempServices.push(service);
            }
          });
          setServices(tempServices);
        },
        (error) => {
          enqueueSnackbar("Can't load Services", 'error');
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong at server', 'error');
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getAllServices();
  }, [getAllServices]);

  const getClientsByServiceId = (serviceId) => {
    try {
      Post(
        { service_id: serviceId },
        API_URLS.getClientsByServiceId,
        (resp) => {
          setClients(resp);
        },
        (error) => {
          enqueueSnackbar("Can't load clients", 'error');
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong at server', 'error');
    }
  };

  // Function to format the ISO date
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
  };

  const handleFileChange = (e) => {
    e.preventDefault();

    // Ensure file exists
    const file = e.target.files[0];
    if (!file) {
      alert('No file selected.');
      return;
    }

    // Check if the file is a .docx by its MIME type
    if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      alert('This file type is not supported. Please choose a .docx file.');
      return;
    }

    // Create FormData object and append the file
    const formData = new FormData();
    formData.append('file', file);

    // Proceed with your API call or logic
    try {
      Post(
        formData,
        API_URLS.extractText,
        (resp) => {
          setDocFileTemplate(resp?.htmlContent + `<br/>`);
        },
        (error) => {
          console.error('Error during file upload:', error);
        }
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  
  const handleUploadFile = (values, actions) => {
    values.doc_file_template = docFileTemplate;
    values.added_by_user_id = userId;
    try {
      Post(
        values,
        API_URLS.attachDocFileTemplate,
        (resp) => {
          actions.setSubmitting(false);
          enqueueSnackbar('Role added successfully', { variant: 'success' });
          onClose();
          setDocFileTemplate(null);
          onRoleAdded();
        },
        (error) => {
          enqueueSnackbar('Fialed to add role', 'error');
        }
      );
    } catch (error) {
      enqueueSnackbar('Something want wrong at server', 'error');
    }
  };

  const handleTextChange = useCallback(
    debounce((htmlText) => {
      setDocFileTemplate(htmlText);
    }, 300), // Adjust debounce delay to suit your needs
    []
  );

  const getRolesOfClient= useCallback((client_number) => {
    try {
      Get(
        { client_number: client_number },
        API_URLS.getRolesByClientNumber,
        (resp) => {
          setClientRoles(resp?.data?.roles);

          // if role are present then get the max role number and set it to the state
          const maxRoleNumber =
            resp?.data?.roles?.length > 0 &&
            Math.max(
              ...resp?.data?.roles.map(
                (role) => role?.roles_working_role?.length > 0 && parseInt(role?.roles_working_role?.role_number)
              )
            );
          setMaxRoleNumber(maxRoleNumber + 1);
        },
        (error) => {
          enqueueSnackbar("Can't load client roles", 'error');
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong at server', 'error');
    }
  },[]);

  console.log('selectedClient', selectedClient?.client_history[0]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '1rem',
            width: '100%',
            maxWidth: '900px',
            height: '95%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Add New Role
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Formik
            initialValues={initialValues}
            //  validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              handleUploadFile(values, actions);
            }}
          >
            {({ errors, values, getFieldProps, setFieldValue, touched, handleSubmit }) => (
              <Form>
                {/* {!editRoleDetails && ( */}
                <>
                  <Autocomplete
                    id="service_id"
                    options={services}
                    size="small"
                    value={selectedService}
                    getOptionLabel={(option) => option?.service_name}
                    onChange={(event, newService) => {
                      if (newService) {
                        const serviceId = newService?.id;
                        setFieldValue('service_id', newService?.id);
                        setSelectedService(newService);
                        getClientsByServiceId(serviceId);
                        setSelectedClient(null);
                      } else {
                        setFieldValue('service_id', null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Service"
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          border: '1px solid #E4E4E5',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={clients}
                    disabled={!values?.service_id}
                    getOptionLabel={(option) =>
                      option?.client_number + ' - ' + option?.first_name + ' ' + option?.last_name
                    }
                    onChange={(event, newClient) => {
                      if (newClient) {
                        setFieldValue('client_number', newClient?.client_number);
                        setSelectedClient(newClient);
                        getRolesOfClient(newClient?.client_number);
                      } else {
                        // setClientRoles([]);
                        setSelectedClient(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Client"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          border: '1px solid #E4E4E5',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  {selectedClient && (
                    <>
                      <div>
                        <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Subscription Info</p>
                        {/* beautify table */}
                        <div
                          style={{
                            maxHeight: '200px', // Approximate height for 3 rows
                            overflowY: 'auto',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            marginTop: '3px',
                            backgroundColor: '#E1F7FC',
                            border: '2px dashed #00C1FE',
                          }}
                        >
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                              fontFamily: 'Arial, sans-serif',
                            }}
                          >
                            <tbody>
                              <tr
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  textAlign: 'left',
                                  padding: '12px',
                                  fontSize: '12px',
                                }}
                              >
                                <th style={cellStyle}>Start Date</th>
                                <th style={cellStyle}>Expiry Date</th>
                                <th style={cellStyle}>Total Credits</th>
                                <th style={cellStyle}>Used Credits</th>
                                <th style={cellStyle}>Remaining Credits</th>
                                <th style={cellStyle}>Subscription</th>
                              </tr>
                              <tr
                                style={{
                                  textAlign: 'left',
                                  padding: '10px',
                                  fontSize: '12px',
                                }}
                              >
                                <td style={cellStyle}>
                                  {selectedClient?.client_history[0]?.start_date
                                    ? formatDate(selectedClient?.client_history[0]?.start_date)
                                    : 'N/A'}
                                </td>
                                <td style={cellStyle}>
                                  {selectedClient?.client_history[0]?.end_date
                                    ? formatDate(selectedClient?.client_history[0]?.end_date)
                                    : 'N/A'}
                                </td>
                                <td style={cellStyle}>
                                  {selectedClient?.client_history[0]?.no_of_credits
                                    ? selectedClient?.client_history[0]?.no_of_credits
                                    : 0}
                                </td>
                                <td style={cellStyle}>
                                  {selectedClient?.client_history[0]?.credits_used
                                    ? selectedClient?.client_history[0]?.credits_used
                                    : 0}
                                </td>
                                <td style={cellStyle}>
                                  {selectedClient?.client_history[0]?.no_of_credits -
                                    selectedClient?.client_history[0]?.credits_used}
                                </td>
                                <td
                                  style={{
                                    ...cellStyle,
                                  }}
                                >
                                  {selectedClient?.client_history[0]?.subscription_type
                                    ? selectedClient?.client_history[0]?.subscription_type
                                    : 'N/A'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {clientRoles?.length > 0 && ( */}
                  {selectedClient && (
                    <>
                      <div
                        style={{
                          maxHeight: '200px', // Approximate height for 3 rows
                          overflowY: 'auto',
                          borderRadius: '8px',
                          marginTop: '10px',
                          backgroundColor: '#E1F7FC',
                          border: '2px dashed #00C1FE',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '13px',
                            textAlign: 'center',
                            paddingTop: '2px',
                            fontWeight: 'bold',
                          }}
                        >
                          Added Roles for this Client
                        </p>
                        <table
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            fontFamily: 'Arial, sans-serif',
                          }}
                        >
                          <thead>
                            <tr
                              style={{
                                position: 'sticky',
                                top: 0,
                                textAlign: 'left',
                                padding: '12px',
                                marginTop: '3px',
                                fontSize: '12px',
                                backgroundColor: '#D0EBFD',
                              }}
                            >
                              <th>#</th>
                              <th style={{ padding: '7px' }}>Role Title</th>
                              <th>Role Status</th>
                              <th>Role Date</th>
                              <th>Role Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {clientRoles?.map((role, index) => (
                              <tr
                                key={index}
                                style={{
                                  fontSize: '12px',
                                  transition: 'background-color 0.3s ease',
                                }}
                              >
                                <td style={cellStyle}>{index + 1}</td>
                                <td style={cellStyle}>
                                  <span style={{ fontWeight: 'bold' }}>{role?.roles_working_role?.title}</span>
                                </td>
                                <td style={cellStyle}>
                                  <span style={{ fontWeight: 'bold' }}>{role?.status}</span>
                                </td>
                                <td style={cellStyle}>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {role.role_date ? role?.role_date.split('T')[0] : 'No date'}
                                  </span>
                                </td>
                                <td style={cellStyle}>
                                  <span style={{ fontWeight: 'bold' }}>{role?.role_type}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <Autocomplete
                    id="role_type"
                    size="small"
                    fullWidth
                    options={roleCategory}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setFieldValue('role_type', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Select Role Category"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.role_type && Boolean(errors.role_type)}
                        helperText={touched.role_type && errors.role_type}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginTop: '10px',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <TextField
                    label="Role Date"
                    value={values.role_date}
                    defaultValue={new Date()}
                    fullWidth
                    id="role_date"
                    name="role_date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue('role_date', e.target.value);
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  {values.role_type === 'Fix' && (
                    <TextField
                      label="End Date"
                      value={values.end_date}
                      fullWidth
                      id="end_date"
                      name="end_date"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue('end_date', e.target.value);
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginBottom: '15px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}

                  <Autocomplete
                    id="creditoption"
                    fullWidth
                    size="small"
                    options={chargeCreditOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      // setChargeCredit(newValue)
                      setFieldValue('chargeCredit', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="creditoption"
                        {...params}
                        placeholder="Charge Credit"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </>

                {values.chargeCredit === 'Yes' && (
                  <TextField
                    required
                    id="role_number"
                    name="role_number"
                    value={values?.role_number}
                    type="number"
                    {...getFieldProps('role_number')}
                    size="small"
                    placeholder={`Role Number ${maxRoleNumber || 1}`}
                    error={touched.role_number && Boolean(errors.role_number)}
                    helperText={touched.role_number && errors.role_number}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
                {values.chargeCredit === 'No' && (
                  <TextField
                    required
                    id="no_credit_charge_reason"
                    name="no_credit_charge_reason"
                    value={values?.no_credit_charge_reason}
                    type="text"
                    {...getFieldProps('no_credit_charge_reason')}
                    placeholder="No credit charge reason"
                    size="small"
                    error={touched.no_credit_charge_reason && Boolean(errors.no_credit_charge_reason)}
                    helperText={touched.no_credit_charge_reason && errors.no_credit_charge_reason}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}

                <Box display="flex" flexDirection="column" p={3} m={2}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    value={values.title}
                    type="string"
                    {...getFieldProps('title')}
                    placeholder="Main Title"
                    size="small"
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  <TextField
                    required
                    id="location"
                    name="location"
                    value={values.location}
                    type="string"
                    {...getFieldProps('location')}
                    placeholder="Location"
                    size="small"
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '15px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />

                  <Autocomplete
                    id="acm_name"
                    size="small"
                    fullWidth
                    value={values.acm_name}
                    defaultValue={values.acm_name}
                    options={acmOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setFieldValue('acm_name', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Who recieve the role"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.acm_name && Boolean(errors.acm_name)}
                        helperText={touched.acm_name && errors.acm_name}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '15px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <WordEditor
                    onContentChange={(content) => {
                      console.log('content', content);
                      setDocFileTemplate(content);
                    }}
                  />

                  {/* {!editRoleDetails && ( */}
                  {/* <>
                    <Input
                      type="file"
                      style={{ display: 'none' }}
                      id="fileInput"
                      accept=".docx"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="fileInput">
                      <Button
                        component="span"
                        sx={{
                          fontSize: '18px',
                          borderRadius: '10px',
                          borderWidth: '3px',
                          marginBottom: '15px',
                          color: 'white',
                          backgroundColor: '#0ec4fe',
                          boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0)',
                          marginLeft: '10px',
                          borderColor: '#0ec4fe !important',
                          marginRight: '30px',
                          '&:hover': {
                            color: 'white !important',
                            borderColor: '#0ec4fe !important',
                            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0) !important',
                            backgroundColor: '#0ec4fe !important',
                          },
                        }}
                      >
                        Select Docs
                      </Button>
                    </label>
                  </> */}
                  {/* )} */}
                  {/* <QuillEditor htmlValue={docFileTemplate} onTextChange={handleTextChange} /> */}
                  {/* <HTMLEditor
                    editorLabel="Template"
                    htmlText={docFileTemplate}
                    onEditorStateChange={(htmlText) => {
                      setDocFileTemplate(htmlText?.data);
                    }}
                  /> */}

                  {/* {!docFileTemplate && <Alert severity="warning">Select file</Alert>} */}
                  <Box display="flex" justifyContent="flex-end" m={2}>
                    <Button
                      sx={{
                        backgroundColor: '#A3A3A3',
                        fontSize: '16px',
                        color: 'white',
                        marginLeft: '1rem',
                        '&:hover': {
                          color: 'white !important',
                          backgroundColor: '#A3A3A3 !important',
                        },
                      }}
                      style={{ marginRight: '10px' }}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>

                    <>
                      <Button
                        sx={{
                          fontSize: '18px',
                          borderRadius: '10px',
                          borderWidth: '3px',
                          color: 'white',
                          backgroundColor: '#0ec4fe',
                          boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0)',
                          marginLeft: '10px',
                          borderColor: '#0ec4fe !important',
                          marginRight: '30px',
                          '&:hover': {
                            color: 'white !important',
                            borderColor: '#0ec4fe !important',
                            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.0) !important',
                            backgroundColor: '#0ec4fe !important',
                          },
                        }}
                        // disabled={!docFileTemplate}
                        startIcon={<Icon icon="line-md:cloud-upload-outline-loop" />}
                        onClick={() => {
                          if (Object.keys(errors)?.length > 0) {
                            enqueueSnackbar('Some fields are missing');
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        Upload
                      </Button>
                    </>
                    {/* )} */}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddNewRole;
