import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { CandidateFeedbackDialog } from '.';
import { useNavigate } from 'react-router-dom';

function CandidateWorkingTable(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const [openCandidateFeedbackDialog, setOpenCandidateFeedbackDialog] = useState(false);
  const statusOptions = ['Interested', 'Not Interested', 'Irrelevant', 'WFR', 'NCR'];
  const statusColors = {
    Interested: '#16BF12',
    NotInterested: '#FF2D2D',
    WFR: '#3F8CFF',
    Irrelevant: '#9747FF',
    skipped: '#7D8592', // Grey for skipped steps
  };

  const statusBgColors = {
    Interested: '#D7FFD6',
    NotInterested: '#FFD2D2',
    WFR: '#C7DEFF',
    Irrelevant: '#E4CFFF',
    NCR: '#FFEBB1',
  };

  const statusTextColors = {
    Interested: '#1FAF38',
    NotInterested: '#FF2D2D',
    WFR: '#3F8CFF',
    Irrelevant: '#9747FF',
    NCR: '#FFC727',
  };
  const data = [
    {
      name: 'Ali Raza',
      email: 'ali@gmail.com',
      number: '123456789',
      activities: [
        { type: 'email', sent: true, response: '', isRepeated: false },
        { type: 'whatsapp', sent: true, response: 'interested', isRepeated: true },
        { type: 'mobileText', sent: true, response: '', isRepeated: false },
        { type: 'call', sent: false, response: '', isRepeated: false },
        { type: 'linkedinConnection', sent: false, response: '', isRepeated: false },
        { type: 'linkedinInmail', sent: false, response: '', isRepeated: false },
      ],
      resourcer: 'Vishal',
      recruiter: 'John',
      status: 'Interested',
      feedback: 'Good',
    },
    {
      name: 'John',
      email: 'ali@gmail.com',
      number: '123456789',
      activities: [
        { type: 'email', sent: true, response: '', isRepeated: false },
        { type: 'whatsapp', sent: true, response: '', isRepeated: false },
        { type: 'mobileText', sent: true, response: 'Interested', isRepeated: false },
        { type: 'call', sent: false, response: '', isRepeated: false },
        { type: 'linkedinConnection', sent: false, response: '', isRepeated: false },
        { type: 'linkedinInmail', sent: false, response: '', isRepeated: false },
      ],
      resourcer: 'Vishal',
      recruiter: 'John',
      status: 'Irrelevant',
      feedback: 'Good',
    },
    {
      name: 'Janiee',
      email: 'ali@gmail.com',
      number: '123456789',
      activities: [
        { type: 'email', sent: true, response: '', isRepeated: false },
        { type: 'whatsapp', sent: true, response: '', isRepeated: false },
        { type: 'mobileText', sent: true, response: 'Interested', isRepeated: false },
        { type: 'call', sent: false, response: '', isRepeated: false },
        { type: 'linkedinConnection', sent: false, response: '', isRepeated: false },
        { type: 'linkedinInmail', sent: false, response: '', isRepeated: false },
      ],
      resourcer: 'Vishal',
      recruiter: 'John',
      status: 'WFR',
      feedback: 'Good',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };
  return (
    <div style={{ marginTop: '1rem' }}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'left' }}></TableCell>
                <TableCell style={{ textAlign: 'left' }}>Candidate Name</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Number</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Activities</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Recruiter</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Box>
                    <Autocomplete
                      id="status"
                      options={statusOptions}
                      size="small"
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {}}
                      disableClearable // Optional: Prevents the clearable option
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Status"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              border: 'none', // Remove border
                              outline: 'none', // Remove outline
                              '& fieldset': {
                                display: 'none', // Hide the fieldset (the default border)
                              },
                            },
                            '& .MuiInputBase-input': {
                              padding: '6px 12px', // Adjust padding as needed
                              color: 'black',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, // Disable the underline
                          }}
                        />
                      )}
                      // Optionally customize the popup of the dropdown to be hidden or styled
                      // PopperComponent={(props) => <div {...props} style={{ display: 'none' }} />} // Hides the dropdown
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Feedback</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Paginate the rows */}
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                <TableRow key={index} selected={selected.includes(row.candidateId)}>
                  <TableCell>
                    <Box display="flex" alignItems="center" justifyContent="space-around">
                      {/* Checkbox for selection */}
                      <Checkbox
                        checked={selected?.includes(row.name)}
                        onChange={() => handleSelect(row.name)}
                        inputProps={{ 'aria-label': 'select row' }}
                        sx={{
                          color: '#808080',
                          '&.Mui-checked': {
                            color: '#00C1FE', // Set checkbox color to blue when checked
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {/* Avatar with the first character */}
                      <Avatar
                        style={{
                          marginRight: '8px', // Adds space between avatar and name
                          backgroundColor: '#4CD4FF',
                          color: '#fff',
                          width: '30px',
                          height: '30px',
                          fontSize: '16px',
                          textAlign: 'center',
                        }}
                      >
                        {row.name.charAt(0)}
                      </Avatar>

                      {/* Candidate name */}
                      <p
                        style={{
                          color: '#4CD4FF',
                          fontWeight: 'bold',
                          margin: 0,
                        }}
                        onClick={() => navigate(`/recruiter/candidateWorkingDetails/1`)}
                      >
                        {row.name}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <p>{row.email}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.number}</p>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <CommunicationChannelPipeline pipeline={row} />
                  </TableCell>
                  <TableCell>
                    <p>{row.resourcer}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.recruiter}</p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        backgroundColor: statusBgColors[row.status],
                        color: statusTextColors[row.status],
                        padding: '5px 10px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.status}
                    </p>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Icon
                        icon="iconoir:message-text-solid"
                        style={{ fontSize: '24px', color: '#5C61F2', margin: '2px' }}
                      />
                      <Icon icon="logos:whatsapp-icon" style={{ fontSize: '24px', margin: '2px' }} />
                      <Icon
                        icon="fluent:call-add-20-filled"
                        style={{ fontSize: '24px', color: '#00C1FE', margin: '2px' }}
                      />
                      <Icon icon="ri:linkedin-fill" style={{ fontSize: '24px', color: '#3F8CFF', margin: '2px' }} />
                      <Icon icon="mdi:linkedin" style={{ fontSize: '24px', color: '#E5B137', margin: '2px' }} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <p onClick={() => setOpenCandidateFeedbackDialog(true)} style={{ cursor: 'pointer' }}>
                      {row.feedback}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
      {openCandidateFeedbackDialog && (
        <CandidateFeedbackDialog
          open={openCandidateFeedbackDialog}
          onClose={() => setOpenCandidateFeedbackDialog(false)}
        />
      )}
    </div>
  );
}

export default CandidateWorkingTable;

const CommunicationChannelPipeline = ({ pipeline }) => {
  const statusColors = {
    interested: '#16BF12',
    notInterested: '#FF2D2D',
    wfr: '#3F8CFF',
    irrelevant: '#9747FF',
    skipped: '#7D8592', // Grey for skipped steps
  };

  // Ensure activities is always an array
  const activities = Array.isArray(pipeline.activities) ? pipeline.activities : Object.values(pipeline.activities);

  const renderIcon = (type) => {
    console.log('key', type);
    switch (type) {
      case 'email':
        return <Icon icon="mdi:envelope" style={{ color: 'white', fontSize: '16px' }} />;
      case 'whatsapp':
        return <Icon icon="mingcute:whatsapp-fill" style={{ color: 'white', fontSize: '18px' }} />;
      case 'mobileText':
        return <Icon icon="material-symbols:sms" style={{ color: 'white', fontSize: '16px' }} />;
      case 'call':
        return <Icon icon="ic:round-phone" style={{ color: 'white', fontSize: '16px' }} />;
      case 'linkedinConnection':
        return <Icon icon="ri:linkedin-fill" style={{ color: 'white', fontSize: '18px' }} />;
      case 'linkedinInmail':
        return <Icon icon="carbon:logo-linkedin" style={{ color: 'white', fontSize: '18px' }} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {activities?.map((activity, index) => {
        const isSkipped = !activity.sent && activity.response === '';
        const bgColor = isSkipped
          ? statusColors.skipped
          : statusColors[activity.response.toLowerCase()] || statusColors.wfr; // Default to 'wfr' if status is not in the color map

        return (
          <Stack direction="row" justifyContent="center" alignItems="center" key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {activity.isRepeated && ( // Conditional rendering of Badge
                <Badge badgeContent={4} color="error">
                  <div
                    style={{
                      backgroundColor: bgColor,
                      color: '#fff',
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                    }}
                  >
                    {renderIcon(activity.type)}
                  </div>
                </Badge>
              )}
              {!activity.isRepeated && ( // Render the icon without Badge if isRepeated is false
                <div
                  style={{
                    backgroundColor: bgColor,
                    color: '#fff',
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '18px',
                  }}
                >
                  {renderIcon(activity.type)}
                </div>
              )}
            </div>
            {index !== activities?.length - 1 && (
              <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', marginLeft: '-2px' }} />
            )}
          </Stack>
        );
      })}
    </div>
  );
};
