import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { CVFormat } from '.';
import { useNavigate, useParams } from 'react-router-dom';
import { Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import { Autorenew, Delete, DownloadOutlined, Refresh } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

function LITable({ roleLIs, onPageChange, onRowsPerPageChange, totalRows, onRefreshRequest }) {
  const userRole = localStorage.getItem('role');
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openViewLIDialog, setOpenViewLIDialog] = useState(false);
  const role = localStorage.getItem('role');
  const [selectedLI, setSelectedLI] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadLiAsPdf, setDownloadLiAsPdf] = useState(false);
  const [liList, setLiList] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const acceptLI = (id) => {
    try {
      Post(
        {
          id,
        },
        API_URLS.acceptLI,
        (resp) => {
          enqueueSnackbar('LI accepted successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error accepting LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error accepting LI', { variant: 'error' });
    }
  };

  const rejectLI = (id) => {
    try {
      Post(
        {
          id,
        },
        API_URLS.rejectLI,
        (resp) => {
          enqueueSnackbar('LI rejected successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error rejecting LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error rejecting LI', { variant: 'error' });
    }
  };

  useEffect(() => {
    roleLIs?.forEach((element) => {
      setLiList((prev) => [...prev, element.li]);
    });
  }, [roleLIs]);

  const changeLIProfileStatus = (id) => {
    console.log("ksdgfsdnfsdf",id);
    try {
      Post(
        {
          id,
          status: 'pending',
        },
        API_URLS.changeLIProfileStatus,
        (resp) => {
          enqueueSnackbar('LI reverted to pending successfully', { variant: 'success' });
          onRefreshRequest();
        },
        (error) => {
          enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
    }
  }

  const deleteLIProfile = (id) => {
    console.log("ksdgfsdnfsdf",id);
    try {
      Post(
        {
          id,
        },
        API_URLS.deleteLIProfile,
        (resp) => {
          enqueueSnackbar('LI removed successfully', { variant: 'success' });
          onRefreshRequest();
        },
        (error) => {
          enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
    }
  }

  return (
    <div>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" gap={1} alignItems="center" padding="10px">
          {userRole === 'resourcer' && (
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '80px',
                height: '40px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: 'white',
                },
                borderRadius: '5px',
                padding: '5px',
              }}
              onClick={() => {
                navigate(`/resourcing/addLI/${roleId}`);
              }}
            >
              Add LIs
            </Button>
          )}
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              width: '80px',
              height: '40px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: 'white',
              },
              borderRadius: '5px',
              padding: '5px',
            }}
            onClick={() => {
              const selectedColumns = [
                'profile_type',
                'full_name',
                'profile_link',
                'personal_email',
                'business_email',
                'business_number',
                'personal_contact',
              ];

              const filteredLiList = liList.map((li) => {
                let filteredItem = {};
                selectedColumns.forEach((column) => {
                  filteredItem[column] = li[column];
                });
                return filteredItem;
              });

              const ws = XLSX.utils.json_to_sheet(filteredLiList);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Candidates');
              const fileName = 'Candidates.xlsx';
              XLSX.writeFile(wb, fileName);
            }}
          >
            Excel <DownloadOutlined />
          </Button>
          &nbsp; &nbsp;
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              width: '40px',
              height: '40px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: 'white',
              },
              borderRadius: '5px',
              padding: '5px',
            }}
            onClick={() => {
              onRefreshRequest();
            }}
          >
            <Refresh />
          </Button>
        </Box>
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC' }}>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Link
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  LIs
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {' '}
                  Status
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleLIs?.length > 0 ? (
                <>
                  {roleLIs?.map((li) => (
                    <TableRow>
                      <TableCell>{li?.li ? li?.li?.full_name : ''}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            backgroundColor: li?.li?.profile_status === 'completed' ? 'green' : '#FFEFCA',
                            color: li?.li?.profile_status === 'completed' ? 'white' : 'black',
                            padding: '5px',
                            width: '105px',
                            margin: '5px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'flex', // Flexbox layout
                            alignItems: 'center', // Align items horizontally in the center
                            justifyContent: 'center', // Center content horizontally
                            borderRadius: '15px',
                          }}
                        >
                          {/* <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '} */}
                          {li?.li?.profile_status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-around" alignItems="center">
                          {li?.li?.profile_status === 'In-Progress' && (
                            <>
                              <CircularProgress value={100} variant="indeterminate" color="primary" size={20} />
                            </>
                          )}
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '50px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => changeLIProfileStatus(li?.li?.id)}
                          >
                            <Autorenew />
                          </Button>
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '50px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => deleteLIProfile(li?.li?.id)}
                          >
                            <Delete />
                          </Button>
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '50px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => {
                              // check the LI row
                              setSelectedLI(li);
                              setOpenViewLIDialog(true);
                            }}
                          >
                            View LI
                          </Button>
                          <Button
                            sx={{
                              border: '1px solid #00C1FE',
                              padding: '5px 10px',
                              color: '#00C1FE',
                              width: '50px',
                              height: '34px',
                              marginRight: '20px',
                              marginLeft: '5px',
                            }}
                            onClick={() => {
                              // check the LI row
                              setSelectedLI(li);
                              setDownloadLiAsPdf(true);
                              setOpenViewLIDialog(true);
                            }}
                          >
                            <DownloadOutlined />
                          </Button>

                          {role === 'QAResourcing' && (
                            <>
                              <Button
                                sx={{
                                  backgroundColor: '#E1F7FC',
                                  height: '34px',
                                  width: '15px',
                                  '&:hover': {
                                    backgroundColor: '#E1F7FC',
                                  },
                                  borderRadius: '5px',
                                }}
                                onClick={() => {
                                  acceptLI(li?.li?.id);
                                }}
                              >
                                {' '}
                                <Tooltip title="Reject LI" arrow placement="top">
                                  <Icon
                                    icon="bitcoin-icons:cross-filled"
                                    style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                  />
                                </Tooltip>
                              </Button>
                              <Button
                                sx={{
                                  backgroundColor: '#E1F7FC',
                                  height: '34px',
                                  width: '15px',
                                  '&:hover': {
                                    backgroundColor: '#E1F7FC',
                                  },
                                  borderRadius: '5px',
                                }}
                                onClick={() => {
                                  rejectLI(li?.li?.id);
                                }}
                              >
                                {' '}
                                <Tooltip title="Accept LI" arrow placement="top">
                                  <Icon
                                    icon="mdi:tick"
                                    style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                  />
                                </Tooltip>
                              </Button>
                              <Button
                                sx={{
                                  backgroundColor: '#E1F7FC',
                                  height: '34px',
                                  width: '15px',
                                  '&:hover': {
                                    backgroundColor: '#E1F7FC',
                                  },
                                  borderRadius: '5px',
                                }}
                              >
                                {' '}
                                <Tooltip title="Edit LI" arrow placement="top">
                                  <Icon
                                    icon="material-symbols-light:edit-outline"
                                    style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                  />
                                </Tooltip>
                              </Button>
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No LIs found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalRows} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {openViewLIDialog && (
        <CVFormat
          open={openViewLIDialog}
          onClose={() => setOpenViewLIDialog(false)}
          openToWork={true}
          candidate={selectedLI?.li}
          downloadLiAsPdf={downloadLiAsPdf}
        />
      )}

      {/* <CVFormatDownload candidate={selectedLI?.li} OpenToWork={true} /> */}
    </div>
  );
}

export default LITable;

const CVFormatDownload = ({ candidate, OpenToWork }) => {
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: 'auto',
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <PDFExport
      ref={pdfExportComponent}
      paperSize="auto"
      margin={40}
      fileName={`Report for ${new Date().getFullYear()}`}
      author="KendoReact Team"
    >
      {candidate?.profile_type === 'openToWork' && (
        <a
          href="#"
          style={{
            position: 'sticky', // Change position to sticky
            top: 0, // Stick to the top
            zIndex: 1, // Ensure it's above other content
            backgroundColor: '#57CC99',
            color: 'white',
            width: '130px',
            padding: '5px 10px',
            textDecoration: 'none',
            fontSize: '14px',
            left: '0px', // Adjust the left property
          }}
        >
          Open to work
        </a>
      )}

      <div style={{ padding: '3rem', width: '95%' }}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '80%',
            }}
          >
            {candidate?.profile_type === 'openToWork' && (
              <img
                src={OpenToWork || 'https://via.placeholder.com/150'}
                alt="profile"
                style={{ width: '150px', height: '150px', borderRadius: '50%' }}
              />
            )}
            <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{candidate?.full_name || 'Dora Opare'}</p>
            <img
              src={candidate?.profile_image || 'https://via.placeholder.com/150'}
              alt="profile"
              style={{ width: '150px', height: '150px', borderRadius: '50%' }}
            />
          </Box>

          <Box display="flex" marginTop="10px">
            <p style={{ fontSize: '16px' }}>
              {candidate?.location || 'GC479, Lane 21 Oniyinase RD, Aoshie - Accra'} {/* Default location */}
              {/* Conditional rendering for personal email */}
              {candidate?.personal_email && (
                <>
                  {' | '}
                  <span style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}>
                    {candidate.personal_email}
                  </span>
                </>
              )}
              {/* Conditional rendering for business email */}
              {candidate?.business_email && (
                <>
                  {' | '}
                  <span style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}>
                    {candidate.business_email}
                  </span>
                </>
              )}
              {/* Conditional rendering for personal contact */}
              {candidate?.personal_contact && (
                <>
                  {' | '}
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>{candidate.personal_contact}</span>
                </>
              )}
              {/* Conditional rendering for business number */}
              {candidate?.business_number && (
                <>
                  {' | '}
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>{candidate.business_number}</span>
                </>
              )}
              {/* Conditional rendering for LinkedIn profile */}
              {candidate?.profile_link && (
                <>
                  {' | '}
                  <a
                    href={candidate.profile_link}
                    target="_blank"
                    style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}
                  >
                    {candidate.profile_link}
                  </a>
                </>
              )}
            </p>
          </Box>
        </Box>

        {/* About section */}
        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>About</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        <Box display="flex" marginLeft="20px" marginTop="10px">
          <p style={{ fontSize: '16px' }}>
            {candidate?.about?.replace('Possible summary:', '') || 'I am a software engineer with 5 years experience'}
          </p>
        </Box>

        <Box display="flex" marginLeft="20px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Education</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        {candidate?.education?.map((edu, index) => (
          <Box key={index} display="flex" marginLeft="20px" justifyContent="center" flexDirection="column">
            <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>{edu.institution}</p>
            <p style={{ fontSize: '14px', marginTop: '5px' }}>{edu.degree}</p>
            <p style={{ fontSize: '14px', marginTop: '5px' }}>{edu.duration}</p>
          </Box>
        ))}

        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Skills</p>
        </Box>

        <Box display="flex" marginLeft="20px" flexDirection="column">
          <hr
            style={{
              color: '#B0B0B0',
              height: '3px',
              border: 'none',
              backgroundColor: '#CAC5C5',
              marginLeft: '0px',
            }}
          />
          <Box
            component="div"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)', // 2 skills per row by default
              gap: '10px', // Space between items
              // border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px',

              // Adjust to 3 skills per row for larger screens
              '@media (min-width: 600px)': {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },

              // Print-friendly layout to ensure all skills fit neatly
              '@media print': {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            {candidate?.skills?.map((skill, index) => (
              <Box
                key={index}
                sx={{
                  fontSize: '16px',
                  width: '90%',
                  padding: '5px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  textAlign: 'center',
                }}
              >
                {skill}
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Experience</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        {candidate?.experience?.map((exp, expIndex) =>
          exp.positions.map((position, posIndex) => (
            // <Box key={`${expIndex}-${posIndex}`} display="flex" flexDirection="column" marginLeft="20px">
            <Box display="flex" justifyContent="space-between" flexDirection="column">
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>{position.title}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{exp.company}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>
                {position?.startDate + '- ' + position?.endDate} ,
                {position.duration !== 'N/A' ? position.duration : 'Present'}
              </p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{position.location}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{position.responsibilities}</p>
            </Box>
            // </Box>
          ))
        )}
      </div>
      {/* Put Powered bu Ultimate outsourcing LTD on the bottom right corner */}
      <Box
        sx={{
          position: 'relative', // Keep it fixed to the bottom-right of the viewport
          bottom: '10px',
          left: '900px',
          color: '#B0B0B0',
          fontSize: '12px',
          zIndex: 1000, // Ensure it stays on top of other elements
        }}
      >
        <Typography>Powered by Ultimate Outsourcing LTD</Typography>
        </Box>
    </PDFExport>
  );
};
