// natasha laptop url
// const api_url = 'https://c63d-182-176-99-238.ngrok-free.app/';
// server url
// const api_url = 'https://btds28q8-5001.inc1.devtunnels.ms/';

const api_url = "https://btds28q8-5000.inc1.devtunnels.ms/";  
// const api_url = "http://localhost:5002/";
// const api_url = "https://b4c4-182-176-99-238.ngrok-free.app/";

// production API URL
// const api_url = "https://api2.ultimateoutsourcing.co.uk/";

export const API_URLS = {
  //users routes
  uploadImage: `${api_url}api/users/uploadImage`,
  createUser: `${api_url}api/users/createUser`,
  login: `${api_url}api/users/login`,
  updateUser: `${api_url}api/users/updateUser`,
  getRoleBasedUsers: `${api_url}api/users/getRoleBasedUsers`,
  getAllUsers: `${api_url}api/users/getAllUsers`,
  deactivateAccount: `${api_url}api/users/deactivateAccount`,
  approveAccount: `${api_url}api/users/approveAccount`,
  logout: `${api_url}api/users/logout`,
  getUserById: `${api_url}api/users/getUserById`,

  // features routes
  findAllFeatures: `${api_url}api/features/findAllFeatures`,
  createFeature: `${api_url}api/features/createFeature`,
  updateUserAndGiveFeatureAcess: `${api_url}api/features/updateUserAndGiveFeatureAcess`,
  //company routes
  addCompany: `${api_url}api/companies/addCompany`,
  getAllCompanies: `${api_url}api/companies/getAllCompanies`,
  searchGlobally: `${api_url}api/companies/searchGlobally`,
  update: `${api_url}api/companies/update`,
  searchCompanies: `${api_url}api/companies/searchCompanies`,
  searchCompanisByFilters: `${api_url}api/companies/searchCompanisByFilters`,
  getCompanies: `${api_url}api/companies/getCompanies`,
  getUniqueIndustriesFromCompanies: `${api_url}api/companies/getUniqueIndustriesFromCompanies`,

  //person routes
  addPerson: `${api_url}api/persons/addPerson`,
  getAllPersons: `${api_url}api/persons/getAllPersons`,
  getPersonByCompanyId: `${api_url}api/persons/getPersonByCompanyId`,
  updatePerson: `${api_url}api/persons/updatePerson`,
  getCompanyOfPerson: `${api_url}api/persons/getCompanyOfPerson`,
  assignPersonsToUser: `${api_url}api/persons/assignPersonsToUser`,
  getAssignedPersonsByUserId: `${api_url}api/persons/getAssignedPersonsByUserId`,
  updatePersonInfo: `${api_url}api/persons/updatePersonInfo`,
  getUniqueIndustriesFromPersons: `${api_url}api/persons/getUniqueIndustriesFromPersons`,
  getUniquePersonTitles: `${api_url}api/persons/getUniquePersonTitles`,
  searchPersons: `${api_url}api/persons/searchPersons`,
  searchPersonsOfCompany: `${api_url}api/persons/searchPersonsOfCompany`,
  downloadPersons: `${api_url}api/persons/downloadPersons`,

  //jobpost routes
  addJobPostWithCompanyId: `${api_url}api/jobposts/addJobPostWithCompanyId`,
  addJobPostWithPersonId: `${api_url}api/jobposts/addJobPostWithPersonId`,
  getJobPostsByPersonId: `${api_url}api/jobposts/getJobPostsByPersonId`,
  getPersonOfJobPost: `${api_url}api/jobposts/getPersonOfJobPost`,
  checkDuplicateByPostingLink: `${api_url}api/jobposts/checkDuplicateByPostingLink`,
  // getUniqueIndustries: `${api_url}api/jobposts/getUniqueIndustries`,
  getCompanyOfJobpost: `${api_url}api/jobposts/getCompanyOfJobpost`,
  getJobPostsByCompanyId: `${api_url}api/jobposts/getJobPostsByCompanyId`,
  getAllJobs: `${api_url}api/jobposts/getAllJobs`,
  searchJobPosts: `${api_url}api/jobposts/searchJobPosts`,
  searchJobPostsOfCompany: `${api_url}api/jobposts/searchJobPostsOfCompany`,

  // countries routes
  getAllCountries: `${api_url}api/countries/getAllCountries`,
  getCountriesAndSector: `${api_url}api/countries/getCountriesAndSector`,

  //sectors routes
  getAllSectors: `${api_url}api/sectors/getAllSectors`,

  // scrapper routes
  addCompanyByScrapper: `${api_url}api/scrapper/addCompanyByScrapper`,
  addPersonByScrapper: `${api_url}api/scrapper/addPersonByScrapper`,
  addJobPostByScrapper: `${api_url}api/scrapper/addJobPostByScrapper`,
  getCompanyAndPersonId: `${api_url}api/scrapper/getCompanyAndPersonId`,

  // schedules routes
  createSchedule: `${api_url}api/schedules/createSchedule`,
  updateSchedule: `${api_url}api/schedules/updateSchedule`,
  deleteSchedule: `${api_url}api/schedules/deleteSchedule`,
  getSchedulesByCountry: `${api_url}api/schedules/getSchedulesByCountry`,
  // sequence routes
  createSequence: `${api_url}api/sequences/createSequence`,

  // sequence steps routes
  createSequenceStep: `${api_url}api/sequenceSteps/createSequenceStep`,
  updateSequenceStep: `${api_url}api/sequenceSteps/updateSequenceStep`,

  // templates routes
  createTemplate: `${api_url}api/templates/createTemplate`,
  updateTemplate: `${api_url}api/templates/updateTemplate`,
  getTemplates: `${api_url}api/templates/getTemplates`,
  updateTemplateDisclaimer: `${api_url}api/templates/updateTemplateDisclaimer`,
  sendTestEmail: `${api_url}api/templates/sendTestEmail`,

  // campaign  routes
  createCampaign: `${api_url}api/campaigns/createCampaign`,
  findAll: `${api_url}api/campaigns/findAll`,
  checkDuplicateByName: `${api_url}api/campaigns/checkDuplicateByName`,
  getCounterByCountryAndSector: `${api_url}api/campaigns/getCounterByCountryAndSector`,
  getJobPostsAndSendingStats: `${api_url}api/campaigns/getJobPostsAndSendingStats`,
  getEmailEventStats: `${api_url}api/campaigns/getEmailEventStats`,

  // emails routes
  addPersonEmails: `${api_url}api/emails/addPersonEmails`,
  unSubscribeFromOurServices: `${api_url}api/emails/unSubscribeFromOurServices`,
  searchEmail: `${api_url}api/emails/searchEmail`,
  addPersonalEmails: `${api_url}api/emails/addPersonalEmails`,
  addBusinessEmails: `${api_url}api/emails/addBusinessEmails`,

  // phone routes
  addPersonPhones: `${api_url}api/phones/addPersonPhones`,

  // CAMPAIGN REPORTING ROUTES
  getOverAllStats: `${api_url}api/campaign-reporting/getOverAllStats`,
  getRecentBouncedEmails: `${api_url}api/campaign-reporting/getRecentBouncedEmails`,
  getRecentOpenedEmails: `${api_url}api/campaign-reporting/getRecentOpenedEmails`,
  getRecentSpamEmails: `${api_url}api/campaign-reporting/getRecentSpamEmails`,
  getCampaignReportStats: `${api_url}api/campaign-reporting/getCampaignReportStats`,
  getRecentUnSubscribedEmails: `${api_url}api/campaign-reporting/getRecentUnSubscribedEmails`,
  getEmailBasedReports: `${api_url}api/campaign-reporting/getEmailBasedReports`,
  getCampaignRecipents: `${api_url}api/campaign-reporting/getCampaignRecipents`,
  getCampaignRecipentsStatsForLineGraph: `${api_url}api/campaign-reporting/getCampaignRecipentsStatsForLineGraph`,

  //new APis
  getCampaignSendingStats: `${api_url}api/campaigns/getCampaignSendingStats`,
  getAmazonSentStats: `${api_url}api/campaigns/getAmazonSentStats`,

  // supression list routes
  addSupressionList: `${api_url}api/campaigns/supressions/addSupressionList`,
  getSupressionList: `${api_url}api/campaigns/supressions/getSupressionList`,
  addSingleEmail: `${api_url}api/campaigns/supressions/addSingleEmail`,
  //blocked domains routes
  addDomainList: `${api_url}api/campaigns/domains/addDomainList`,
  getBlockedDomains: `${api_url}api/campaigns/domains/getBlockedDomains`,
  addSingleDomain: `${api_url}api/campaigns/domains/addSingleDomain`,

  //candidates routes
  createCandidate: `${api_url}api/candidates/createCandidate`,
  getAllCandidates: `${api_url}api/candidate/getAllCandidates`,
  getMatchingCandidates: `${api_url}api/candidate/getMatchingCandidates`,

  // email record routes
  markAsEmailNotFound: `${api_url}api/email_records/markAsEmailNotFound`,
  updateEmailStatus: `${api_url}api/email_records/updateEmailStatus`,
  updateThePersonEmailsStatusInBulk: `${api_url}api/email_records/updateThePersonEmailsStatusInBulk`,
  markAsReplacementNotFound: `${api_url}api/email_records/markAsReplacementNotFound`,
  addReplacementEmails: `${api_url}api/email_records/addReplacementEmails`,
  addEmailsFromSpreadSheet: `${api_url}api/email_records/addEmailsFromSpreadSheet`,

  // Stats
  getCountryAndSectorWiseLeadsStats: `${api_url}api/stats/getCountryAndSectorWiseLeadsStats`,
  getPersonWiseLeadsStats: `${api_url}api/stats/getPersonWiseLeadsStats`,
  getOverallAndUserBasedStats: `${api_url}api/stats/getOverallAndUserBasedStats`,
  userSystemReport: `${api_url}api/stats/userSystemReport`,
  getCountryAndSectorWiseLeadsContactStats: `${api_url}api/stats/getCountryAndSectorWiseLeadsContactStats`,
  getCountryAndSectorWisePersons: `${api_url}api/stats/getCountryAndSectorWisePersons`,
  getUserWorkReport: `${api_url}api/stats/getUserWorkReport`,
  getUserWorkReportV3: `${api_url}api/stats/getUserWorkReportV3`,
  getScrapperReporting: `${api_url}api/stats/getScrapperReporting`,
  getEmailCampaignReporting: `${api_url}api/stats/getEmailCampaignReporting`,
  getTeamAssignedPersonsStats: `${api_url}api/stats/getTeamAssignedPersonsStats`,

  // -----------------------------------------------------------------  resourcing routes -----------------------------------------------------------
  //services routes
  createService: `${api_url}api/services/createService`,
  getAllServices: `${api_url}api/services/getAllServices`,

  //client routes
  createClient: `${api_url}api/clients/createClient`,
  getAllClientsWithPagination: `${api_url}api/clients/getAllClientsWithPagination`,
  getClientsByServiceId: `${api_url}api/clients/getClientsByServiceId`,
  getClientSheetByClientNumber: `${api_url}api/clients/getClientSheetByClientNumber`,
  getResourcingTeamStats: `${api_url}api/clients/getResourcingTeamStats`,
  getRolesStats: `${api_url}api/clients/getRolesStats`,
  getClientRolesStats: `${api_url}api/clients/getClientRolesStats`,
  getClientsRolesReport: `${api_url}api/clients/getClientsRolesReport`,
  sendRoleFollowupEmailToClient: `${api_url}api/clients/sendRoleFollowupEmailToClient`,

  //resourcing stats
  getClientStatsByService: `${api_url}api/resourcingStats/getClientStatsByService`,

  //resourcing roles routes
  extractText: `${api_url}api/roles/extractText`,
  attachDocFileTemplate: `${api_url}api/roles/attachDocFileTemplate`,
  getAllRoles: `${api_url}api/roles/getAllRoles`,
  getAllRolesWithStats: `${api_url}api/roles/getAllRolesWithStats`,
  getTrialRolesWithStats: `${api_url}api/roles/getTrialRolesWithStats`,
  getFixRolesWithStats: `${api_url}api/roles/getFixRolesWithStats`,
  getRegularRolesWithStats: `${api_url}api/roles/getRegularRolesWithStats`,
  getRolesTypeBasedServiceRolesWithStats: `${api_url}api/roles/getRolesTypeBasedServiceRolesWithStats`,
  getRoleById: `${api_url}api/roles/getRoleById`,
  updateRoleStatus: `${api_url}api/roles/updateRoleStatus`,
  startRole: `${api_url}api/roles/startRole`,
  addRoleIssue: `${api_url}api/roles/addRoleIssue`,
  editRoleIssue: `${api_url}api/roles/editRoleIssue`,
  addRoleInfo: `${api_url}api/roles/addRoleInfo`,
  editRoleInfo: `${api_url}api/roles/editRoleInfo`,
  addRoleSuggestion: `${api_url}api/roles/addRoleSuggestion`,
  editRoleSuggestion: `${api_url}api/roles/editRoleSuggestion`,
  addRoleQuery: `${api_url}api/roles/addRoleQuery`,
  editRoleQuery: `${api_url}api/roles/editRoleQuery`,
  addCVToRole: `${api_url}api/roles/addCVToRole`,
  uploadFile: `${api_url}api/roles/uploadFile`,
  getRoleCVs: `${api_url}api/roles/getRoleCVs`,
  getRoleByRoleWorkingId: `${api_url}api/roles/getRoleByRoleWorkingId`,
  markRoleAsDone: `${api_url}api/roles/markRoleAsDone`,
  leaveRole: `${api_url}api/roles/leaveRole`,
  getRolesByClientNumber: `${api_url}api/roles/getRolesByClientNumber`,
  getRoleLIs: `${api_url}api/roles/getRoleLIs`,

  // LI routes
  sendLIToScrapper: `${api_url}api/lis/sendLIToScrapper`,
  addLIToRole: `${api_url}api/lis/addLIToRole`,
  rejectLI: `${api_url}api/lis/rejectLI`,
  acceptLI: `${api_url}api/lis/acceptLI`,
  changeLIProfileStatus: `${api_url}api/lis/changeLIProfileStatus`,
  deleteLIProfile: `${api_url}api/lis/deleteLIProfile`,

  // cv uploads routes
  uploadCV: `${api_url}api/cvUploads/uploadCV`,
  downloadCV: `${api_url}api/cvUploads/downloadCV`,

  // job boards routes
  addJobBoard: `${api_url}api/jobBoards/addJobBoard`,
  getAllJobBoards: `${api_url}api/jobBoards/getAllJobBoards`,

  // ACM Settings routes
  createLimitSettings: `${api_url}api/acmSettings/createLimitSettings`,
  getLimitSettings: `${api_url}api/acmSettings/getLimitSettings`,

  // Candidates
  getDailyCandidates: `${api_url}api/candidate/getDailyCandidates`,
  assignDailyCandidates: `${api_url}api/candidate/assignDailyCandidates`,

  // Reed Search
  createSearch: `${api_url}api/reed_search/createSearch`,
  getAllSearchesWithPagination: `${api_url}api/reed_search/getAllSearchesWithPagination`,
  // activateSearch: `${api_url}api/reed_search/activateSearch`,
  changeSearchStatus: `${api_url}api/reed_search/changeSearchStatus`,

  // Candidate Search using boolean string
  searchCandidateBySearchString: `${api_url}api/candidate_search/searchCandidateBySearchString`,

  // ------------------------------------------------------------------ 360 recruiter side routes -----------------------------------------------------------

  getPreQualificationRoles: `${api_url}api/recruiter/getPreQualificationRoles`,
  getRoleCandidatesById: `${api_url}api/recruiter/getRoleCandidatesById`,
};
