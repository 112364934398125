import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's Snow theme CSS
import { Alert, Typography } from '@mui/material';
import './react-editor.css';

const ReactQuillEditor = ({ editorLabel, htmlText, onEditorStateChange, showTags, maxCharacters  }) => {
  const leadTagList = [
    '[First Name]',
    '[Last Name]',
    '[Company Name]',
    '[Title]',
    '[Email]',
    '[Recent Job Post]',
    '[Industry]',
  ];

  const BDTags = ['[BD Name]', '[BD Title]', '[BD Email]', '[BD Telephone]'];
  const [copiedTag, setCopiedTag] = useState(null);
  const [editorContent, setEditorContent] = useState(htmlText || ''); // State to manage editor content
  const [charCount, setCharCount] = useState(0); // State to manage character count
  const [isExceeded, setIsExceeded] = useState(false); // State to manage if limit is exceeded
  const editorRef = useRef(null);

  useEffect(() => {
    // Update editor content if htmlText changes
    setEditorContent(htmlText);
    updateCharCount(htmlText);
  }, [htmlText]);

  const handleCopyToClipboard = (tag) => {
    navigator.clipboard.writeText(tag);
    setCopiedTag(tag);
    setTimeout(() => setCopiedTag(null), 1000); // Reset copiedTag after 1 second
  };

  // Function to update character count and check if limit is exceeded
  const updateCharCount = (content) => {
    // Strip HTML tags to get plain text
    const plainText = content?.replace(/<[^>]+>/g, '');
    const currentCharCount = plainText?.length;
    setCharCount(currentCharCount);
    setIsExceeded(currentCharCount > maxCharacters);
  };

  // Custom toolbar configuration
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'], // Media options
    [{ list: 'ordered' }, { list: 'bullet' }], // List options
    ['clean'], // Clear formatting
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header options
    [{ script: 'sub' }, { script: 'super' }], // Superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // Indent/outdent
    [{ direction: 'rtl' }], // Text direction (right-to-left)
    [{ size: ['small', false, 'large', 'huge'] }], // Font size options
    [{ color: [] }, { background: [] }], // Text color and background
    [{ font: [] }], // Font family
    [{ align: [] }], // Text alignment
  ];

  const modules = {
    toolbar: toolbarOptions, // Attach custom toolbar
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'link',
    'image',
    'video',
    'list',
    'bullet',
    'indent',
    'script',
    'direction',
    'size',
    'color',
    'background',
    'font',
    'align',
  ];

  return (
    <div>
      <h3>{editorLabel || ''}</h3>
      {showTags && (
        <>
          <Typography variant="h6">Lead Tags</Typography>
          <div className="tag-list-container">
            <div className="tags-container">
              {leadTagList.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => handleCopyToClipboard(tag)} // Only copy tag, do not insert into editor
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <Typography variant="h6">BD Tags</Typography>
          <div className="tag-list-container">
            <div className="tags-container">
              {BDTags.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => handleCopyToClipboard(tag)} // Only copy tag, do not insert into editor
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
            {copiedTag && <p className="copied-message">Copied Tag: {copiedTag}</p>}
            <br />
            <Alert severity="error">Note: Do not edit the tags in the editor.</Alert>
          </div>
        </>
      )}


      <ReactQuill
        ref={editorRef} // Attach ref for Quill instance
        value={editorContent} // Controlled input for editor
        onChange={(content, delta, source, editor) => {
          setEditorContent(content); // Update local state with new content
          updateCharCount(content); // Update character count
          onEditorStateChange({ data: editor.getHTML() }); // Send HTML data to parent component
        }}
        style={{ height: '900px', overflowY: 'scroll', maxHeight: '900px', width: '100%' }} // Editor styling
        modules={modules} // Attach modules with toolbar
        formats={formats} // Specify formats
        theme="snow" // Use the Snow theme
      />
      <div style={{ marginTop: '10px', display:'flex', justifyContent: 'flex-end' }}>
        {isExceeded ? (
          <Typography variant="body2" color="error">
            Character limit of {maxCharacters} exceeded ({charCount}/{maxCharacters})
          </Typography>
        ) : (
          <Typography variant="body2">
            {charCount}/{maxCharacters} characters
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ReactQuillEditor;
