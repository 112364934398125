import { Icon } from '@iconify/react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CustomizedRolesComponent({
  rolesss,
  title,
  onPageChange,
  onRowsPerPageChange,
  onPreviousClick,
  OnAdvanceClick,
  totalCount,
  userRole,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [groupedRoles, setGroupedRoles] = useState([]);
  const [groupedRolesArray, setGroupedRolesArray] = useState([]);
  const [paginatedRoles, setPaginatedRoles] = useState([]);

  // Group roles by service name

  useEffect(() => {
    const temp = Array.isArray(rolesss)
      ? rolesss.reduce((groups, role) => {
          const service = role?.role_service_working?.service_name;
          if (!groups[service]) {
            groups[service] = [];
          }
          groups[service].push(role);
          return groups;
        }, {})
      : {};
    setGroupedRoles(temp);
  }, [rolesss]);

  useEffect(() => {
    // Convert groupedRoles object to array for easier pagination
    const temp = Object.keys(groupedRoles).map((service) => ({
      service,
      roles: groupedRoles[service],
    }));
    setGroupedRolesArray(temp);
  }, [groupedRoles]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  // Get the current roles to display based on pagination

  useEffect(() => {
    const temp = groupedRolesArray.slice(0, rowsPerPage);
    setPaginatedRoles(temp);
  }, [page, rowsPerPage, groupedRolesArray]);

  // Get border color based on service name
  const getBorderColor = (service) => {
    switch (service) {
      case 'CV-Sourcing':
        return '#F85ED6';
      case 'Pre-Qualification':
        return '#8C85FB';
      case '360/Direct':
        return '#00C1FE';
      default:
        return 'black';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FDC748';
      case 'In-Progress':
        return '#00C1FE';
      case 'Re-working':
        return '#7D8592';
      case 'Issue':
        return 'red';
      case 'Done':
        return '#E9DB62';
      case 'Completed':
        return '#23C981';
      case 'Final':
        return '#760DFF';
      case 'Locked':
        return '#D06BFF';
      default:
        return 'black';
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFF3D8';
      case 'In-Progress':
        return '#D1F1FC';
      case 'Re-working':
        return '#ECECEC';
      case 'Issue':
        return '#FFC9C9';
      case 'Done':
        return '#FEFBC7';
      case 'Completed':
        return '#D7FEED';
      case 'Final':
        return '#EFE2FF';
      case 'Locked':
        return '#F7E6FF';
      default:
        return 'black';
    }
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-around" sx={{ marginTop: '3rem' }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="ri:skip-back-fill" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            onClick={() => {
              onPreviousClick(title);
            }}
          >
            Previous Roles
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          fullWidth
          sx={{
            borderRadius: '10px',
            backgroundColor: '#DCECFF',
            height: '55px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '15px',
          }}
        >
          <Typography variant="h5" sx={{ width: '100%' }}>
            {title ? title : 'Customized Roles'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="fluent:next-16-filled" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                borderColor: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            onClick={() => {
              OnAdvanceClick(title);
            }}
          >
            Advance Roles
          </Button>
        </Grid>
      </Grid>
      <br />
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <TableContainer sx={{ borderRadius: '10px', overflow: 'hidden' }}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC', padding: '5px' }}>
                {userRole !== 'resourcer' && (
                  <>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>R</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>S</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>ACM</TableCell>
                  </>
                )}

                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Client No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Role</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Role No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>No. CVs</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>No. LIs</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Services</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Resourcer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRoles?.length > 0 ? (
                <>
                  {paginatedRoles?.map(({ service, roles }) => (
                    <React.Fragment key={service}>
                      {roles.map((role, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            borderTop: index === 0 ? `3px dashed ${getBorderColor(service)}` : 'none',
                            borderLeft: `3px dashed ${getBorderColor(service)}`,
                            borderRight: `3px dashed ${getBorderColor(service)}`,
                            borderBottom: index === roles.length - 1 ? `3px dashed ${getBorderColor(service)}` : 'none',
                            marginBottom: '1px',
                            '& td': {
                              borderBottom: '1px solid #D9D9D9',
                            },
                          }}
                        >
                          {userRole !== 'resourcer' && (
                            <>
                              <TableCell sx={{ borderRadius: '10px 0 0 10px' }}>{role?.acm_name}</TableCell>
                              <TableCell>{role?.acm_name}</TableCell>
                              <TableCell>{role?.acm_name}</TableCell>
                            </>
                          )}
                          {/* <TableCell>{isNaN(role?.role_number) ? 'N/A' : role?.role_number}</TableCell> */}
                          <TableCell sx={{ width: '40px' }}>{index + 1}</TableCell>
                          <TableCell sx={{ width: '40px' }}>{role?.client_number}</TableCell>
                          <TableCell style={{ color: getBorderColor(service), width: '40px' }}>
                            <Typography
                              onClick={() => {
                                if (userRole === 'resourcer') {
                                  navigate(`/resourcing/roles-processing/${role?.id}`);
                                } else {
                                  navigate(`/acm/roles-processing/${role?.id}`);
                                }
                              }}
                              sx={{ fontSize: '15px', cursor: 'pointer' }}
                            >
                              {role?.roles_working_role?.title}
                            </Typography>
                          </TableCell>
                          {console.log('sdagfsdafsdaf', role)}
                          {/* <TableCell>{`${role?.role_client_working?.credits_used} / ${role?.role_client_working?.no_of_credits}`}</TableCell> */}
                          <TableCell sx={{ width: '40px' }}>{`${role?.role_number}`}</TableCell>
                          <TableCell sx={{ width: '40px' }}>
                            {role?.role_working_history?.length > 0
                              ? role?.role_working_history[0]?.cvs_submitted
                              : '0'}
                          </TableCell>
                          <TableCell sx={{ width: '40px' }}>
                            {role?.role_working_history?.length > 0
                              ? role?.role_working_history[0]?.lis_submitted
                              : '0'}
                          </TableCell>
                          <TableCell sx={{ width: '40px' }} style={{ color: getBorderColor(service) }}>
                            {role?.role_service_working?.service_name}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                backgroundColor: getStatusBackgroundColor(role?.status),
                                color: getStatusColor(role?.status),
                                padding: '6px',
                                width: '120px',
                                margin: '5px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                borderRadius: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                              {role?.status}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderRadius: '0 10px 10px 0' }}>
                            {role?.roles_working_resourcer
                              ? `${role?.roles_working_resourcer?.first_name} ${role?.roles_working_resourcer?.last_name}`
                              : 'Not Started'}
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* Spacer between groups */}
                      <TableRow>
                        <TableCell colSpan={10} />
                      </TableRow>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalCount} // Total number of records from server
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default CustomizedRolesComponent;
