import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

const EditSignatureDialog = ({ open, onClose, onSubmit, updateUser }) => {
  
  const [signature, setSignature] = useState();

  const initialValue = (updateUser) => ({
    first_name: '',
    last_name: '',
    phone_number: '',
    cnic: '',
    email: '',
    designation: '',
    ...(updateUser && {
      id: updateUser.id,
      first_name: updateUser.first_name,
      last_name: updateUser.last_name,
      phone_number: updateUser.phone_number,
      cnic: updateUser.cnic,
      email: updateUser.email,
      designation: updateUser.designation,
    }),
  });
  const handleSubmit = (values, actions) => {
    setSignature(values);
    onSubmit(values, actions);
  };
  
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="text-[#00C1FE] text-lg m-4">Edit Signatures </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValue(updateUser)}
            onSubmit={handleSubmit}
            // onSubmit={(values, actions) => {
            //   actions.setSubmitting(true);
            //   onSubmit(values, actions);
            // }}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit, getFieldProps, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <br />
                    <TextField
                      id="first_name"
                      fullWidth
                      size="small"
                      name="first_name"
                      label="First Name"
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      {...getFieldProps('first_name')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <br />
                    <TextField
                      id="last_name"
                      fullWidth
                      size="small"
                      name="last_name"
                      label="last Name"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      {...getFieldProps('last_name')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="email"
                      fullWidth
                      size="small"
                      name="email"
                      label="Email"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      {...getFieldProps('email')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="phone_number"
                      fullWidth
                      size="small"
                      name="phone_number"
                      label="Contact Number"
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      {...getFieldProps('phone_number')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="cnic"
                      fullWidth
                      size="small"
                      name="cnic"
                      value={values.cnic}
                      label="CNIC ( Without Dashes )"
                      error={Boolean(touched.cnic && errors.cnic)}
                      helperText={touched.cnic && errors.cnic}
                      onChange={(e) => {
                        const value = e.target.value;
                        let formattedValue = value.replace(/\D/g, ''); // Remove non-digit characters

                        if (formattedValue.length > 13) {
                          formattedValue = formattedValue.substring(0, 13); // Limit the length to 13 characters
                        }

                        setFieldValue('cnic', formattedValue);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="designation"
                      fullWidth
                      size="small"
                      name="designation"
                      value={values.designation}
                      label="Add / Change Designation)"
                      error={Boolean(touched.designation && errors.designation)}
                      helperText={touched.designation && errors.designation}
                      {...getFieldProps('designation')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" sx={{ marginTop: '2rem' }}>
                  <button
                    className="mt-4 mr-4 border-2 border-[#00C1FE] text-sm bg-white text-[#00C1FE] px-2 py-2 rounded-md hover:bg-[#00C1FE] hover:text-white"
                    onClick={() => onClose()}
                    sx={{ marginRight: '20px' }}
                    size="small"
                  >
                    Cancel Update
                  </button>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                    className="px-2 py-[9px] mt-4 text-white bg-[#00C1FE] hover:bg-[#00C1FE] text-sm rounded-md "
                  >
                    Update Signatures
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditSignatureDialog;
