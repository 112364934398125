import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { RoleTypeBasedRoles } from '../acm/role-section';
import { ArrowPipeline, CustomizedRolesComponent, ReactMultiDatePicker } from '../acm/components';

function RolesPage(props) {
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const [roles, setRoles] = useState([]);
  const [fixRoles, setFixRoles] = useState([]);
  const [trialPage, setTrialPage] = useState(0);
  const [regularPage, setRegularPage] = useState(0);
  const [fixPage, setFixPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [fixLimit, setFixLimit] = useState(10);
  const [status, setStatus] = useState({ status: '' });
  const [trialStatus, setTrialStatus] = useState({ status: '' });
  const [fixStatus, setFixStatus] = useState({ status: '' });
  const [regularStatus, setRegularStatus] = useState({ status: '' });
  const [fixStats, setFixStats] = useState();
  const [previousRoles, setPreviousRoles] = useState({ title: '' });
  const [regularLimit, setRegularLimit] = useState(10);
  const [trialTotalCount, setTrialTotalCount] = useState(0);
  const [fixTotalCount, setFixTotalCount] = useState(0);
  const [regularTotalCount, setRegularTotalCount] = useState(0);
  const [advanceRoles, setAdvanceRoles] = useState({ title: '' });
  const [regularRoles, setRegularRoles] = useState([]);
  const [regularStats, setRegularStats] = useState();

  const [roleType, setRoleType] = useState({ roleType: '' });
  const [total, setTotal] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalInProgress, setTotalInProgress] = useState(0);
  const [totalDone, setTotalDone] = useState(0);

  const [trialStats, setTrialStats] = useState();

  const getTrialRoles = useCallback(() => {
    try {
      Get(
        {
          page: trialPage,
          limit: limit,
          status: trialStatus.status ? trialStatus.status : status.status,
          start_date: formattedDates[0],
          end_date: formattedDates[1],
          previous_roles: previousRoles?.title === 'Trial' ? true : false,
          advance_roles: advanceRoles?.title === 'Trial' ? true : false,
        },
        API_URLS.getTrialRolesWithStats,
        (resp) => {
          console.log(resp);
          setRoles(resp?.data?.roles);
          setTrialStats(resp?.data);
          setTrialTotalCount(resp?.data?.pagination?.totalRoles);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [trialStatus, trialPage, limit, formattedDates, previousRoles, advanceRoles]);

  useEffect(() => {
    getTrialRoles();
  }, [getTrialRoles]);

  const getFixRoles = useCallback(() => {
    try {
      Get(
        {
          page: fixPage,
          limit: fixLimit,
          status: fixStatus.status ? fixStatus.status : status.status,
          start_date: formattedDates[0],
          end_date: formattedDates[1],
          previous_roles: previousRoles?.title === 'Fix' ? true : false,
          advance_roles: advanceRoles?.title === 'Fix' ? true : false,
        },
        API_URLS.getFixRolesWithStats,
        (resp) => {
          setFixRoles(resp?.data?.roles);
          setFixStats(resp?.data);
          setFixTotalCount(resp?.data?.pagination?.totalRoles);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [fixStatus, fixPage, fixLimit, formattedDates, previousRoles, advanceRoles]);

  useEffect(() => {
    getFixRoles();
  }, [getFixRoles]);

  const getRegularRoles = useCallback(() => {
    try {
      Get(
        {
          page: regularPage,
          limit: regularLimit,
          status: regularStatus.status ? regularStatus.status : status.status,
          start_date: formattedDates[0],
          end_date: formattedDates[1],
          previous_roles: previousRoles?.title === 'Regular' ? true : false,
          advance_roles: advanceRoles?.title === 'Regular' ? true : false,
        },
        API_URLS.getRegularRolesWithStats,
        (resp) => {
          console.log(resp);
          setRegularRoles(resp?.data?.roles);
          setRegularStats(resp?.data);
          setRegularTotalCount(resp?.data?.pagination?.totalRoles);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [regularStatus, regularPage, regularLimit, formattedDates, previousRoles, advanceRoles]);

  useEffect(() => {
    getRegularRoles();
  }, [getRegularRoles]);

  const calculateTotalStats = () => {
    const total =
      (trialStats ? trialStats?.status?.total : 0) +
      (fixStats ? fixStats?.status?.total : 0) +
      (regularStats ? regularStats?.status?.total : 0);

    const totalPending =
      (trialStats ? trialStats?.status?.Pending : 0) +
      (fixStats ? fixStats?.status?.Pending : 0) +
      (regularStats ? regularStats?.status?.Pending : 0);

    const totalInProgress =
      (trialStats ? trialStats?.status?.InProgress : 0) +
      (fixStats ? fixStats?.status?.InProgress : 0) +
      (regularStats ? regularStats?.status?.InProgress : 0);

    const totalDone =
      (trialStats ? trialStats?.status?.Done : 0) +
      (fixStats ? fixStats?.status?.Done : 0) +
      (regularStats ? regularStats?.status?.Done : 0);

    setTotal(total);
    setTotalPending(totalPending);
    setTotalInProgress(totalInProgress);
    setTotalDone(totalDone);
  };

  useEffect(() => {
    calculateTotalStats();
  }, [trialStats, fixStats, regularStats]);

  return (
    <div>
      <Box display="flex" width="95%" margin="auto">
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          Roles
        </Typography>
      </Box>
      <br />
      {roleType?.roleType ? (
        <RoleTypeBasedRoles
          roleType={roleType}
          onBack={() => {
            setRoleType((prevState) => ({ ...prevState, roleType: '' }));
          }}
        />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: '1rem', backgroundColor: 'white', width: '95%', margin: 'auto' }}
          >
            <>
              <ArrowPipeline
                title="Total"
                total={total ? total : 0}
                left={totalPending ? totalPending : 0}
                center={totalInProgress ? totalInProgress : 0}
                right={totalDone ? totalDone : 0}
                color="#E496FF"
                onClick={() => {
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onLeftClick={() => {
                  setStatus((prevState) => ({ ...prevState, status: 'Pending' }));
                  setTrialStatus((prevState) => ({ ...prevState, status: '' }));
                  setRegularStatus((prevState) => ({ ...prevState, status: '' }));
                  setFixStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onCenterClick={() => {
                  setStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
                  setTrialStatus((prevState) => ({ ...prevState, status: '' }));
                  setRegularStatus((prevState) => ({ ...prevState, status: '' }));
                  setFixStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onRightClick={() => {
                  setStatus((prevState) => ({ ...prevState, status: 'Done' }));
                  setTrialStatus((prevState) => ({ ...prevState, status: '' }));
                  setRegularStatus((prevState) => ({ ...prevState, status: '' }));
                  setFixStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                leftTooltip="Pending"
                centerTooltip="In progress"
                rightTooltip="Done"
              />
              <ArrowPipeline
                title="Trial"
                onClick={() => {
                  setRoleType((prevState) => ({ ...prevState, roleType: 'Trial' }));
                }}
                total={trialStats ? trialStats?.status?.total : 0}
                left={trialStats ? trialStats?.status?.Pending : 0}
                center={trialStats ? trialStats?.status?.InProgress : 0}
                right={trialStats ? trialStats?.status?.Done : 0}
                color="#F85ED6"
                onLeftClick={() => {
                  setTrialStatus((prevState) => ({ ...prevState, status: 'Pending' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onCenterClick={() => {
                  setTrialStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onRightClick={() => {
                  setTrialStatus((prevState) => ({ ...prevState, status: 'Done' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                leftTooltip="Pending"
                centerTooltip="In progress"
                rightTooltip="Done"
              />
              <ArrowPipeline
                title="Regular"
                onClick={() => {
                  setRoleType((prevState) => ({ ...prevState, roleType: 'Regular' }));
                }}
                total={regularStats ? regularStats?.status?.total : 0}
                left={regularStats ? regularStats?.status?.Pending : 0}
                center={regularStats ? regularStats?.status?.InProgress : 0}
                right={regularStats ? regularStats?.status?.Done : 0}
                color="#8C85FB"
                onLeftClick={() => {
                  setRegularStatus((prevState) => ({ ...prevState, status: 'Pending' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onCenterClick={() => {
                  setRegularStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onRightClick={() => {
                  setRegularStatus((prevState) => ({ ...prevState, status: 'Done' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                leftTooltip="Pending"
                centerTooltip="In progress"
                rightTooltip="Done"
              />
              <ArrowPipeline
                title="Fix"
                onClick={() => {
                  setRoleType((prevState) => ({ ...prevState, roleType: 'Fix' }));
                }}
                total={fixStats ? fixStats?.status?.total : 0}
                left={fixStats ? fixStats?.status?.Pending : 0}
                center={fixStats ? fixStats?.status?.InProgress : 0}
                right={fixStats ? fixStats?.status?.Done : 0}
                color="#00C1FE"
                onLeftClick={() => {
                  setFixStatus((prevState) => ({ ...prevState, status: 'Pending' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onCenterClick={() => {
                  setFixStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                onRightClick={() => {
                  setFixStatus((prevState) => ({ ...prevState, status: 'Done' }));
                  setStatus((prevState) => ({ ...prevState, status: '' }));
                }}
                leftTooltip="Pending"
                centerTooltip="In progress"
                rightTooltip="Done"
              />
            </>
          </Box>
          <br />
          <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}>
            <ReactMultiDatePicker
              onChange={(dateRange) => {
                setFormattedDates(dateRange);
              }}
              style={{
                width: '240px',
                height: '50px',
                paddingRight: '5px',
                marginRight: '5px',
                border: '2px solid #00C1FE',
                outline: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '10px',
                boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
                backgroundColor: '#ECF6FE',
                color: '#00C1FE',
                boxSizing: 'border-box',
                paddingLeft: '10px',
              }}
            />
          </Box>
          <div style={{ width: '95%', margin: 'auto' }}>
            <CustomizedRolesComponent
              rolesss={roles}
              userRole="resourcer"
              title="Trial"
              onPageChange={(page) => setTrialPage(page)}
              onRowsPerPageChange={(limit) => setLimit(limit)}
              onPreviousClick={(title) => {
                setPreviousRoles((prevState) => ({ ...prevState, title: title }));
                setAdvanceRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              OnAdvanceClick={(title) => {
                setAdvanceRoles((prevState) => ({ ...prevState, title: title }));
                setPreviousRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              totalCount={trialTotalCount}
            />
          </div>
          <div style={{ width: '95%', margin: 'auto', marginTop: '5px' }}>
            <CustomizedRolesComponent
              userRole="resourcer"
              rolesss={fixRoles}
              title="Fix"
              onPageChange={(page) => {
                console.log('page', page);
                setFixPage(page);
              }}
              onRowsPerPageChange={(limit) => setFixLimit(limit)}
              onPreviousClick={(title) => {
                setPreviousRoles((prevState) => ({ ...prevState, title: title }));
                setAdvanceRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              OnAdvanceClick={(title) => {
                setAdvanceRoles((prevState) => ({ ...prevState, title: title }));
                setPreviousRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              totalCount={fixTotalCount}
            />
          </div>
          <br />
          <div style={{ width: '95%', margin: 'auto', marginTop: '5px' }}>
            <CustomizedRolesComponent
              userRole="resourcer"
              rolesss={regularRoles}
              title="Regular"
              onPageChange={(page) => setRegularPage(page)}
              onRowsPerPageChange={(limit) => setRegularLimit(limit)}
              onPreviousClick={(title) => {
                setPreviousRoles((prevState) => ({ ...prevState, title: title }));
                setAdvanceRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              OnAdvanceClick={(title) => {
                setAdvanceRoles((prevState) => ({ ...prevState, title: title }));
                setPreviousRoles((prevState) => ({ ...prevState, title: '' }));
              }}
              totalCount={regularTotalCount}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default RolesPage;
