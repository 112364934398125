import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React from 'react';

function ViewResolvedIssueRoles({ open, onClose , roles }) {
  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No',
      minWidth: 170,
    },
    {
      accessorKey: 'title',
      header: 'Role Title',
      minWidth: 170,
    },
    {
      accessorKey: 'client_number',
      header: 'Client Number',
    },
    {
      accessorKey: 'abc',
      header: 'Resolved By',
    },
  ];

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ sx: { width: '120%' } }}>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">Resolved Issues</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <MaterialReactTable
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center',
                },
                size: 50,
              },
            }}
            columns={columns}
            data={roles}
            defaultColumn={{
              size: 100, //make columns wider by default
            }}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
            positionToolbarAlertBanner="bottom"
            manualFiltering
            actionsColumnIndex={-1}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewResolvedIssueRoles;
