import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';

function AddLIComponent() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');
  const [rows, setRows] = useState([
    {
      profileType: '',
      liLink: '',
      personalNumber: '',
      businessNumber: '',
      personalEmail: '',
      businessEmail: '',
      isSubmitted: false,
      isChecked:false,
    },
    {
      profileType: '',
      liLink: '',
      personalNumber: '',
      businessNumber: '',
      personalEmail: '',
      businessEmail: '',
      isSubmitted: false,
      isChecked:false,
    },
    {
      profileType: '',
      liLink: '',
      personalNumber: '',
      businessNumber: '',
      personalEmail: '',
      businessEmail: '',
      isSubmitted: false,
      isChecked:false,
    },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const handleSubmitRow = (index) => {
    const rowData = rows[index];
    const { liLink, profileType, personalNumber, businessNumber, personalEmail, businessEmail } = rowData;

    const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Profile type validation
    if (profileType !== 'Normal' && profileType !== 'openToWork') {
      enqueueSnackbar('Please select a valid profile type (Normal or Open to Work)', { variant: 'error' });
      return;
    }

    // LinkedIn link validation
    if (!liLink || !pattern.test(liLink)) {
      enqueueSnackbar('Please enter a valid LinkedIn link', { variant: 'error' });
      return;
    }

    // Email validations
    if (personalEmail && !emailPattern.test(personalEmail)) {
      enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
      return;
    }
    if (businessEmail && !emailPattern.test(businessEmail)) {
      enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
      return;
    }

    const payload = {
      profile_link: liLink,
      personal_contact: personalNumber,
      business_number: businessNumber,
      personal_email: personalEmail,
      business_email: businessEmail,
      profile_type: profileType,
      user_id: parseInt(userId),
      profile_status: 'pending',
      role_id: parseInt(roleId),
    };

    try {
      Post(
        payload,
        API_URLS.addLIToRole,
        (resp) => {
          enqueueSnackbar('LI added successfully', { variant: 'success' });
          // Update submission status
          const updatedRows = [...rows];
          updatedRows[index].isSubmitted = true;
          setRows(updatedRows);
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const addMoreRows = () => {
    const newRows = [
      { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
      { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
      { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
    ];
    setRows([...rows, ...newRows]);
  };

  const selectAll = () => {
    // if all are checked, uncheck all
    if (rows.every((row) => row.isChecked)) {
      const updatedRows = [...rows];
      updatedRows.map((row) => {
        row.isChecked = false;
      });
      setRows(updatedRows);
      return;
    }else{
      const updatedRows = [...rows];
      updatedRows.map((row) => {
        row.isChecked = true;
      });
      setRows(updatedRows);
    }

  };

  const addSelectedLIs = () => {
    // get all selected rows
    const selectedRows = rows.filter((row) => row.isChecked);
    //  check if any profile is already subnmitted
    const isAnySubmitted = selectedRows.some((row) => row.isSubmitted);
    if(isAnySubmitted){
      enqueueSnackbar('Please select only those rows which are not submitted', { variant: 'error' });
      return;
    }
    if(selectedRows.length === 0){
      enqueueSnackbar('Please select atleast one row to add', { variant: 'error' });
      return;
    }
    selectedRows.forEach((row) => {
      const { liLink, profileType, personalNumber, businessNumber, personalEmail, businessEmail } = row;

      const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      // Profile type validation
      if (profileType !== 'Normal' && profileType !== 'openToWork') {
        enqueueSnackbar('Please select a valid profile type (Normal or Open to Work)', { variant: 'error' });
        return;
      }

      // LinkedIn link validation
      if (!liLink || !pattern.test(liLink)) {
        enqueueSnackbar('Please enter a valid LinkedIn link', { variant: 'error' });
        return;
      }

      // Email validations
      if (personalEmail && !emailPattern.test(personalEmail)) {
        enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
        return;
      }
      if (businessEmail && !emailPattern.test(businessEmail)) {
        enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
        return;
      }

      const payload = {
        profile_link: liLink,
        personal_contact: personalNumber,
        business_number: businessNumber,
        personal_email: personalEmail,
        business_email: businessEmail,
        profile_type: profileType,
        user_id: parseInt(userId),
        profile_status: 'pending',
        role_id: parseInt(roleId),
      };

      try {
        Post(
          payload,
          API_URLS.addLIToRole,
          (resp) => {
            enqueueSnackbar('LI added successfully', { variant: 'success' });
            // Update submission status
            const updatedRows = [...rows];
            updatedRows.map((row) => {
              if (row.isChecked) {
                row.isSubmitted = true;
              }
            });
            setRows(updatedRows);
          },
          (error) => {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
          }
        );
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }, []);
  }


  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <Box display="flex" marginBottom="10px" alignItems="center">
        <Icon
          icon="ion:arrow-back"
          style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
          onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
        />
        <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE', fontWeight: 'bold' }}>Back to Role Detail</p>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="20px">
        {/* button to  add all selected LIs at a time*/}
        <Button variant="outlined" color="primary" fullWidth style={{ marginRight: '10px' }} onClick={()=>addSelectedLIs()}>
          Add Selected LIs
        </Button>
        <select
          name="profileType"
          onChange={(e) => {
            // change type of all selected rows to the selected type
            const updatedRows = [...rows];
            updatedRows.map((row) => {
              if (row.isChecked) {
                row.profileType = e.target.value;
              }
            });

            setRows(updatedRows);
          }}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '5px',
            border: '1px solid #D8D8D8',
            backgroundColor: '#F9F9F9',
          }}
        >
          <option value="">Choose Type</option>
          <option value="Normal">Normal</option>
          <option value="openToWork">Open to Work</option>
        </select>
      </Box>
      <table
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderCollapse: 'collapse',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              S.No
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Mark all <br />{' '}
              <input type="checkbox" checked={rows.every((row) => row.isChecked)} onChange={selectAll} />
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              LI Type
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
                width: '25%',
              }}
            >
              LI Link
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Personal Number
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Business Number
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Personal Email
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Business Email
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index} style={{ borderBottom: index === rows.length - 1 ? 'none' : '1px solid #D8D8D8' }}>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  color: '#979797',
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  color: '#979797',
                }}
              >
                <input
                  type="checkbox"
                  checked={row.isChecked}
                  onChange={() => {
                    const updatedRows = [...rows];
                    updatedRows[index].isChecked = !updatedRows[index].isChecked;
                    setRows(updatedRows);
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
                <select
                  name="profileType"
                  value={row.profileType}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  <option value="">Choose Type</option>
                  <option value="Normal">Normal</option>
                  <option value="openToWork">Open to Work</option>
                </select>
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="liLink"
                  value={row.liLink}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="personalNumber"
                  value={row.personalNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="businessNumber"
                  value={row.businessNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="personalEmail"
                  value={row.personalEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="businessEmail"
                  value={row.businessEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleSubmitRow(index)}
                  disabled={row.isSubmitted}
                  style={{
                    backgroundColor: row.isSubmitted ? '#B0B0B0' : '#007BFF',
                  }}
                >
                  {row.isSubmitted ? 'Submitted' : 'Submit'}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box marginTop="20px">
        <Button variant="outlined" onClick={addMoreRows} color="primary">
          Add More Rows
        </Button>
      </Box>
    </div>
  );
}

export default AddLIComponent;



// -------------------------------------------------------------- Manual Approach --------------------------------------------------------------
// import { Icon } from '@iconify/react';
// import { Add } from '@mui/icons-material';
// import { Box, Button } from '@mui/material';
// import React, { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Post } from 'src/actions/API/apiActions';
// import { API_URLS } from 'src/constants/apiURLs';
// import { enqueueSnackbar } from 'notistack';
// import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';

// function AddLIComponent() {
//   const navigate = useNavigate();
//   const [openLIAddDialog, setOpenLIAddDialog] = useState(false);
//   const { roleId } = useParams();
//   const userId = localStorage.getItem('userId');
//   const [rows, setRows] = useState([
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//   ]);

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedRows = [...rows];
//     updatedRows[index][name] = value;
//     setRows(updatedRows);
//   };

//   // const handleSendLIToScrapper = (index) => {
//   //   const rowData = rows[index];
//   //   const li = rowData?.liLink
//   //   if(!li){
//   //     enqueueSnackbar('Please enter LI link', { variant: 'error' });
//   //     return
//   //   }

//   //   try {
//   //     Post(
//   //       { url  : li },
//   //       LI_Scrapper_URLs.scrapData,
//   //       (resp) => {
//   //         enqueueSnackbar('Response reeived from scrapper');
//   //         setScrappedData(resp);
//   //         handleSubmitRow(resp)
//   //       },
//   //       (error) => {
//   //         console.log(error);
//   //       }
//   //     );
//   //   } catch (error) {
//   //     console.log(error);

//   //   }
//   // }
//   const handleSubmitRow = (index) => {
//     const rowData = rows[index];
//     const { liLink, profileType, personalNumber, businessNumber, personalEmail, businessEmail } = rowData;

//     const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
//     const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

//     // Profile type validation
//     if (profileType !== 'Normal' && profileType !== 'openToWork') {
//       enqueueSnackbar('Please select a valid profile type (Normal or Open to Work)', { variant: 'error' });
//       return;
//     }

//     // LinkedIn link validation
//     if (!liLink || !pattern.test(liLink)) {
//       enqueueSnackbar('Please enter a valid LinkedIn link', { variant: 'error' });
//       return;
//     }

//     // Email validations
//     if (personalEmail && !emailPattern.test(personalEmail)) {
//       enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
//       return;
//     }
//     if (businessEmail && !emailPattern.test(businessEmail)) {
//       enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
//       return;
//     }

//     const payload = {
//       profile_link: liLink,
//       personal_contact: personalNumber,
//       business_number: businessNumber,
//       personal_email: personalEmail,
//       business_email: businessEmail,
//       profile_type: profileType,
//       user_id: parseInt(userId),
//       profile_status: 'pending',
//       role_id: parseInt(roleId),
//     };

//     try {
//       Post(
//         payload,
//         API_URLS.addLIToRole,
//         (resp) => {
//           enqueueSnackbar('LI added successfully', { variant: 'success' });

//           // Update submission status
//           const updatedRows = [...rows];
//           updatedRows[index].isSubmitted = true;
//           setRows(updatedRows);
//         },
//         (error) => {
//           console.log('lsdbhfjsdfsdf', error?.response?.data?.message);
//           enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
//         }
//       );
//     } catch (error) {
//       enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
//     }
//   };

//   const addMoreRows = () => {
//     const newRows = [
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//     ];
//     setRows([...rows, ...newRows]);
//   };

//   return (
//     <div style={{ width: '95%', margin: 'auto' }}>
//       <Box display="flex" marginBottom="10px" alignItems="center">
//         <Icon
//           icon="ion:arrow-back"
//           style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
//           onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
//         />
//         <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE', fontWeight: 'bold' }}>Back to Role Detail</p>
//       </Box>
//       <table style={{ width: '100%', backgroundColor: 'white', borderCollapse: 'collapse', borderRadius: '20px' }}>
//         <thead>
//           <tr>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderLeft: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               S.No
//             </th>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderLeft: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               LI Type
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               LI Link
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Personal Number
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Business Number
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Personal Email
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Business Email
//             </th>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderRight: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               Action
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {rows?.map((row, index) => (
//             <tr key={index} style={index === rows.length - 1 ? { borderBottom: 'none' } : {}}>
//               <td
//                 style={{
//                   border: '1px solid #D8D8D8',
//                   padding: '10px',
//                   textAlign: 'center',
//                   borderLeft: 'none',
//                   color: '#979797',
//                 }}
//               >
//                 {index + 1}
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
//                 <select
//                   name="profileType"
//                   value={row.profileType}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 >
//                   <option value="choose">Choose Type</option>
//                   <option value="Normal">Normal</option>
//                   <option value="openToWork">Open to Work</option>
//                 </select>
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
//                 <input
//                   type="text"
//                   name="liLink"
//                   value={row.liLink}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="text"
//                   name="personalNumber"
//                   value={row.personalNumber}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="text"
//                   name="businessNumber"
//                   value={row.businessNumber}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="email"
//                   name="personalEmail"
//                   value={row.personalEmail}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="email"
//                   name="businessEmail"
//                   value={row.businessEmail}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', borderRight: 'none' }}>
//                 <Box display="flex" justifyContent="center">
//                   <Button
//                     disabled={row.isSubmitted}
//                     sx={{
//                       background: '#00C1FE',
//                       color: '#fff',
//                       width: '90px',
//                       height: '35px',
//                       border: '1px solid #00C1FE',
//                       '&:hover': {
//                         background: '#00C1FE',
//                         color: '#fff',
//                       },
//                       borderRadius: '5px',
//                       padding: '2px',
//                     }}
//                     onClick={() => {
//                       handleSubmitRow(index);
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </Box>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <Box display="flex" justifyContent="flex-end" marginTop="1rem">
//         <Button
//           sx={{
//             background: '#00C1FE',
//             color: '#fff',
//             width: '150px',
//             height: '35px',
//             marginRight: '1rem',
//             border: '1px solid #00C1FE',
//             '&:hover': {
//               background: '#00C1FE',
//               color: '#fff',
//             },
//             borderRadius: '5px',
//             padding: '10px',
//           }}
//           onClick={addMoreRows}
//           startIcon={<Add />}
//         >
//           Add more rows
//         </Button>
//       </Box>
//       {openLIAddDialog && (
//         <ActionConfirmationDialog
//           open={openLIAddDialog}
//           onClose={() => setOpenLIAddDialog(false)}
//           title={`Are you sure you want to submit LI`}
//           actionButtonText="Yes"
//           onSubmit={() => handleSubmitRow()}
//         />
//       )}
//     </div>
//   );
// }

// export default AddLIComponent;
