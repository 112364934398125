import { Icon } from '@iconify/react';
import { ArrowBack, CheckCircle, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CreateInterview } from '.';

function CandidateDetails(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [candidate, setCandidate] = useState();
  const [selectedState, setSelectedState] = useState('overview');
  const location = useLocation();
  const previousPath = location.state?.previousPath || `/recruiter/allCandidates/${roleId}`;
  const [openCreateInterviewDialog, setOpenCreateInterviewDialog] = useState(false);
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{ marginTop: '20px', backgroundColor: '#E2F2FF', borderRadius: 4, height: '120px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
          <Avatar
            style={{
              marginRight: '20px', // Adds space between avatar and name
              backgroundColor: '#4CD4FF',
              color: '#fff',
              width: '100px',
              height: '100px',
              fontSize: '40px',
              textAlign: 'center',
            }}
          >
            A
          </Avatar>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex">
              <Typography variant="h5" sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}>
                {candidate?.name || 'Ali Raza'}
              </Typography>
              <Box
                display="flex"
                sx={{
                  border: '2px solid #C2D0DB',
                  height: '5%',
                  borderRadius: '10px',
                  padding: '5px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '5px',
                  marginLeft: '25px',
                }}
              >
                <Icon icon="ri:linkedin-fill" style={{ fontSize: '18px', cursor: 'pointer' }} />
                <Icon icon="ri:facebook-fill" style={{ fontSize: '18px', marginLeft: '10px', cursor: 'pointer' }} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginTop="10px">
              <p
                style={{
                  fontSize: '14px',
                  backgroundColor: '#4CD4FF',
                  borderRadius: '5px',
                  padding: '7px',
                  color: 'white',
                }}
              >
                {candidate?.stage || 'Screening'}
              </p>
              <Button
                sx={{
                  marginLeft: '10px',
                  padding: '10px',
                  backgroundColor: '#C7E6FF',
                  '&:hover': { backgroundColor: '#C7E6FF' },
                }}
                size="small"
              >
                <Icon icon="charm:refresh" style={{ fontSize: '18px', color: '#00C1FE' }} />
              </Button>
              <Button
                sx={{
                  marginLeft: '10px',
                  padding: '10px',
                  backgroundColor: '#C7E6FF',
                  '&:hover': { backgroundColor: '#C7E6FF' },
                }}
                size="small"
              >
                <Icon icon="pepicons-pencil:cv" style={{ fontSize: '18px', color: '#00C1FE' }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="flex" sx={{ padding: '20px', marginRight: '30px' }}>
          {/* <Tooltip title="Edit" placement="bottom">
            <Button style={{ backgroundColor: '#C7E6FF', padding: '10px' }} size="large">
              <Icon icon="lucide:edit" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip> */}
          <Tooltip title="Send Email" placement="bottom">
            <Button
              style={{ backgroundColor: '#C7E6FF', marginLeft: '10px' }}
              size="large"
              onClick={() =>
                navigate(`/recruiter/sendEmailToCandidate/1`, {
                  state: { previousPath: location.pathname }, // Pass the current path as state
                })
              }
            >
              <Icon icon="lucide:send" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip>
          <Tooltip title="Schedule Interview" placement="bottom">
            <Button
              style={{ backgroundColor: '#C7E6FF', marginLeft: '10px' }}
              size="large"
              onClick={() => setOpenCreateInterviewDialog(true)}
            >
              <Icon icon="tabler:calendar-time" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" marginTop="20px" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          value={selectedState}
          exclusive
          onChange={(e, value) => {
            if (value !== null) {
              setSelectedState(value);
            }
          }}
          aria-label="state"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: '20px',
            marginBottom: '10px', // Add margin bottom for spacing
            backgroundColor: '#E6EDF5',
            borderRadius: '35px',
            padding: '10px',
          }}
        >
          <ToggleButton
            value="overview"
            aria-label="overview"
            style={{
              color: selectedState === 'overview' ? 'white' : 'black',
              borderRadius: selectedState === 'overview' ? '25px' : 0,
              backgroundColor: selectedState === 'overview' ? '#00C1FE' : '#E6EDF5',
              border: selectedState === 'overview' ? '1px solid #00C1FE' : 'none',
              paddingBottom: 2,
              fontSize: '18px',
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value="timeline"
            aria-label="timeline"
            style={{
              color: selectedState === 'timeline' ? 'white' : 'black',
              backgroundColor: selectedState === 'timeline' ? '#00C1FE' : '#E6EDF5',
              paddingBottom: 2,
              fontSize: '18px',
              paddingTop: '5px',
              borderRadius: selectedState === 'timeline' ? '25px' : 0,
              border: selectedState === 'timeline' ? '1px solid #00C1FE' : 'none',
              paddingBottom: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
              marginLeft: '15px',
              fontWeight: 'normal',
            }}
          >
            Timeline
          </ToggleButton>
          <ToggleButton
            value="hiringPipeline"
            aria-label="hiringPipeline"
            style={{
              color: selectedState === 'hiringPipeline' ? 'white' : 'black',
              backgroundColor: selectedState === 'hiringPipeline' ? '#00C1FE' : '#E6EDF5',
              paddingBottom: 2,
              fontSize: '18px',
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '20px',
              border: selectedState === 'hiringPipeline' ? '1px solid #00C1FE' : 'none',
              marginLeft: '15px',
              borderRadius: selectedState === 'hiringPipeline' ? '25px' : 0,
              paddingRight: '20px',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            Hiring Pipeline
          </ToggleButton>
        </ToggleButtonGroup>
        <Box display="flex" marginRight="30px">
          <p style={{ color: '#838383' }}>Last Update : 02:24 PM</p> &nbsp; | &nbsp;
          <p style={{ color: '#00C1FE' }}> Hide empty fields</p>
        </Box>
      </Box>
      {selectedState === 'overview' && <CandidateOverview />}
      {selectedState === 'timeline' && <CandidateTimeline />}
      {selectedState === 'hiringPipeline' && <CandidateHiringPipeline />}

      {openCreateInterviewDialog && (
        <CreateInterview open={openCreateInterviewDialog} onClose={() => setOpenCreateInterviewDialog(false)} />
      )}
    </div>
  );
}

const CandidateOverview = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Candidate Stages</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <CandidateStages />
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Business Card</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'20px'}>
            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Origin</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Sourced"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Email</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="aliraza@gmail.com"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#4CD4FF',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                    Job Opening ID
                  </p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="uol-2-job"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                    Assigned Recruiters
                  </p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Miss xyz"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'20px'}>
            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Phone</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="+44 7678 78"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                  <Button
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#C7E6FF',
                      padding: '10px',
                      '&:hover': { backgroundColor: '#C7E6FF' },
                    }}
                    size="medium"
                  >
                    <Icon icon="material-symbols:call" style={{ fontSize: '24px', color: '#00C1FE' }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Mobile</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="+44 78876 78"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                  <Button
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#C7E6FF',
                      padding: '10px',
                      '&:hover': { backgroundColor: '#C7E6FF' },
                    }}
                    size="medium"
                  >
                    <Icon icon="material-symbols:call" style={{ fontSize: '24px', color: '#00C1FE' }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                    Account Manager
                  </p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Miss XYZ"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Skill Set</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value=""
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <br />
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Box display="flex" alignItems={'center'}>
          <p style={{ fontSize: '18px', margin: '4px', color: '#4CD4FF' }}>
            {isExpanded ? 'Hide Details' : 'Show Details'}
          </p>
          <Icon
            icon="iconamoon:arrow-down-2-fill"
            style={{ fontSize: '20px', color: '#4CD4FF', marginLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        </Box>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        {isExpanded && (
          <>
            <p style={{ fontSize: '18px', margin: '20px' }}>Basic Info</p>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Candidate ID
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="uol-can-12"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Email</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="aliraza@gmail.com"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#4CD4FF',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Phone</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="+44 4 454 54534"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                      <Button
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#C7E6FF',
                          padding: '10px',
                          '&:hover': { backgroundColor: '#C7E6FF' },
                        }}
                        size="medium"
                      >
                        <Icon icon="material-symbols:call" style={{ fontSize: '24px', color: '#00C1FE' }} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Account Manager
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Skill Set</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Candidate Name
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Ali Raza"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Mobile</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="+44 78876 78"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                      <Button
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#C7E6FF',
                          padding: '10px',
                          '&:hover': { backgroundColor: '#C7E6FF' },
                        }}
                        size="medium"
                      >
                        <Icon icon="material-symbols:call" style={{ fontSize: '24px', color: '#00C1FE' }} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Posting Title
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Designer"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Job Opening ID
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="uol-job-3"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Assigned Recruiter(s)
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <p style={{ fontSize: '18px', margin: '20px' }}>Other Info</p>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Application Source
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Application Owner
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#4CD4FF',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Country</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Application Status
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Created By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz , Thu, 2march 2024 05:00 AM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Modified By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz , Thu, 2march 2024 05:00 AM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Hiring Pipeline
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Screening"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Notes</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <TextField
          id="mention"
          name="mention"
          type="text"
          placeholder="@mention to notify users"
          size="large"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {}}
          sx={{
            backgroundColor: 'white',
            outline: 'none',
            border: '1px solid #D9D9D9',
            marginBottom: '3px',
            margin: '20px',
            borderRadius: 0,
            marginLeft: '5px',
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#cccccc',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#cccccc',
              },
            },
          }}
        />
        <br />
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontSize: '18px', margin: '4px' }}>Interview</p>
          <Button
            size="medium"
            sx={{
              backgroundColor: '#F0FCFF',
              color: '#3CD0FE',
              border: '2px solid #3CD0FE',
              borderRadius: '15px',
              padding: '10px',
              '&:hover': {
                backgroundColor: '#F0FCFF',
                color: '#3CD0FE',
                border: '2px solid #3CD0FE',
              },
            }}
          >
            <Icon icon="ic:baseline-add" style={{ fontSize: '20px', color: '#3CD0FE' }} /> &nbsp; Schedule Interview
          </Button>
        </Box>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        {/* Table */}
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #CAC5C5' }}>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>Interview</th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>From</th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>To</th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                Candidate Name
              </th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                Interview Status
              </th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>Feedback</th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                Reviewed By
              </th>
            </tr>
          </thead>
          <tbody>
            {/* You can add rows here as needed */}
            <tr>
              <td style={{ padding: '10px', color: '#3CD0FE', fontWeight: 'normal' }}>Online </td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>10:00 AM</td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>11:00 AM</td>
              <td style={{ padding: '10px', color: '#3CD0FE', fontWeight: 'normal' }}>John Doe</td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>Completed</td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>Positive</td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>HR Manager</td>
            </tr>
          </tbody>
        </table>
      </Box>
    </>
  );
};

const CandidateTimeline = () => {
  const timelineData = [
    {
      time: '9:30 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'On hold - In progress',
      actionBy: 'by miss xyz',
      icon: 'material-symbols:person-outline',
    },
    {
      time: '9:45 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'In progress - Qualified',
      actionBy: 'by miss xyz',
      icon: 'fluent-mdl2:text-document-edit',
    },
    {
      time: '10:00 AM',
      status: 'Candidate saved as draft by Ultimate',
      description: '',
      actionBy: '',
      icon: 'fluent:drafts-24-regular',
    },
    {
      time: '9:30 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'On hold - In progress',
      actionBy: 'by miss xyz',
      icon: 'material-symbols:person-outline',
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: 4,
        height: 'auto',
        marginTop: '15px',
      }}
      justifyContent="flex-start"
    >
      <br />
      <br />
      <Timeline position="right">
        {' '}
        {/* Keep position 'right' */}
        {timelineData?.map((item, index) => (
          <TimelineItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {/* Align items center */}
            {/* Time on the left */}
            <TimelineOppositeContent
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end', // Align time to the right
                alignItems: 'center', // Vertical centering
                marginRight: '20px', // Adjust spacing between time and icon
              }}
              variant="body2"
              color="text.secondary"
            >
              {item.time}
            </TimelineOppositeContent>
            <TimelineSeparator sx={{ flexShrink: 0 }}>
              <TimelineConnector sx={{ height: 50 }} />
              <TimelineDot
                sx={{
                  backgroundColor: '#C7E6FF',
                  borderColor: '#C7E6FF',
                  borderRadius: '10px',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center', // Center icon in dot
                }}
              >
                <Icon icon={item.icon} width={30} height={30} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            {/* Content on the right */}
            <TimelineContent
              sx={{
                flex: 2, // Make content take up more space
                paddingLeft: '20px', // Adjust spacing between icon and content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Align content vertically
              }}
            >
              <Typography variant="body2">{item.status}</Typography>
              {item.description && <Typography variant="body2">{item.description}</Typography>}
              {item.actionBy && <Typography variant="body2">{item.actionBy}</Typography>}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <br />
    </Box>
  );
};

const CandidateHiringPipeline = () => {
  const stages = [
    { label: 'Screening', value: 1 },
    { label: 'Submission', value: 2 },
    { label: 'Rejected', value: 3 },
    { label: 'Interview', value: 4 },
    { label: 'Offered', value: 5 },
    { label: 'Hired', value: 6 },
  ];
  // Dummy current stage (Change this to test the pipeline)
  const [currentStage, setCurrentStage] = useState(2);
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: 4,
          height: 'auto',
          marginTop: '15px',
        }}
      >
        <Grid
          container
          spacing={2}
          marginLeft="auto"
          marginRight="auto"
          justifyContent="space-between"
          alignItems="center"
          margin="15px"
          sx={{ border: '1px solid #C4C4C4', borderRadius: '20px', height: '140px', width: '97%' }}
        >
          {stages?.map((stage, index) => (
            <Grid
              item
              key={stage.value}
              // Remove the left border for the first item
              sx={{
                borderLeft: index !== 0 ? '1px solid #C4C4C4' : 'none',
                height: '140px',
                width: '15%',
              }}
              onClick={() => setCurrentStage(stage.value)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '15px',
                }}
              >
                {/* Icon: Checkmark for completed, Radio button for pending */}
                {stage.value < currentStage ? (
                  <CheckCircle sx={{ color: '#00C1FE', fontSize: '40px' }} /> // Set checkmark color
                ) : stage.value === currentStage ? (
                  <RadioButtonChecked sx={{ color: '#7A72F0', fontSize: '40px' }} /> // Filled purple dot for current stage
                ) : (
                  <RadioButtonUnchecked sx={{ color: 'gray', fontSize: '40px' }} /> // Empty circles for remaining stages
                )}

                {/* Stage Label */}
                <p style={{ marginTop: '10px', fontSize: '20px' }}>{stage.label}</p>
              </Box>
            </Grid>
          ))}
        </Grid>
        <br />
        <Box></Box>
      </Box>
    </>
  );
};

const CandidateStages = () => {
  const stages = [
    { label: 'New', value: 1, color : '#88BCF6' },
    { label: 'In Review', value: 2, color : '#A4B0DB' },
    { label: 'Available', value: 3 , color : '#FF9999'},
    { label: 'Engaged', value: 4, color : '#EBB794' },
    { label: 'Hired', value: 5 , color : '#67E4BB'},
    { label: 'Offered', value: 6, color : '#E0CC38' },
    { label: 'Rejected', value: 7, color : '#B0CDCF'},
  ];
  // Dummy current stage (Change this to test the pipeline)
  const [currentStage, setCurrentStage] = useState(2);
  return (
    <>
      <Box
        sx={{
          padding: '15px',
          height: 'auto',
          marginTop: '10px',
        }}
      >
        <Grid
          container
          spacing={2}
          marginLeft="auto"
          marginRight="auto"
          justifyContent="space-between"
          alignItems="center"
          margin="15px"
          sx={{ border: '1px solid #C4C4C4', height: '140px', width: '97%', backgroundColor: '#F4F9FD' }}
        >
          {stages?.map((stage, index) => (
            <Grid
              item
              key={stage.value}
              // Remove the left border for the first item
              sx={{
                borderLeft: index !== 0 ? '1px solid #C4C4C4' : 'none',
                height: '140px',
                width: '14%',
                borderTop: `5px solid ${stage.color}`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '15px',
                }}
              >
                {/* Icon: Checkmark for completed, Radio button for pending */}
                {stage.value === currentStage ? (
                  <RadioButtonChecked sx={{ color: '#00C1FE', fontSize: '40px' }} /> // Filled purple dot for current stage
                ) : (
                  <RadioButtonUnchecked sx={{ color: '#00C1FE', fontSize: '40px' }} /> // Empty circles for remaining stages
                )}

                {/* Stage Label */}
                <p style={{ marginTop: '10px', fontSize: '20px' }}>{stage.label}</p>
              </Box>
            </Grid>
          ))}
        </Grid>

      </Box>
    </>
  );
};

export default CandidateDetails;
