import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
  Avatar,
  Skeleton,
} from '@mui/material';
import React, { useState } from 'react';
import { AddCandidateToRole, ViewResume } from './images';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssociateCandidateToOtherRoleDialog, ViewCandidateResumeDialog } from '.';

function CandidatePipelineTable({
  showButtons,
  buttons,
  onButtonClick,
  showHiringPipeline,
  onPageChange,
  onRowsPerPageChange,
  totalCandidates,
  candidates,
  loading,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const navigate = useNavigate();
  const location = useLocation(); // Use the hook to get the location
  const [openAssociateJobDialog, setOpenAssociateJobDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [openViewCandidateCVDialog, setOpenViewCandidateCVDialog] = useState(false);
  // const data = [
  //   {
  //     name: 'Ali Raza',
  //     hiringPipeline: {
  //       screening: true,
  //       submission: true,
  //       rejected: false,
  //       interview: false,
  //       offered: false,
  //       hired: false,
  //     },
  //     stage: 'In Review',
  //     candidateId: 1,
  //     number: '+44 56 5465 6',
  //     email: 'XbCzR@example.com',
  //     recruiter: 'Robert',
  //     resourcer: 'Janieee',
  //     acm: 'Amna',
  //   },
  //   {
  //     name: 'Vincee Nikolett',
  //     hiringPipeline: {
  //       screening: true,
  //       submission: true,
  //       rejected: true,
  //       interview: true,
  //       offered: false,
  //       hired: false,
  //     },
  //     stage: 'Qualified',
  //     candidateId: 2,
  //     number: '+44 56 5465 6',
  //     email: 'XbCzR@example.com',
  //     recruiter: 'Robert',
  //     resourcer: 'Janieee',
  //     acm: 'Amna',
  //   },
  //   {
  //     name: 'Varga Boglarka',
  //     hiringPipeline: {
  //       screening: true,
  //       submission: true,
  //       rejected: true,
  //       interview: false,
  //       offered: false,
  //       hired: false,
  //     },
  //     stage: 'Applied',
  //     candidateId: 3,
  //     number: '+44 56 5465 6',
  //     email: 'XbCzR@example.com',
  //     recruiter: 'Robert',
  //     resourcer: 'Janieee',
  //     acm: 'Amna',
  //   },
  // ];

  const hiringPipeline = {
    screening: true,
    submission: true,
    rejected: false,
    interview: false,
    offered: false,
    hired: false,
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };

  return (
    <div style={{ marginTop: '1rem' }}>
      {selected?.length > 0 ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <p style={{ fontSize: '20px', color: '#838383' }}>{selected?.length} &nbsp; Candidate Selected</p>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '10px',
                marginLeft: '20px',
              }}
              onClick={() => {
                setOpenAssociateJobDialog(true);
              }}
            >
              Associate to Another Role
            </Button>
          </Box>
        </>
      ) : null}
      <br />
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        {showButtons && (
          <>
            <Box
              sx={{ width: '95%', border: '1px solid rgba(0, 0, 0, 0.12)', margin: 'auto', backgroundColor: '#F4F9FD' }}
            >
              <Stack direction="row">
                {buttons?.map((button) => (
                  <Button
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 2,
                      outline: 'none',
                      flexGrow: 1,
                      border: 'none',
                      '&:hover': {
                        backgroundColor: '#F4F9FD',
                        outline: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderTop: `7px solid ${button?.borderTop}`,
                      },
                      '&:focus, &:active': {
                        outline: 'none',
                        borderTop: `7px solid ${button?.borderTop}`,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        boxShadow: 'none',
                      },
                      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                      borderTop: `7px solid ${button?.borderTop}`,
                      borderRadius: 0,
                    }}
                    onClick={() => onButtonClick(button?.buttonText)}
                  >
                    <p style={{ fontSize: '20px' }}>{button?.value}</p>
                    <p style={{ fontSize: `${button?.buttonFontSize}`, color: '#838383' }}>{button?.buttonText}</p>
                  </Button>
                ))}
              </Stack>
            </Box>
          </>
        )}

        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Candidate name</TableCell>
                {showHiringPipeline && <TableCell style={{ textAlign: 'center' }}>Hiring Pipeline</TableCell>}
                <TableCell style={{ textAlign: 'left' }}>Stage</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Number</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Recruiter</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'left' }}>ACM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Skeleton variant="reactangular" width={24} height={24} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Skeleton variant="text" width="60%" />{' '}
                    </TableCell>{' '}
                    {showHiringPipeline && (
                      <TableCell>
                        <Skeleton variant="text" width="40%" />
                      </TableCell>
                    )}{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="30%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="50%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>
                  </TableRow>
                ))
              ) : candidates?.data?.length > 0 ? (
                <>
                  {candidates?.data?.map((row, index) => (
                    <TableRow key={index} selected={selected.includes(row.candidateId)}>
                      <TableCell>
                        <Box display="flex" alignItems="center" justifyContent="space-around">
                          {/* Checkbox for selection */}
                          <Checkbox
                            checked={selected?.includes(row.candidateId)}
                            onChange={() => handleSelect(row.candidateId)}
                            inputProps={{ 'aria-label': 'select row' }}
                            sx={{
                              color: '#808080',
                              '&.Mui-checked': {
                                color: '#00C1FE', // Set checkbox color to blue when checked
                              },
                            }}
                          />
                          <Tooltip title="Add Candidate to Role" placement="top">
                            <img
                              src={AddCandidateToRole}
                              alt="Add CV to role"
                              width="16px"
                              height="16px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setOpenAssociateJobDialog(true);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="View CV" placement="top">
                            <img
                              src={ViewResume}
                              alt="View CV"
                              width="12px"
                              height="16px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setOpenViewCandidateCVDialog(true);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            navigate(`/recruiter/candidateInfo/1/11`, {
                              state: { previousPath: location.pathname }, // Pass the current path as state
                            })
                          }
                        >
                          {/* Avatar with the first character */}
                          <Avatar
                            style={{
                              marginRight: '8px', // Adds space between avatar and name
                              backgroundColor: '#4CD4FF',
                              color: '#fff',
                              width: '30px',
                              height: '30px',
                              fontSize: '16px',
                              textAlign: 'center',
                            }}
                          >
                            {row?.full_name?.charAt(0)}
                          </Avatar>

                          {/* Candidate name */}
                          <p
                            style={{
                              color: '#4CD4FF',
                              fontWeight: 'bold',
                              margin: 0,
                            }}
                          >
                            {row?.full_name}
                          </p>
                        </div>
                      </TableCell>
                      {showHiringPipeline && (
                        <TableCell sx={{ textAlign: 'center' }}>
                          {/* Render the HiringPipelineStepper */}
                          <HiringPipelineStepper pipeline={hiringPipeline} />
                        </TableCell>
                      )}
                      <TableCell>
                        <p>Screening</p>
                      </TableCell>
                      <TableCell>
                        <p>{row?.personal_phone}</p>
                      </TableCell>
                      <TableCell>
                        <p>{row?.personal_email}</p>
                      </TableCell>
                      <TableCell>
                        <p>Robert</p>
                      </TableCell>
                      <TableCell>
                        <p>Robert</p>
                      </TableCell>
                      <TableCell>
                        <p>Robert</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                      No records found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCandidates}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
      {openAssociateJobDialog && (
        <AssociateCandidateToOtherRoleDialog
          open={openAssociateJobDialog}
          onClose={() => setOpenAssociateJobDialog(false)}
        />
      )}
      {openViewCandidateCVDialog && (
        <ViewCandidateResumeDialog
          open={openViewCandidateCVDialog}
          onClose={() => setOpenViewCandidateCVDialog(false)}
        />
      )}
    </div>
  );
}

export default CandidatePipelineTable;

const HiringPipelineStepper = ({ pipeline }) => {
  const steps = ['screening', 'submission', 'rejected', 'interview', 'offered', 'hired'];

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {steps?.map((step, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Show tick or step number */}
          {pipeline[step] ? (
            <Tooltip placement="top" title={step}>
              <CheckCircle sx={{ color: '#00bfff', fontSize: 22 }} />
            </Tooltip>
          ) : (
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Tooltip placement="top" title={step}>
                <RadioButtonUnchecked sx={{ color: '#00bfff', fontSize: 24 }} />
              </Tooltip>
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  fontSize: '12px',
                  color: '#00bfff',
                  fontWeight: 'bold',
                }}
              >
                {index + 1}
              </Typography>
            </Box>
          )}
          {/* Connecting line between steps, except the last one */}
          {index !== steps?.length - 1 && (
            <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', marginLeft: '-2px' }} />
          )}
        </Box>
      ))}
    </Stack>
  );
};
